import { gql } from '@apollo/client'
import { TRAVATAR_FRAGMENT } from './travatar'
import { LIKES_FRAGMENT } from './like'
import { TRAVEL_ELEMENT_FRAGMENT } from './travelElement'
import { TRIP_PLAN_FRAGMENT } from './tripPlan'
import { TRAVELOG_FRAGMENT } from './travelog'
import { TRAVEL_ELEMENT_CONTENT_FRAGMENT } from './travelElementContent'
import { TRIP_CARD_CONTENT_FRAGMENT } from './tripCardContent'
import { BLOG_FRAGMENT } from './blog'

const PROFILE_INFO_FRAGMENT = gql`
  fragment ProfileInfo on user {
    id
    name
    profilePhoto: profile_photo
    description
    email
    isAdvisor: is_advisor
  }
`

const USER_FRAGMENT = gql`
  fragment User on user {
    ...ProfileInfo
    dateBirth: date_birth
    legalName: legal_name
    mobileNumber: mobile_number
    passport
    followersAmount: computed_followers
    followingsAmount: computed_followings
    iFollow: followed_by_user
    followerUsers: follower_users {
      id
      name
      profilePhoto: profile_photo
    }
    followingUsers: following_users {
      id
      name
      profilePhoto: profile_photo
    }
    travatars(limit: 10, order_by: { created_at: desc }) {
      ...Travatar
    }
    blogs {
      ...BlogInfo
    }
    travelElements: travel_elements(limit: 10, order_by: { created_at: desc }) {
      ...TravelElement
    }
    tripPlans: trip_plans(limit: 10, order_by: { created_at: desc }) {
      ...TripPlan
    }
    tripPlanCollaborations: trip_plan_collaborators(
      limit: 10
      order_by: { created_at: desc }
      where: { invitation_accepted: { _eq: true } }
    ) {
      tripPlan: trip_plan {
        ...TripPlan
      }
    }
    travelogs(limit: 10, order_by: { created_at: desc }) {
      ...Travelog
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
  ${TRAVATAR_FRAGMENT}
  ${LIKES_FRAGMENT}
  ${TRIP_PLAN_FRAGMENT}
  ${TRAVELOG_FRAGMENT}
  ${BLOG_FRAGMENT}
  ${PROFILE_INFO_FRAGMENT}
`

const USER_LIKED_CONTENT_FRAGMENT = gql`
  fragment UserLikedContent on user {
    likedTravelogs: liked_travelogs(limit: 10) {
      ...Travelog
    }
    likedTravelElements: liked_travel_elements(limit: 10) {
      ...TravelElement
    }
    likedTripPlans: liked_trip_plans(limit: 10) {
      ...TripPlan
    }
    likedTravelElementContent: liked_travel_element_contents(limit: 10) {
      ...TravelElementContent
    }
    likedTripCardContent: liked_trip_card_contents(limit: 10) {
      ...TripCardContent
    }
  }
  ${TRAVELOG_FRAGMENT}
  ${TRAVEL_ELEMENT_FRAGMENT}
  ${TRIP_CARD_CONTENT_FRAGMENT}
  ${TRAVEL_ELEMENT_CONTENT_FRAGMENT}
  ${TRIP_PLAN_FRAGMENT}
`

export { USER_FRAGMENT, PROFILE_INFO_FRAGMENT, USER_LIKED_CONTENT_FRAGMENT }
