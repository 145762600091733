import client from '../gql/clients/apollo-client'
import { INSERT_CHAT } from '../gql/mutations/chat'
import { GET_MORE_CHAT } from '../gql/querys/chat'
import { IChat } from '../types'
import { getProfilePhotoUrl } from '../utils/profile'

const completeChat = async (chat: IChat): Promise<IChat> => {
  const user = chat?.user
  const profileInfo = {
    id: user?.id,
    name: user?.name,
    profilePhotoUrl: await getProfilePhotoUrl(user?.profilePhoto, user?.id),
  }
  return {
    ...chat,
    profileInfo,
  }
}

export default {
  insertChat: async (chat) => {
    await client.mutate({
      mutation: INSERT_CHAT,
      variables: {
        id: chat.id,
        message: chat.message,
        path: chat.path,
        userId: chat.userId,
      },
    })
  },
  getMoreChat: async (path, offset): Promise<IChat[]> => {
    const result = await client.query({
      query: GET_MORE_CHAT,
      variables: { path, offset },
    })
    return await Promise.all(
      result.data.chat.map(async (chat) => await completeChat(chat))
    )
  },
  filterAndCompleteChat: async (
    chats: IChat[],
    chatsInStore?: IChat[]
  ): Promise<IChat[]> => {
    if (chatsInStore?.length) {
      const chatsNotInStore = await Promise.all(
        chats
          .filter(
            (notification) =>
              !chatsInStore.find((n) => n.id === notification.id)
          )
          .map(async (chat) => await completeChat(chat))
      )

      return [...chatsNotInStore, ...chatsInStore]
    } else {
      const tripPlanChat: IChat[] = await Promise.all(
        chats.map(async (chat) => await completeChat(chat))
      )
      return tripPlanChat
    }
  },
}
