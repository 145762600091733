import { useEffect } from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import { SearchState, SearchResults } from 'react-instantsearch-core'
import { useSearch } from '../../../context/Search'
import { useSearchPlace } from '../../../context/SearchPlace'

interface IProps {
  searchState: SearchState
  searchResults: SearchResults
  setHits: (string) => void
}

function Hits({ searchState, searchResults, setHits }: IProps) {
  const validQuery = searchState.query?.length >= 3

  const searchcontext = useSearch()

  const { setHitsPlaces } = searchcontext

  const searchPlacesHits = useSearchPlace()

  const { searchResultsMap } = searchPlacesHits

  useEffect(() => {
    if (
      (searchResults?.hits.length > 0 && validQuery) ||
      searchResultsMap.length
    ) {
      let placesSearchResults =
        searchResultsMap?.map((hit) => {
          return {
            label: hit.place_name,
            place: hit,
            group: `Places `,
          }
        }) || []

      placesSearchResults = [
        ...placesSearchResults.slice(0, 3),
        searchResultsMap?.length !== 0 && {
          label: `Show ${searchResultsMap.length} Places on the Globe`,
          showAll: true,
          allElements: searchResultsMap,
          isTravelElements: false,
          group: `Places `,
        },
      ]

      let hitsSearchResult: any =
        searchResults?.hits.map((hit) => {
          return {
            label: hit.title || hit?.name || 'Unnamed ',
            place: hit,
            group: `Elements `,
          }
        }) || []

      hitsSearchResult = [
        ...hitsSearchResult.slice(0, 3),
        searchResults?.hits?.length !== 0 && {
          label: `Show ${searchResults?.hits?.length} Elements on the Globe`,
          showAll: true,
          allElements: searchResults?.hits,
          isTravelElements: true,
          group: `Elements `,
        },
      ]
      setHits([...placesSearchResults, ...hitsSearchResult])
    } else {
      setHitsPlaces([])
      setHits([])
    }
  }, [searchResults, searchResultsMap])

  return <></>
}

export default connectStateResults(Hits)
