import { gql } from '@apollo/client'

const GET_VERSION = gql`
  query GetVersion {
    settings {
      appVersion: app_version
    }
  }
`
export { GET_VERSION }
