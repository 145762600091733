import { getImageUrl, uploadFile } from '../utils/useS3'
import { ITravelog, ITripCard, ITripPlan } from '../types'
import client from '../gql/clients/apollo-client'
import {
  GET_MORE_LIKED_TRAVELOGS,
  GET_MORE_TRAVELOGS,
  GET_TRAVELOGS,
  GET_TRAVELOG_BY_ID,
} from '../gql/querys/travelog'
import removeDashes from '../utils/removeDashes'
import EntityTypeEnum from '../constants/entityType.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import { isValidUuid } from '../utils'
import { UPDATE_TRAVELOG, INSERT_TRAVELOG } from '../gql/mutations/travelog'
import { getProfilePhotoUrl } from '../utils/profile'
import IdsEnum from '../constants/ids.constants'
import commentService from './commentService'
import tripCardContentService from './tripCardContentService'
import { v4 as uuidv4 } from 'uuid'

type completeTravelogInfoProps = {
  travelog: ITravelog
  profilePhotoUrl?: string
  tripCards?: ITripCard[]
}

const completeTravelogInfo = async ({
  travelog,
  profilePhotoUrl,
  tripCards,
}: completeTravelogInfoProps): Promise<ITravelog> => {
  const coverPhotoUrl = await getImageUrl(
    `${travelog?.id}/${travelog?.coverPhoto}`
  )
  const comments = await Promise.all(
    travelog?.comments?.map(async (comment) => {
      const profilePhotoUrl = await getProfilePhotoUrl(
        comment?.user?.profilePhoto,
        comment?.user?.id
      )
      return {
        ...comment,
        user: {
          ...comment?.user,
          profilePhotoUrl,
        },
      }
    }) || []
  )

  const cards = tripCards?.length ? tripCards : travelog?.tripCards
  const travelogTripCards = await Promise.all(
    cards?.map(async (tc: ITripCard) => {
      return {
        ...tc,
        tripCardContents: await tripCardContentService.completeTripCardContents(
          tc?.tripCardContents
        ),
      }
    })
  )

  const profile = travelog?.user
  const profilePhoto =
    profilePhotoUrl ||
    (await getImageUrl(`${profile?.id}/${profile?.profilePhoto}`))
  return {
    ...travelog,
    tripCards: travelogTripCards,
    coverPhotoUrl: travelog?.coverPhoto ? coverPhotoUrl : null,
    comments,
    profileInfo: {
      id: profile?.id,
      name: profile?.name,
      profilePhotoUrl: profilePhoto,
    },
  }
}

type CompleteTravelogsInfoProps = {
  travelogs: ITravelog[]
  profilePhotoUrl?: string
}

export default {
  completeTravelogsInfo: async ({
    travelogs,
    profilePhotoUrl,
  }: CompleteTravelogsInfoProps): Promise<ITravelog[]> => {
    return await Promise.all(
      travelogs.map(async (travelog) =>
        completeTravelogInfo({ travelog, profilePhotoUrl })
      )
    )
  },

  getTravelogs: async (travelogIds: string[]) => {
    const result = await client.query({
      query: GET_TRAVELOGS,
      variables: {
        travelogIds,
      },
    })
    const travelogsResult = result?.data?.travelog

    const travelogs = (await Promise.all(
      travelogsResult.map(
        async (travelog) => await completeTravelogInfo({ travelog })
      )
    )) as ITravelog[]

    return travelogs
  },
  getMoreTravelogs: async (
    userId: string,
    offset: number,
    travatarId: string,
    searchInput?: string
  ) => {
    if (!isValidUuid(userId)) {
      throw new InvalidUuidError('Invalid User id')
    }
    const titleComparisonExp = searchInput
      ? {
          _ilike: `%${searchInput}%`,
        }
      : {}
    const travatarIdComparisonExp = travatarId ? { _eq: travatarId } : {}

    const result = await client.query({
      query: GET_MORE_TRAVELOGS,
      variables: {
        userId,
        offset,
        titleComparisonExp,
        travatarIdComparisonExp,
      },
    })
    const travelogs = await Promise.all(
      result.data.travelog.map(
        async (travelog) => await completeTravelogInfo({ travelog })
      )
    )
    return travelogs
  },
  getMoreLikedTravelogs: async (userId, offset) => {
    const result = await client.query({
      query: GET_MORE_LIKED_TRAVELOGS,
      variables: { userId, offset },
    })
    const travelogs = (await Promise.all(
      result.data.user_by_pk.likedTravelogs.map(
        async (travelog) => await completeTravelogInfo({ travelog })
      )
    )) as ITravelog[]

    return travelogs
  },
  getTravelog: async (travelogId: string): Promise<ITravelog> => {
    if (!isValidUuid(travelogId)) {
      throw new InvalidUuidError('Invalid Travelog id')
    }

    const result = await client.query({
      query: GET_TRAVELOG_BY_ID,
      variables: {
        id: travelogId,
        path: `${EntityTypeEnum.TRAVELOG}.${removeDashes(travelogId)}`,
      },
    })

    const travelogResult = result?.data?.travelog_by_pk

    const travelog = await completeTravelogInfo({
      travelog: travelogResult,
    })

    return travelog
  },
  getOrAssignTravelog: async (travelogId: string): Promise<ITravelog> => {
    if (!isValidUuid(travelogId)) {
      throw new InvalidUuidError('Invalid Travelog id')
    }

    const result = await client.query({
      query: GET_TRAVELOG_BY_ID,
      variables: {
        id: travelogId,
      },
    })

    let travelog
    if (result.data.travelog_by_pk?.id) {
      travelog = await completeTravelogInfo({
        travelog: result.data.travelog_by_pk,
      })
    } else {
      travelog = { id: travelogId, statusId: IdsEnum.STATUS_DRAFT }
    }
    return travelog
  },
  editTravelog: async (newTravelogData: ITravelog) => {
    const {
      id,
      title: newTitle,
      description: newDescription,
      heroImage,
      coverPhoto: dataCoverPhoto,
      statusId,
    } = newTravelogData

    const coverPhoto = `${newTravelogData.id}/travelog_hero${Date.now()}.jpg`

    const result = await client.mutate({
      mutation: UPDATE_TRAVELOG,
      variables: {
        id,
        title: newTitle,
        description: newDescription,
        entityTypeId: IdsEnum.ENTITY_TYPE_TRAVELOG,
        coverPhoto: heroImage ? coverPhoto : dataCoverPhoto,
        statusId,
      },
    })
    if (newTravelogData.heroImage) {
      await uploadFile({
        rawKey: `${newTravelogData.id}/${coverPhoto}`,
        contentType: newTravelogData.heroImage.type,
        file: newTravelogData.heroImage,
      })
    }

    const travelog = await completeTravelogInfo({
      travelog: result.data.update_travelog_by_pk,
    })

    return travelog
  },
  getMoreComments: async (oldTravelog: ITravelog) => {
    const offset = oldTravelog.comments.length
    const path = `${EntityTypeEnum.TRAVELOG}.${removeDashes(oldTravelog.id)}`
    const comments = await commentService.getMoreComments(path, offset)
    const travelog = {
      ...oldTravelog,
      comments: [...oldTravelog.comments, ...comments],
    }
    return travelog
  },
  publishTripPlanAsTravelog: async (
    tripPlan: ITripPlan,
    profileTravelogsInStore: ITravelog[],
    cacheContentsInStore: any
  ) => {
    const travelog_id = uuidv4()

    const travelogInsertInput = {
      id: travelog_id,
      title: tripPlan.title,
      description: tripPlan.description,
      metadata: tripPlan.metadata,
      user_id: tripPlan.userId,
    }
    const tripCardsInsertInput = tripPlan?.tripCards?.map((tc: ITripCard) => {
      return {
        travelog_id,
        location: tc?.location,
        metadata: tc?.metadata,
        travel_element_id: tc?.travelElementId,
      }
    })

    const result = await client.mutate({
      mutation: INSERT_TRAVELOG,
      variables: { travelogInsertInput, tripCardsInsertInput },
    })

    const travelog = await completeTravelogInfo({
      travelog: result.data.insert_travelog_one,
      tripCards: result.data.insert_trip_card.returning,
    })

    const profileTravelogs = [travelog, ...profileTravelogsInStore]
    const cacheContents = {
      ...cacheContentsInStore,
      travelogs: profileTravelogs,
    }
    return { travelog, profileTravelogs, cacheContents }
  },
}
