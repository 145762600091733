import { gql } from '@apollo/client'
import { NOTIFICATION_FRAGMENT } from '../fragments/notification'

const INSERT_NOTIFICATION = gql`
  mutation insertNotifiaction($body: jsonb!, $toId: uuid!) {
    insert_notification_one(object: { to: $toId, body: $body }) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`

const READ_NOTIFICATION = gql`
  mutation ReadNotification($id: uuid!) {
    update_notification_by_pk(pk_columns: { id: $id }, _set: { read: true }) {
      id
    }
  }
`

const READ_ALL_NOTIFICATIONS = gql`
  mutation ReadAllNotifications($to: uuid!) {
    update_notification(where: { to: { _eq: $to } }, _set: { read: true }) {
      affected_rows
    }
  }
`

export { INSERT_NOTIFICATION, READ_NOTIFICATION, READ_ALL_NOTIFICATIONS }
