import client from '../gql/clients/apollo-client'
import {
  INSERT_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
  READ_NOTIFICATION,
} from '../gql/mutations/notification'
import {
  GET_MORE_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS_AMOUNT,
} from '../gql/querys/notification'
import { INotification } from '../types'

export default {
  sendNotification: (body, toId: string) => {
    client.mutate({
      mutation: INSERT_NOTIFICATION,
      variables: { body, toId },
    })
  },
  getUnreadNotificationsAmount: async (userId) => {
    const result = await client.query({
      query: GET_UNREAD_NOTIFICATIONS_AMOUNT,
      variables: { id: userId },
    })
    return result.data.notification_aggregate.aggregate.count
  },
  readNotification: async (id) => {
    await client.mutate({
      mutation: READ_NOTIFICATION,
      variables: { id },
    })
  },
  getMoreNotifications: async (userId, offset) => {
    const result = await client.query({
      query: GET_MORE_NOTIFICATIONS,
      variables: { id: userId, offset },
    })

    const notifications = result.data.notification
    return notifications
  },
  readAllNotifications: async (
    userId,
    notifications
  ): Promise<INotification[]> => {
    await client.mutate({
      mutation: READ_ALL_NOTIFICATIONS,
      variables: { to: userId },
    })
    return notifications.map((n) => {
      return { ...n, isRead: true }
    })
  },
}
