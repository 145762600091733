import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from './comment'

const TRIP_CARD_CONTENT_FRAGMENT = gql`
  fragment TripCardContent on trip_card_content {
    id
    entityType: entity_type {
      id
      name
    }
    location
    metadata
    isPrivate: is_private
    likesAmount: computed_likes
    iLiked: liked_by_user
    description
    path
    tripCardId: trip_card_id
    comments {
      ...Comments
    }
    tripCard: trip_card {
      metadata
    }
  }
  ${COMMENT_FRAGMENT}
`

export { TRIP_CARD_CONTENT_FRAGMENT }
