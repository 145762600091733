import { gql } from '@apollo/client'
import { CHAT_FRAGMENT } from '../fragments/chat'

const GET_MORE_CHAT = gql`
  query GetMoreChat($path: ltree!, $offset: Int!) {
    chat(
      where: { path: { _eq: $path } }
      order_by: { created_at: desc }
      offset: $offset
      limit: 10
    ) {
      ...Chat
    }
  }
  ${CHAT_FRAGMENT}
`

export { GET_MORE_CHAT }
