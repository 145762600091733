import { gql } from '@apollo/client'
import {
  TRIP_PLAN_COLLABORATOR_FRAGMENT,
  TRIP_PLAN_FRAGMENT,
  TRIP_PLAN_INFO_FRAGMENT,
  TRIP_PLAN_LOG_FRAGMENT,
} from '../fragments/tripPlan'

const GET_TRIP_PLAN_BY_PK = gql`
  query GetTripPlanByPk($tripPlanId: uuid!) {
    trip_plan_by_pk(id: $tripPlanId) {
      ...TripPlan
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`

const GET_MORE_TRIP_PLANS = gql`
  query GetMoreTripPlans(
    $offset: Int!
    $userId: uuid!
    $limit: Int!
    $titleComparisonExp: String_comparison_exp
    $travatarIdComparisonExp: uuid_comparison_exp
  ) {
    trip_plan(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        user_id: { _eq: $userId }
        title: $titleComparisonExp
        travatar_id: $travatarIdComparisonExp
        deleted_at: { _is_null: true }
      }
    ) {
      ...TripPlan
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`

const GET_MORE_LIKED_TRIP_PLANS = gql`
  query GetMoreLikedTripPlans($userId: uuid!, $offset: Int!) {
    user_by_pk(id: $userId) {
      likedTripPlans: liked_trip_plans(limit: 10, offset: $offset) {
        ...TripPlanInfo
      }
    }
  }
  ${TRIP_PLAN_INFO_FRAGMENT}
`

const GET_MORE_COLLABORATION_TRIP_PLANS = gql`
  query GetMoreTripPlansByUserId($userId: uuid!, $offset: Int!) {
    user_by_pk(id: $userId) {
      tripPlanCollaborations: trip_plan_collaborators(
        limit: 10
        offset: $offset
        order_by: { created_at: desc }
        where: {
          invitation_accepted: { _eq: true }
          deleted_at: { _is_null: true }
        }
      ) {
        tripPlan: trip_plan {
          ...TripPlan
        }
      }
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`

const GET_TRIP_PLAN_COLLABORATOR_BY_PK = gql`
  query GetTripPlanCollaboratorsByPk($id: uuid!) {
    trip_plan_collaborators_by_pk(id: $id) {
      ...TripPlanCollaborator
    }
  }
  ${TRIP_PLAN_COLLABORATOR_FRAGMENT}
`

const GET_TRIP_PLAN_COLLABORATORS_BY_EMAIL = gql`
  query GetTripPlanCollaborators($tripPlanId: uuid!, $emailInvited: String!) {
    trip_plan_collaborators(
      where: {
        trip_plan_id: { _eq: $tripPlanId }
        email_invited: { _ilike: $emailInvited }
        deleted_at: { _is_null: true }
      }
    ) {
      ...TripPlanCollaborator
    }
  }
  ${TRIP_PLAN_COLLABORATOR_FRAGMENT}
`
const GET_TRIP_PLAN_COLLABORATORS_BY_USER_ID = gql`
  query GetTripPlanCollaborators($tripPlanId: uuid!, $userId: uuid!) {
    trip_plan_collaborators(
      where: {
        trip_plan_id: { _eq: $tripPlanId }
        user_id: { _eq: $userId }
        deleted_at: { _is_null: true }
      }
    ) {
      ...TripPlanCollaborator
    }
  }
  ${TRIP_PLAN_COLLABORATOR_FRAGMENT}
`

const GET_MORE_TRIP_PLAN_LOGS = gql`
  query GetMoreTripPlanLogs($tripPlanId: uuid!, $offset: Int!) {
    trip_plan_log(
      where: { trip_plan_id: { _eq: $tripPlanId } }
      order_by: { created_at: desc }
      offset: $offset
      limit: 10
    ) {
      ...TripPlanLog
    }
  }
  ${TRIP_PLAN_LOG_FRAGMENT}
`

const GET_ALL_TRIP_PLANS = gql`
  query GetAllTripPlans($userId: uuid!) {
    tripPlans: trip_plan(where: { user_id: { _eq: $userId } }) {
      ...TripPlan
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`

export {
  GET_TRIP_PLAN_BY_PK,
  GET_MORE_TRIP_PLANS,
  GET_MORE_LIKED_TRIP_PLANS,
  GET_MORE_COLLABORATION_TRIP_PLANS,
  GET_TRIP_PLAN_COLLABORATOR_BY_PK,
  GET_TRIP_PLAN_COLLABORATORS_BY_EMAIL,
  GET_TRIP_PLAN_COLLABORATORS_BY_USER_ID,
  GET_MORE_TRIP_PLAN_LOGS,
  GET_ALL_TRIP_PLANS,
}
