import imageCompression from 'browser-image-compression'
import exifr from 'exifr'
import IdsEnum from '../constants/ids.constants'
import { ITravelElement } from '../types'
import { getImageUrl } from './useS3'

export const isBrowser = typeof window !== 'undefined'

//Function receives the string and the max number of characters it will support.
//If the string is bigger than the number, truncate the string and add ...
export const truncateString = (text: string, long: number): string =>
  text?.length > long ? `${text.slice(0, long - 3)}...` : text

export const getBase64 = (file): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}

export const base64ToFile = async (imageBase64, name, type) => {
  const res = await fetch(imageBase64)
  if (res) {
    const blob = await res.blob()
    return new File([blob], name, {
      type: type,
    })
  }
}

export const getLocationMedia = async (file) => {
  try {
    const { latitude, longitude } = await exifr.gps(file)
    return {
      file,
      localization: { latitude, longitude },
    }
  } catch {
    return {
      file,
      localization: null,
    }
  }
}

const compressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 3840,
  useWebWorker: true,
}

export const imageSizeCompression = async (
  imageFiles,
  withCoordinates = false,
  options = { ...compressionOptions }
): Promise<any[]> => {
  try {
    const imageFilesNew = await Promise.all(
      imageFiles.map(async (imageFile) => {
        const compressedFile = await imageCompression(imageFile.file, options)

        let file = new File([compressedFile], imageFile.file.name, {
          type: imageFile.file.type,
          lastModified: Date.now(),
        })
        file = Object.assign(file, {
          path: imageFile.file.name,
        })

        if (withCoordinates) {
          return {
            file,
            localization: imageFile.localization
              ? { ...imageFile.localization }
              : null,
          }
        } else {
          return {
            file,
          }
        }
      })
    )

    return imageFilesNew
  } catch (error) {
    console.error(error)
  }
}

export const regexExpUuid =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

export const isValidUuid = (id: string): boolean => {
  return regexExpUuid.test(id)
}

export const getTags = (text: string): string[] => {
  let tagList = []
  let startWithHash = false
  if (text.charAt(0) == '#') {
    startWithHash = true
  }
  const tags = text.split('#')
  if (startWithHash) {
    tags.shift()
  }
  tags.forEach((word) => {
    tagList = [...tagList, word.split(' ')[0]]
  })
  if (!startWithHash) {
    tagList.shift()
  }
  return tagList
}

export const getMentions = (text: string): string[] => {
  let mentionList = []
  let startWithMention = false
  if (text.charAt(0) == '@') {
    startWithMention = true
  }
  const mentions = text.split('@')
  if (startWithMention) {
    mentions.shift()
  }
  mentions.forEach((word) => {
    mentionList = [...mentionList, word.split(' ')[0]]
  })
  if (!startWithMention) {
    mentionList.shift()
  }
  return mentionList
}

export const formatPointToCoordinateType = (
  place: string
): { latitude: number; longitude: number } => {
  if (place) {
    const placeReplace = place.replace(/[^0-9.\-, ]/g, '')
    const [pointA, pointB] = placeReplace.split(',')
    return { latitude: Number(pointA), longitude: Number(pointB) }
  } else {
    return { latitude: null, longitude: null }
  }
}

export const getContentUrl = async (
  id: string,
  photoName: string
): Promise<string> => {
  if (photoName) return await getImageUrl(`${id}/${photoName}`)
  else return ''
}

function editDistance(s1, s2) {
  //https://en.wikipedia.org/wiki/Levenshtein_distance
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  const costs = []
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j
      else {
        if (j > 0) {
          let newValue = costs[j - 1]
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue
  }
  return costs[s2.length]
}
export function similarityPercentageStrings(string1, string2): number {
  let longer = string1
  let shorter = string2
  if (string1.length < string2.length) {
    longer = string2
    shorter = string1
  }
  const longerLength = longer.length
  if (longerLength == 0) {
    return 1
  }
  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  )
}

export const removeDuplicates = (arr1: any[], arr2: any[]) => {
  const arr1Ids = arr1.map((element) => element.id)

  return arr2.filter((element) => {
    const isDuplicated = arr1Ids.includes(element.id)
    if (!isDuplicated) {
      return true
    }
    return false
  })
}

export const statusIdToString = (statusId: string): string => {
  let status
  if (statusId == IdsEnum.STATUS_DRAFT || statusId == IdsEnum.STATUS_COMPLETE) {
    status = 'Draft'
  } else if (statusId == IdsEnum.STATUS_PUBLISHED) {
    status = 'Published'
  }
  return status
}

export const travelElementsToGeoJson = (travelElements: ITravelElement[]) => {
  const travelElementGeoJson = travelElements.map((te) => {
    const location = formatPointToCoordinateType(te?.location)
    return {
      type: 'Feature',
      properties: {
        ...te,
      },
      geometry: {
        type: 'Point',
        coordinates: [location.longitude, location.latitude, 0.0],
      },
    }
  })

  const searchedGlobePoints = {
    type: 'FeatureCollection',
    features: travelElementGeoJson,
  }

  return searchedGlobePoints
}

export const removeHtmlTagsOnString = (text: string) => {
  let textWithoutTags = ''
  if (text) {
    textWithoutTags = text?.replace(/(<([^>]+)>)/gi, ' ')
  }

  return textWithoutTags
}

export const coordinatesArrayToGeoJson = (travelElements) => {
  const coordinatesToData = travelElements.map((te) => {
    const location = formatPointToCoordinateType(te.location)
    return [location.longitude, location.latitude]
  })
  const travelElementsArray = travelElements.map((te) => {
    return te
  })
  const data = {
    type: 'Feature',
    properties: { travelElementsArray },
    geometry: {
      type: 'LineString',
      coordinates: coordinatesToData,
    },
  }

  const geoJsonToReturn = {
    ...data,
  }

  return geoJsonToReturn
}

export const getTripPlanDate = (date: Date, index) => {
  const newDate = new Date(date || Date.now())

  newDate.setDate(newDate.getDate() + index)

  const returnedDate = `${newDate.getDate()} ${newDate.toLocaleString('en-us', {
    month: 'long',
  })}`

  return returnedDate
}

export const travelElementsMapRoutePoints = (travelElements) => {
  const travelElementGeoJson = travelElements.map((te) => {
    const location = formatPointToCoordinateType(te?.location)
    return {
      type: 'Feature',
      properties: {
        ...te,
      },
      geometry: {
        type: 'Point',
        coordinates: [location.longitude, location.latitude, 0.0],
      },
    }
  })

  const searchedGlobePoints = {
    type: 'FeatureCollection',
    features: travelElementGeoJson,
  }

  return searchedGlobePoints
}
export const getRandomNumberBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
