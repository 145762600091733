import client from '../gql/clients/apollo-client'
import { GET_VERSION } from '../gql/querys/settings'

export default {
  getVersion: async () => {
    const result = await client.query({
      query: GET_VERSION,
    })

    const appVersion: string = result.data.settings[0].appVersion
    return appVersion
  },
}
