import { gql } from '@apollo/client'
import { BLOG_FRAGMENT } from '../fragments/blog'

const GET_BLOG = gql`
  query GetBlog($id: uuid!) {
    blog_by_pk(id: $id) {
      ...BlogInfo
    }
  }
  ${BLOG_FRAGMENT}
`
const GET_BLOG_BY_NAME = gql`
  query getBlogsByName($search: String!) {
    blog(where: { title: { _ilike: $search } }) {
      ...BlogInfo
    }
  }
  ${BLOG_FRAGMENT}
`

export { GET_BLOG, GET_BLOG_BY_NAME }
