enum EntityTypeEnum {
  TRAVELOG = 'TRAVELOG',
  TRAVATAR = 'TRAVATAR',
  PDF = 'PDF',
  TRAVEL_ELEMENT = 'TRAVEL_ELEMENT',
  TRIP_PLAN = 'TRIP_PLAN',
  TRAVEL_ELEMENT_CONTENT = 'TRAVEL_ELEMENT_CONTENT',
  TRIP_CARD_CONTENT = 'TRIP_CARD_CONTENT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  GIF = 'GIF',
  BLOG_POST = 'BLOG_POST',
  COMMENT = 'COMMENT',
  LIKE = 'LIKE',
  USER = 'USER',
}

export default EntityTypeEnum
