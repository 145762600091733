import { StoreSlice } from '.'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import commentService from '../services/commentService'
import followService from '../services/followService'
import likeService from '../services/likeService'
import travelElementContentService from '../services/travelElementContentService'
import travelElementService from '../services/travelElementService'
import {
  IComment,
  IFileWithLocation,
  ITravelElement,
  ITravelElementContent,
  ITravelElementRelatedTravelogs,
} from '../types'
import { v4 as uuidv4 } from 'uuid'
import { getImageUrl } from '../utils/useS3'
import removeDashes from '../utils/removeDashes'
import { getProfilePhotoUrl } from '../utils/profile'
import {
  completeTravelElementInfo,
  orderTravelElementContents,
} from '../utils/travelElement'

type TravelElementSliceState = {
  travelElement: ITravelElement | null
  travelElements: ITravelElement[] | []
  globePoints: any
  globePointsGeoJson: any
  travelElementRelatedTravelogs: ITravelElementRelatedTravelogs[] | []
}

type TravelElementSliceActions = {
  getTravelElement(travelElementId: string): void
  getOrAssignNewTravelElement(travelElementId: string): void
  editTravelElement(newTravelElementData)
  getTravelElementsGeoJSON(): Promise<void>
  changeTravelElementContentPosition(travelElementContents): Promise<void>
  addTravelElementLike(travelElementId, profileId): Promise<void>
  getMoreTravelElements(): Promise<void>
  cleanGlobePointsGeoJson(): void
  getMoreLikedTravelElements(): Promise<void>
  deleteTravelElementLike(travelElementId, profileId): Promise<void>
  getGlobePoints(): Promise<void>
  insertTravelElementFollow(travelElementId, profileId): Promise<void>
  deleteTravelElementFollow(travelElementId, profileId): Promise<void>
  addTravelElementContentLike(contentId, profileId): void
  deleteTravelElementContentLike(contentId, profileId): void
  addTravelElementComment(
    comment: string,
    travelElementId: string,
    user: any
  ): Promise<void>
  deleteTravelElementComment(commentId: string, travelElementId: string): void
  addTravelElementContentComment(
    comment: string,
    contentId: string,
    user: any
  ): Promise<void>
  deleteTravelElementContentComment(commentId: string): void
  getMoreTravelElementComments(): Promise<void>
  getMoreTravelElementContentComments(contentId): Promise<void>
  cleanTravelElement(): void
  removeTravelElementContent(ids: string): void
  addTravelElementContentDescription(
    description: string,
    travelElementContentId: string
  ): void
  updateTravelElementsInStore(travelElement: ITravelElement): void
  getMoreFollowingTravelElements(): Promise<void>
}
export type TravelElementSlice = TravelElementSliceState &
  TravelElementSliceActions

const defaultTravelElementSliceState: TravelElementSliceState = {
  travelElement: null,
  travelElements: [],
  globePoints: null,
  globePointsGeoJson: null,
  travelElementRelatedTravelogs: [],
}

export const createTravelElementSlice: StoreSlice<TravelElementSlice> = (
  set,
  get
) => ({
  ...defaultTravelElementSliceState,

  getTravelElement: async (travelElementId) => {
    get().setStartLoading()
    try {
      const travelElements: ITravelElement[] = get().travelElements
      const profileTravelElements = get().profileTravelElements

      let travelElement = travelElements.find(
        (te: ITravelElement) => te.id === travelElementId
      )

      if (!travelElement) {
        travelElement = profileTravelElements.find(
          (te: ITravelElement) => te.id === travelElementId
        )
      }

      if (!travelElement) {
        travelElement = await travelElementService.getTravelElement(
          travelElementId
        )
        travelElements.push(travelElement)
      }
      set({
        travelElement,
        travelElements,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  cleanGlobePointsGeoJson: () => {
    const globePointsGeoJson = null
    set({
      globePointsGeoJson,
    })
  },
  getOrAssignNewTravelElement: async (travelElementId) => {
    get().setStartLoading()
    try {
      const profileTravelElements = get().profileTravelElements
      const travelElements: ITravelElement[] = get().travelElements

      let travelElement = travelElements?.find(
        (te: ITravelElement) => te.id === travelElementId
      )

      if (!travelElement) {
        travelElement = profileTravelElements?.find(
          (te: ITravelElement) => te.id === travelElementId
        )
      }

      if (!travelElement) {
        travelElement = await travelElementService.getOrAssignNewTravelElement(
          travelElementId
        )
        travelElements.push(travelElement)
      }

      set({
        travelElement,
        travelElements,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
        console.log(error)
      }
    } finally {
      get().setEndLoading()
    }
  },
  getMoreTravelElements: async () => {
    try {
      const userId = get().profile.id
      const searchInput = get().searchInput
      const searchResultStore = get().searchResult
      const searchResultTravelElements = searchResultStore?.travelElements
      const searchTravatarId = get().searchTravatarId
      const currentProfileTravelElements = get().profileTravelElements

      const offsetSearch = searchResultTravelElements?.length
      const offsetNotSearch = currentProfileTravelElements?.length
      const offset: number = searchInput ? offsetSearch : offsetNotSearch

      const newSearchTravelElements =
        await travelElementService.getMoreTravelElements(
          userId,
          offset,
          searchTravatarId,
          searchInput
        )

      if (searchInput && newSearchTravelElements?.length) {
        const searchResult = {
          ...searchResultStore,
          travelElements: [
            ...searchResultTravelElements,
            ...newSearchTravelElements,
          ],
        }
        set({ searchResult })
      } else if (newSearchTravelElements?.length) {
        const profileTravelElements = [
          ...currentProfileTravelElements,
          ...newSearchTravelElements,
        ]
        set({
          profileTravelElements,
        })
      }
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  getMoreLikedTravelElements: async () => {
    try {
      const userId = get().profile.id
      const likedContentInStore = get().likedContent

      const likedTravelElements = likedContentInStore?.travelElements
      const offset = likedTravelElements?.length || 0

      const travelElements =
        await travelElementService.getMoreLikedTravelElements(userId, offset)

      let likedContent
      if (likedContentInStore) {
        likedContent = {
          ...likedContentInStore,
          travelElements: [...likedTravelElements, ...travelElements],
        }
      } else {
        likedContent = {
          travelElements: travelElements,
        }
      }

      set({ likedContent })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  editTravelElement: async (newTravelElementData) => {
    try {
      if (
        newTravelElementData?.mediaFiles?.length > 0 ||
        newTravelElementData?.travelElementContentUrl
      ) {
        get().setStartLoading({ travelElement: { mutation: true } })
      }

      let currentProfileTravelElements = get().profileTravelElements

      const globePoints = get().getGlobePoints
      if (!globePoints.length) {
        await get().getGlobePoints()
      }

      const { travelElement: newTravelElement, globePointsGeoJson } =
        await travelElementService.editTravelElement(
          newTravelElementData,
          get().globePoints,
          get().globePointsGeoJson
        )

      const contentsLength = newTravelElement?.travelElementContents?.length

      if (
        newTravelElementData?.mediaFiles?.length > 0 ||
        newTravelElementData?.heroImage
      ) {
        const heroImage = newTravelElementData.heroImage
          ? { file: newTravelElementData.heroImage }
          : null
        const newContent = heroImage
          ? [...newTravelElementData.mediaFiles, heroImage]
          : newTravelElementData.mediaFiles

        const travelElementContents =
          await travelElementService.addTravelElementContentWithUrl(
            newTravelElement.id,
            newContent,
            contentsLength
          )

        newTravelElement.travelElementContents = [
          ...newTravelElement.travelElementContents,
          ...travelElementContents,
        ]
      }

      //TODO: LET'S REVIEW THIS LOGIC. IT'S NOT THE BEST WAY TO DO IT. BUT IT WORKS FOR NOW. ALONZO.
      if (newTravelElementData?.travelElementContentUrl) {
        const travelElementContents =
          await travelElementService.addYoutubeContent(
            newTravelElementData?.id,
            newTravelElementData.travelElementContentUrl,
            contentsLength
          )
        newTravelElement.travelElementContents = [
          ...newTravelElement.travelElementContents,
          ...travelElementContents,
        ]
      }

      currentProfileTravelElements = currentProfileTravelElements.filter(
        (te: ITravelElement) => te.id !== newTravelElement?.id
      )

      const profileTravelElements = [
        newTravelElement,
        ...currentProfileTravelElements,
      ]

      const travelElements = [
        ...get().travelElements.filter(
          (te: ITravelElement) => te.id !== newTravelElement?.id
        ),
        newTravelElement,
      ]
      const cacheContents = {
        ...get().cacheContents,
        travelElements: profileTravelElements,
      }

      set({
        profileTravelElements,
        travelElement: newTravelElement,
        travelElements,
        cacheContents,
        globePointsGeoJson,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  insertTravelElementFollow: async (
    travelElementId: string,
    profileId: string
  ) => {
    try {
      const travelElement = get().travelElement
      const oldTravelElements = get().travelElements

      const follow = await followService.addFollow(
        travelElementId,
        profileId,
        EntityTypeEnum.TRAVEL_ELEMENT,
        IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT
      )

      travelElement.followersAmount = ++travelElement.followersAmount
      travelElement.iFollow = true

      const url = await getProfilePhotoUrl(
        follow?.user?.profilePhoto,
        follow?.userId
      )
      travelElement.followerUsers.push({
        id: follow?.userId,
        name: follow?.user?.name,
        profilePhotoUrl: url,
      })

      const travelElements = oldTravelElements?.filter(
        (te: ITravelElement) => te.id !== travelElement.id
      )
      travelElements.push(travelElement)

      const followingContentInStore = get().followingContent || {
        travelElements: [],
      }
      const followingContent = {
        ...followingContentInStore,
        travelElements: [
          travelElement,
          ...followingContentInStore.travelElements,
        ],
      }

      set({
        travelElement,
        travelElements,
        followingContent,
      })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
      console.log(error)
    }
  },
  deleteTravelElementFollow: async (
    travelElementId: string,
    profileId: string
  ) => {
    try {
      const travelElement = get().travelElement
      const oldTravelElements: ITravelElement[] = get().travelElements

      followService.deleteFollow(
        travelElementId,
        profileId,
        EntityTypeEnum.TRAVEL_ELEMENT
      )

      if (travelElement) {
        travelElement.followersAmount = --travelElement.followersAmount
        travelElement.iFollow = false
        travelElement.followerUsers = travelElement.followerUsers.filter(
          (user) => user.id !== profileId
        )
      }
      const travelElements = oldTravelElements?.filter(
        (te: ITravelElement) => te?.id !== travelElementId
      )
      travelElements.push(travelElement)
      const followingContentInStore = get().followingContent || {
        travelElements: [],
      }
      const followingContent = {
        ...followingContentInStore,
        travelElements: followingContentInStore?.travelElements?.filter(
          (te: ITravelElement) => te?.id !== travelElementId
        ),
      }
      set({
        travelElement,
        travelElements,
        followingContent,
      })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
      console.log(error)
    }
  },
  getGlobePoints: async () => {
    try {
      const globePoints = await travelElementService.getGlobePoints()

      set({ globePoints })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  getTravelElementsGeoJSON: async () => {
    try {
      const globePointsGeoJson = get().globePointsGeoJson
      if (!globePointsGeoJson) {
        const globePointsElements: ITravelElement[] =
          await travelElementService.travelElementGeoJsonFactory()

        const globePointsGeoJson = {
          type: 'FeatureCollection',
          features: globePointsElements,
        }

        set({ globePointsGeoJson: globePointsGeoJson })
      }
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    }
  },

  addTravelElementLike: async (travelElementId, profileId) => {
    const travelElement = get().travelElement

    travelElement.likesAmount = ++travelElement.likesAmount
    travelElement.iLiked = true

    const travelElements = [
      ...get().travelElements.filter(
        (te: ITravelElement) => te.id !== travelElementId
      ),
      travelElement,
    ]

    likeService.addLike(
      travelElementId,
      profileId,
      EntityTypeEnum.TRAVEL_ELEMENT,
      IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT
    )

    const likedContentInStore = get().likedContent
    let likedContent = null
    if (likedContentInStore) {
      likedContent = {
        ...likedContentInStore,
        travelElements: [travelElement, ...likedContentInStore.travelElements],
      }
    }

    set({
      travelElement,
      travelElements,
      likedContent,
    })
  },
  deleteTravelElementLike: async (travelElementId, profileId) => {
    const travelElement = get().travelElement
    travelElement.likesAmount = --travelElement.likesAmount
    travelElement.iLiked = false

    const travelElements = [
      ...get().travelElements.filter(
        (te: ITravelElement) => te.id !== travelElementId
      ),
      travelElement,
    ]

    likeService.deleteLike(
      travelElementId,
      profileId,
      EntityTypeEnum.TRAVEL_ELEMENT
    )
    const likedContentInStore = get().likedContent

    let likedContent = null
    if (likedContentInStore) {
      likedContent = {
        ...likedContentInStore,
        travelElements: likedContentInStore.travelElements.filter(
          (te) => te.id !== travelElementId
        ),
      }
    }

    set({
      travelElement,
      travelElements,
      likedContent,
    })
  },

  removeTravelElementContent: async (travelElementContentId: string) => {
    try {
      const deletedTravelElementContent =
        await travelElementContentService.removeTravelElementContents(
          travelElementContentId
        )

      const currentTravelElement = get().travelElement
      const currentTravelElementContents =
        currentTravelElement.travelElementContents
      const newTravelElementContents = currentTravelElementContents.filter(
        (cte: ITravelElementContent) =>
          cte.id !== deletedTravelElementContent.id
      )

      currentTravelElement.travelElementContents = newTravelElementContents

      get().setAlertMessage(
        "The Travel Element's content has been successfully deleted",
        'success'
      )
      set({
        travelElement: currentTravelElement,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  insertTravelElementContent: async (
    files: IFileWithLocation[],
    travelElementId: string
  ) => {
    try {
      const newTravelElementContents =
        await travelElementService.insertContents(files, travelElementId)

      const currentTravelElement = get().travelElement

      const currentTravelElementContent =
        currentTravelElement.travelElementContents

      currentTravelElement.travelElementContents = [
        ...currentTravelElementContent,
        ...newTravelElementContents,
      ]

      set({ travelElement: currentTravelElement })
      get().setAlertMessage('Images uploaded successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTravelElementContentLike: async (contentId, profileId) => {
    try {
      const travelElement = get().travelElement
      let content

      if (travelElement) {
        const travelElementContents = travelElement?.travelElementContents?.map(
          (tec) => {
            if (tec.id === contentId) {
              content = tec
              return {
                ...tec,
                likesAmount: ++tec.likesAmount,
                iLiked: true,
              }
            }
            return tec
          }
        )
        travelElement.travelElementContents = travelElementContents
      }

      const itineraryTravelElements = get().itineraryTravelElements.map(
        (te: ITravelElement) => {
          const travelElementContents = te.travelElementContents.map(
            (tec: ITravelElementContent) => {
              if (tec.id === contentId) {
                content = tec
                return {
                  ...tec,
                  likesAmount: tec.likesAmount + 1,
                  iLiked: true,
                }
              }
              return tec
            }
          )
          return {
            ...te,
            travelElementContents,
          }
        }
      )

      const profileTravelElements = get().profileTravelElements.map(
        (te: ITravelElement) => {
          const travelElementContents = te.travelElementContents.map((tec) => {
            if (tec.id === contentId) {
              content = tec
              return {
                ...tec,
                likesAmount: tec.likesAmount + 1,
                iLiked: true,
              }
            }
            return tec
          })
          return {
            ...te,
            travelElementContents,
          }
        }
      )

      const likedContentInStore = get().likedContent
      let likedContent = null
      if (likedContentInStore) {
        likedContent = {
          ...likedContentInStore,
          contents: [content, ...likedContentInStore.contents],
        }
      }

      const cacheContents = {
        ...get().cacheContents,
        travelElements: profileTravelElements,
      }

      likeService.addLike(
        contentId,
        profileId,
        EntityTypeEnum.TRAVEL_ELEMENT_CONTENT,
        IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT_CONTENT
      )
      set({
        profileTravelElements,
        itineraryTravelElements,
        travelElement,
        likedContent,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  deleteTravelElementContentLike: (contentId, profileId) => {
    try {
      const travelElement = get().travelElement

      if (travelElement?.id) {
        const travelElementContents = travelElement.travelElementContents.map(
          (tec) => {
            if (tec.id === contentId) {
              return {
                ...tec,
                likesAmount: tec.likesAmount - 1,
                iLiked: false,
              }
            }
            return tec
          }
        )
        travelElement.travelElementContents = travelElementContents
      }

      const profileTravelElements = get().profileTravelElements.map(
        (te: ITravelElement) => {
          const travelElementContents = te.travelElementContents.map((tec) => {
            if (tec.id === contentId) {
              return {
                ...tec,
                likesAmount: tec.likesAmount - 1,
                iLiked: false,
              }
            }
            return tec
          })
          return {
            ...te,
            travelElementContents,
          }
        }
      )

      const itineraryTravelElements = get().itineraryTravelElements.map(
        (te) => {
          const travelElementContents = te.travelElementContents.map((tec) => {
            if (tec.id === contentId) {
              return {
                ...tec,
                likesAmount: --tec.likesAmount,
                iLiked: false,
              }
            }
            return tec
          })
          return { ...te, travelElementContents }
        }
      )

      const likedContentInStore = get().likedContent as any
      let likedContent = null
      if (likedContentInStore) {
        likedContent = {
          ...likedContentInStore,
          contents: likedContentInStore.contents.filter(
            (content) => content.id !== contentId
          ),
        }
      }

      likeService.deleteLike(
        contentId,
        profileId,
        EntityTypeEnum.TRAVEL_ELEMENT_CONTENT
      )

      const cacheContents = {
        ...get().cacheContents,
        travelElements: profileTravelElements,
      }

      set({
        profileTravelElements,
        travelElement,
        itineraryTravelElements,
        likedContent,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTravelElementComment: async (
    comment: string,
    travelElementId: string,
    user: any
  ) => {
    try {
      const commentId = uuidv4()

      const newComment: IComment = {
        id: commentId,
        comment,
        createdAt: Date.now(),
        likesAmount: 0,
        path: `${EntityTypeEnum.TRAVEL_ELEMENT}.${removeDashes(
          travelElementId
        )}`,
        iLiked: false,
        user: {
          id: user.id,
          name: user.name,
          profilePhoto: user.profilePhoto,
          profilePhotoUrl: await getImageUrl(`${user.id}/${user.profilePhoto}`),
        },
      }
      commentService.addComment(
        commentId,
        travelElementId,
        user.id,
        EntityTypeEnum.TRAVEL_ELEMENT,
        IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT,
        comment
      )

      const travelElement = get().travelElement
      travelElement.comments = [newComment, ...travelElement.comments]

      const travelElements = get().travelElements.map((te: ITravelElement) => {
        if (te.id === travelElementId) {
          return travelElement
        }
        return te
      })

      const profileTravelElements = get().profileTravelElements.map(
        (te: ITravelElement) => {
          if (te.id === travelElementId) {
            return travelElement
          }
          return te
        }
      )

      const cacheContents = {
        ...get().cacheContents,
        travelElements: profileTravelElements,
      }

      set({
        profileTravelElements,
        travelElements,
        travelElement,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  deleteTravelElementComment: async (
    commentId: string,
    travelElementId: string
  ) => {
    try {
      await commentService.deleteComment(commentId)

      const travelElement = get().travelElement
      if (travelElement) {
        const comments = travelElement.comments.filter(
          (comment: IComment) => comment.id !== commentId
        )
        travelElement.comments = comments
      }

      const travelElements = get().travelElements.map((te: ITravelElement) => {
        if (te.id === travelElementId) {
          return
        }
        return te
      })

      const profileTravelElements = get().profileTravelElements.map(
        (te: ITravelElement) => {
          if (te.id === travelElementId) {
            return travelElement
          }
          return te
        }
      )

      const cacheContents = {
        ...get().cacheContents,
        travelElements: profileTravelElements,
      }

      set({
        travelElement,
        travelElements,
        profileTravelElements,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTravelElementContentComment: async (
    comment: string,
    contentId: string,
    user: any
  ) => {
    try {
      const commentId = uuidv4()

      const newComment: IComment = {
        id: commentId,
        comment,
        createdAt: Date.now(),
        likesAmount: 0,
        iLiked: false,
        path: `${EntityTypeEnum.TRAVEL_ELEMENT_CONTENT}.${removeDashes(
          contentId
        )}`,
        user: {
          id: user.id,
          name: user.name,
          profilePhoto: user.profilePhoto,
          profilePhotoUrl: await getImageUrl(`${user.id}/${user.profilePhoto}`),
        },
      }
      commentService.addComment(
        commentId,
        contentId,
        user.id,
        EntityTypeEnum.TRAVEL_ELEMENT_CONTENT,
        IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT_CONTENT,
        comment
      )

      const travelElement = get().travelElement
      if (travelElement) {
        const travelElementContents = travelElement.travelElementContents.map(
          (tec) => {
            if (tec.id === contentId) {
              const comments = [newComment, ...tec.comments]
              return {
                ...tec,
                comments,
              }
            }
            return tec
          }
        )
        travelElement.travelElementContents = travelElementContents
      }

      const travelElements: ITravelElement[] = get().travelElements.map(
        (te: ITravelElement) => {
          if (te.id === travelElement.id) {
            return travelElement
          }
          return te
        }
      )

      const profileTravelElements: ITravelElement[] =
        get().profileTravelElements.map((te: ITravelElement) => {
          if (te.id === travelElement.id) {
            return travelElement
          }
          return te
        })

      const cacheContents = {
        ...get().cacheContents,
        travelElements: profileTravelElements,
      }

      set({
        profileTravelElements,
        travelElements,
        travelElement,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  deleteTravelElementContentComment: async (commentId: string) => {
    try {
      await commentService.deleteComment(commentId)

      const travelElement = get().travelElement
      if (travelElement) {
        const travelElementContents = travelElement.travelElementContents.map(
          (tec) => {
            const comments = tec.comments.filter(
              (comment) => comment.id !== commentId
            )
            return {
              ...tec,
              comments,
            }
          }
        )
        travelElement.travelElementContents = travelElementContents
      }

      const travelElements = get().travelElements.map((te: ITravelElement) => {
        if (te.id === travelElement.id) {
          return travelElement
        }
        return te
      })

      const profileTravelElements = get().profileTravelElements.map(
        (te: ITravelElement) => {
          if (te.id === travelElement.id) {
            return travelElement
          }
          return te
        }
      )

      const cacheContents = {
        ...get().cacheContents,
        travelElements: profileTravelElements,
      }

      set({
        travelElement,
        travelElements,
        profileTravelElements,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  getMoreTravelElementComments: async () => {
    try {
      const travelElement = await travelElementService.getMoreComments(
        get().travelElement
      )
      set({ travelElement })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  getMoreTravelElementContentComments: async (contentId) => {
    try {
      const travelElement = await travelElementContentService.getMoreComments(
        get().travelElement,
        contentId
      )
      set({ travelElement })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  cleanTravelElement: () => {
    set({ travelElement: null })
  },
  addTravelElementContentDescription: async (
    description: string,
    travelElementContentId: string
  ) => {
    try {
      const { travelElement, travelElements, profileTravelElements } =
        await travelElementContentService.addDescription(
          description,
          travelElementContentId,
          get().travelElement,
          get().travelElements,
          get().profileTravelElements
        )
      const cacheContents = {
        ...get().cacheContents,
        travelElements: profileTravelElements,
      }

      get().setAlertMessage('Description saved!', 'success')
      set({
        travelElement,
        travelElements,
        profileTravelElements,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  updateTravelElementsInStore: (travelElement: ITravelElement) => {
    const travelElementsInStore = get().travelElements
    const profileTravelElementsInStore = get().profileTravelElements

    const travelElements = travelElementsInStore.map((te: ITravelElement) => {
      if (te.id === travelElement.id) {
        return travelElement
      }
      return te
    })
    const profileTravelElements = profileTravelElementsInStore.map(
      (te: ITravelElement) => {
        if (te.id === travelElement.id) {
          return travelElement
        }
        return te
      }
    )

    const cacheContents = {
      ...get().cacheContents,
      travelElements: profileTravelElements,
    }
    set({
      travelElement,
      travelElements,
      profileTravelElements,
      cacheContents,
    })
  },
  getMoreFollowingTravelElements: async () => {
    const followingContentInStore = get().followingContent
    const profileId = get().profile.id
    const offset = followingContentInStore.travelElements.length

    const followingTravelElements =
      await travelElementService.getMoreFollowingTravelElements(
        profileId,
        offset
      )

    const followingContent = {
      ...followingContentInStore,
      travelElements: [
        ...followingContentInStore.travelElements,
        ...followingTravelElements,
      ],
    }
    set({ followingContent })
  },
  changeTravelElementContentPosition: async (
    travelElementContents: ITravelElementContent[]
  ) => {
    const travelElement = get().travelElement

    const newTravelElementContents =
      await travelElementContentService.changeTravelElementContentOrder(
        travelElementContents
      )

    const orderContents = orderTravelElementContents(newTravelElementContents)

    const newTravelElement: ITravelElement = {
      ...travelElement,
      travelElementContents: orderContents,
    }

    const completeTravelElementWithContent = await completeTravelElementInfo(
      newTravelElement
    )

    set({ travelElement: completeTravelElementWithContent })
  },
})
