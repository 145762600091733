import { StoreSlice } from '.'
import profileService from '../services/profileService'
import followService from '../services/followService'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'

import {
  IProfile,
  ITravatar,
  ITripPlan,
  ITravelog,
  IProfileInfo,
  ITravelElement,
  ISearchResult,
  ITravelElementContent,
  ITripCardContent,
  IBlog,
} from '../types'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import { getProfilePhotoUrl } from '../utils/profile'
import travatarService from '../services/travatarService'

type ProfileSliceState = {
  profile: IProfile | null
  profileTravatars: ITravatar[] | []
  profileTripPlans: ITripPlan[] | []
  profileTravelogs: ITravelog[] | []
  profileBlogs: IBlog[] | []
  profileTravelElements: ITravelElement[] | []
  profileFollowerProfiles: IProfileInfo[] | []
  profileFollowingProfiles: IProfileInfo[] | []
  searchResult: ISearchResult | null
  searchInput: string
  cacheContents: {
    travelElements: ITravelElement[]
    travelogs: ITravelog[]
    tripPlans: ITripPlan[]
  } | null
  searchTravatarId: string | null
  likedContent: {
    travelElements?: ITravelElement[]
    travelogs?: ITravelog[]
    tripPlans?: ITripPlan[]
    contents?: ITravelElementContent[] | ITripCardContent[]
  } | null
  followingContent: {
    travelElements?: ITravelElement[]
    travatars?: ITravatar[]
  } | null
  searchProfiles: IProfileInfo[] | []
}

type ProfileSliceActions = {
  getProfile(profileId: string): Promise<void>
  cleanProfile(): void
  editProfile(newProfileInfo): Promise<void>
  insertProfileFollow(profileToFollowId, user): Promise<void>
  deleteProfileFollow(profileToUnfollowId, userId, profileIdUrl): Promise<void>
  searchInDb(search: string, profileId?: string): Promise<void>
  filterByTravatar(travatarId: string): void
  cleanSearchTravatarId(): void
  getLikedContent(profileId: string): Promise<void>
  getMoreContents(): Promise<void>
  getFollowingContent(profileId: string): Promise<void>
  getMoreProfiles(): Promise<void>
  searchProfilesByName(search: string): void
}

export type ProfileSlice = ProfileSliceState & ProfileSliceActions

const defaultProfileSliceState: ProfileSliceState = {
  profile: null,
  profileTravatars: [],
  profileTravelogs: [],
  profileTravelElements: [],
  profileTripPlans: [],
  profileBlogs: [],
  profileFollowerProfiles: [],
  profileFollowingProfiles: [],
  searchResult: null,
  searchInput: '',
  cacheContents: null,
  searchTravatarId: null,
  likedContent: null,
  followingContent: null,
  searchProfiles: [],
}
export const createProfileSlice: StoreSlice<ProfileSlice> = (set, get) => ({
  ...defaultProfileSliceState,

  cleanProfile: () => {
    set({ ...defaultProfileSliceState })
  },
  getProfile: async (profileId) => {
    try {
      get().setStartLoading()
      const profileInStore = get().profile
      if (!profileInStore?.id || profileInStore?.id !== profileId) {
        const {
          profile,
          profileFollowerProfiles,
          profileFollowingProfiles,
          profileTripPlans,
          profileTravelogs,
          profileTravelElements,
          profileTravatars,
          profileBlogs,
          cacheContents,
        } = await profileService.getProfile(profileId)

        set({
          profile,
          profileFollowerProfiles,
          profileFollowingProfiles,
          profileTravatars,
          profileBlogs,
          profileTripPlans,
          profileTravelogs,
          profileTravelElements,
          cacheContents,
        })
      }
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  editProfile: async (newProfileInfo) => {
    try {
      const profile = await profileService.editProfile(newProfileInfo)
      if (get().storeUser?.profilePhoto !== profile?.profilePhoto) {
        get().changeUserPhoto(profile.profilePhoto, profile.profilePhotoUrl)
      }
      set({
        profile,
      })
    } catch (error) {
      console.log(error)
      get().setAlertMessage(error.message, 'error')
    }
  },
  insertProfileFollow: async (profileToFollowId, user) => {
    const profileInStore = get().profile

    const profile = {
      ...profileInStore,
      followersAmount: ++profileInStore.followersAmount,
      iFollow: true,
    }

    const myProfile: IProfileInfo = {
      id: user.id,
      name: user.name,
      profilePhoto: user.profilePhoto,
      profilePhotoUrl: await getProfilePhotoUrl(user.profilePhoto, user.id),
    }
    const profileFollowerProfiles = [
      myProfile,
      ...get().profileFollowerProfiles,
    ]
    followService.addFollow(
      profileToFollowId,
      user.id,
      EntityTypeEnum.USER,
      IdsEnum.ENTITY_TYPE_PROFILE
    )

    set({
      profile,
      profileFollowerProfiles,
    })
  },
  deleteProfileFollow: async (profileToUnfollowId, userId, profileIdUrl) => {
    const profileFollowing = get().profileFollowingProfiles
    const profileFollowingProfiles = profileFollowing.filter(
      (f: IProfileInfo) => f.id != profileToUnfollowId
    )

    const profileFollowers = get().profileFollowerProfiles
    const profileFollowerProfiles = profileFollowers.filter(
      (pfp: IProfileInfo) => pfp.id != userId
    )

    const profile = get().profile

    if (userId === profileIdUrl) {
      profile.followingsAmount = --profile.followingsAmount
    } else {
      profile.followersAmount = --profile.followersAmount
      profile.iFollow = false
    }

    followService.deleteFollow(profileToUnfollowId, userId, EntityTypeEnum.USER)

    set({
      profile,
      profileFollowingProfiles,
      profileFollowerProfiles,
    })
  },

  searchInDb: async (searchInput, profileId) => {
    if (searchInput) {
      get().setStartLoading({ search: { main: true } })
      const searchResult = await profileService.searchInDb(
        searchInput,
        profileId
      )
      get().setEndLoading()

      set({ searchInput, searchResult })
    } else {
      const cacheContents = get().cacheContents
      set({
        searchInput,
        searchResult: null,
        profileTravelElements: cacheContents?.travelElements,
        profileTripPlans: cacheContents?.tripPlans,
        profileTravelogs: cacheContents?.travelogs,
      })
    }
  },
  filterByTravatar: async (travatarId) => {
    try {
      set({ searchResult: null, searchInput: '', searchTravatarId: travatarId })
      const { travelElements, tripPlans, travelogs } = get().cacheContents
      const profileId = get().profile.id
      if (travatarId) {
        const { profileTravelElements, profileTripPlans, profileTravelogs } =
          await travatarService.searchByTravatar(travatarId, profileId)
        set({ profileTravelElements, profileTripPlans, profileTravelogs })
      } else {
        set({
          profileTravelElements: travelElements,
          profileTripPlans: tripPlans,
          profileTravelogs: travelogs,
        })
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  cleanSearchTravatarId: () => {
    set({ searchTravatarId: null })
  },
  getLikedContent: async (profileId: string) => {
    try {
      get().setStartLoading()
      const likedContent = await profileService.getLikedContent(profileId)
      set({ likedContent })
    } catch (err) {
      if (err instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(err)
        get().setAlertMessage(err.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  getMoreContents: async () => {
    try {
      const userId = get().profile.id
      const likedContentInStore = get().likedContent
      const likedImages = likedContentInStore.contents as any[]

      const offsetTec = likedImages.reduce((acc, image) => {
        if (image?.travelElementContentUrl) {
          return acc + 1
        }
        return acc
      }, 0)

      const offsetTcc = likedImages.reduce((acc, image) => {
        if (image?.tripCardContentUrl) {
          acc = acc + 1
        }
        return acc
      }, 0)

      const contents = await profileService.getMoreLikedContent(
        userId,
        offsetTec,
        offsetTcc
      )

      const likedContent = {
        ...likedContentInStore,
        contents: [...likedImages, ...contents],
      }
      set({ likedContent })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  getFollowingContent: async (profileId: string) => {
    try {
      get().setStartLoading()
      const followingContent = await profileService.getFollowedContent(
        profileId
      )
      set({ followingContent })
    } catch (err) {
      if (err instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(err)
        get().setAlertMessage(err.message, 'error')
      }
    }
  },
  getMoreProfiles: async () => {
    try {
      const userId = get().profile.id
      const searchInput = get().searchInput
      const searchProfilesInStore = get().searchProfiles
      const profileFollowingProfilesInStore = get().profileFollowingProfiles

      const offset = searchInput
        ? searchProfilesInStore.length
        : profileFollowingProfilesInStore.length

      if (searchInput) {
        const profiles = await profileService.searchProfiles(
          searchInput,
          offset
        )
        set({
          searchProfiles: [...searchProfilesInStore, ...profiles],
        })
      } else {
        const profiles = await followService.getMoreFollowings(userId, offset)
        const profileFollowingProfiles = [
          ...profileFollowingProfilesInStore,
          ...profiles,
        ]

        set({ profileFollowingProfiles })
      }
    } catch (err) {
      get().setAlertMessage(err.message, 'error')
      console.log(err)
    }
  },
  searchProfilesByName: async (searchInput) => {
    try {
      get().setStartLoading({ search: { main: true } })
      if (searchInput) {
        const profiles = await profileService.searchProfiles(searchInput)

        set({ searchProfiles: profiles, searchInput })
      } else {
        set({ searchProfiles: [], searchInput })
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    } finally {
      get().setEndLoading()
    }
  },
})
