import { gql } from '@apollo/client'
import { PROFILE_INFO_FRAGMENT } from './profile'

const CHAT_FRAGMENT = gql`
  fragment Chat on chat {
    id
    message
    path
    userId: user_id
    user {
      ...ProfileInfo
    }
    createdAt: created_at
  }
  ${PROFILE_INFO_FRAGMENT}
`
export { CHAT_FRAGMENT }
