import client from '../gql/clients/apollo-client'
import { GET_USER } from '../gql/querys/profile'
import { IProfileInfo } from '../types'
import { getProfilePhotoUrl } from '../utils/profile'

export default {
  getUser: async (userId: string): Promise<IProfileInfo> => {
    const result = await client.query({
      query: GET_USER,
      variables: {
        userId,
      },
    })

    const userResult = result.data.user_by_pk
    const user = {
      ...userResult,
      profilePhotoUrl: await getProfilePhotoUrl(
        userResult?.profilePhoto,
        userResult?.id
      ),
    }
    return user
  },
}
