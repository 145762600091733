import InvalidUuidError from '../customErrors/InvalidUuid.error'
import client from '../gql/clients/apollo-client'
import { INSERT_OR_UPDATE_TRAVEL_ELEMENT } from '../gql/mutations/travelElement'
import {
  GET_MORE_FOLLOWING_TRAVEL_ELEMENTS,
  GET_MORE_LIKED_TRAVEL_ELEMENTS,
  GET_MORE_TRAVEL_ELEMENTS,
  GET_TRAVEL_ELEMENT,
  GET_TRAVEL_ELEMENTS,
  GET_TRAVEL_ELEMENTS_BY_ID,
} from '../gql/querys/travelElement'
import {
  ITravelElement,
  IFileWithLocation,
  ITravelElementContent,
} from '../types'
import { formatPointToCoordinateType, isValidUuid } from '../utils'
import { getTripCardContentUrl } from '../utils/travelContent'
import { getImageUrl, uploadFile } from '../utils/useS3'
import IdsEnum from '../constants/ids.constants'
import { v4 as uuidv4 } from 'uuid'
import { INSERT_TRAVEL_ELEMENT_CONTENTS } from '../gql/mutations/travelElementContent'
import travelogService from './travelogService'
import {
  checkIfTravelElementExists,
  completeTravelElementInfo,
  orderTravelElementContents,
} from '../utils/travelElement'
import commentService from './commentService'
import EntityTypeEnum from '../constants/entityType.constants'
import removeDashes from '../utils/removeDashes'

const getTravelElementStatus = (travelElement): string => {
  if (
    travelElement?.name &&
    travelElement?.description &&
    travelElement?.travatarId &&
    travelElement?.location &&
    (travelElement?.travelElementContents?.length > 0 ||
      travelElement?.mediaFiles?.length ||
      travelElement?.travelElementContentUrl) &&
    travelElement?.statusId !== IdsEnum.STATUS_PUBLISHED
  ) {
    return IdsEnum.STATUS_COMPLETE
  } else if (travelElement?.statusId !== IdsEnum.STATUS_PUBLISHED) {
    return IdsEnum.STATUS_DRAFT
  } else {
    return IdsEnum.STATUS_PUBLISHED
  }
}

export default {
  getTravelElement: async (
    travelElementId: string
  ): Promise<ITravelElement> => {
    if (!isValidUuid(travelElementId)) {
      throw new InvalidUuidError('Invalid Travel Element id')
    }
    const result = await client.query({
      query: GET_TRAVEL_ELEMENT,
      variables: {
        id: travelElementId,
      },
    })
    const travelElement: ITravelElement = result?.data?.travel_element_by_pk

    const travelElementComplete = await completeTravelElementInfo(travelElement)

    return travelElementComplete
  },
  getMoreTravelElements: async (
    userId: string,
    offset: number,
    travatarId?: string,
    searchInput?: string
  ) => {
    if (!isValidUuid(userId)) {
      throw new InvalidUuidError('Invalid User id')
    }
    const nameComparisonExp = searchInput
      ? {
          _ilike: `%${searchInput}%`,
        }
      : {}
    const travatarIdComparisonExp = travatarId ? { _eq: travatarId } : {}

    const result = await client.query({
      query: GET_MORE_TRAVEL_ELEMENTS,
      variables: {
        userId,
        offset,
        nameComparisonExp,
        travatarIdComparisonExp,
      },
    })

    const travelElements = await Promise.all(
      result.data.travel_element.map(
        async (te) => await completeTravelElementInfo(te)
      )
    )

    return travelElements
  },
  getMoreLikedTravelElements: async (userId, offset) => {
    const result = await client.query({
      query: GET_MORE_LIKED_TRAVEL_ELEMENTS,
      variables: { userId, offset },
    })
    const travelElements = (await Promise.all(
      result.data.user_by_pk.likedTravelElements.map(
        async (te) => await completeTravelElementInfo(te)
      )
    )) as ITravelElement[]

    return travelElements
  },
  editTravelElement: async (
    newTravelElementData,
    globePoints,
    globePointsGeoJson
  ) => {
    const imageDate = Date.now()

    const status = getTravelElementStatus(newTravelElementData)

    if (status === IdsEnum.STATUS_PUBLISHED) {
      checkIfTravelElementExists(globePoints, newTravelElementData)
    }

    const result = await client.mutate({
      mutation: INSERT_OR_UPDATE_TRAVEL_ELEMENT,
      variables: {
        id: newTravelElementData?.id,
        cover_photo: newTravelElementData?.heroImage
          ? `travel_element_hero${imageDate}.jpg`
          : newTravelElementData.coverPhoto,
        entityTypeId: IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT,
        statusId: status,
        description: newTravelElementData?.description,
        travatarId: newTravelElementData?.travatarId,
        userId: newTravelElementData?.userId,
        name: newTravelElementData?.name,
        metadata: newTravelElementData?.metadata,
        location: newTravelElementData?.location,
      },
    })

    const newTravelElement = result.data.insert_travel_element_one

    if (newTravelElementData.heroImage) {
      await uploadFile({
        rawKey: `${newTravelElement.id}/travel_element_hero${imageDate}.jpg`,
        contentType: newTravelElementData.heroImage.type,
        file: newTravelElementData.heroImage,
      })
    }

    const travelElement = await completeTravelElementInfo(newTravelElement)

    const relatedTravelogs = await travelogService.completeTravelogsInfo({
      travelogs: travelElement.relatedTravelogs,
    })

    const location = formatPointToCoordinateType(travelElement.location)
    const travelElementGeoJson = {
      type: 'Feature',
      properties: {
        ...travelElement,
        relatedTravelogs,
      },
      geometry: {
        type: 'Point',
        coordinates: [location.longitude, location.latitude, 0.0],
      },
    }
    const featuresInStore = globePointsGeoJson?.features.filter(
      (feature) => feature.properties.id !== travelElement.id
    )

    const globePointsGeoJsonReturn =
      newTravelElement.statusId === IdsEnum.STATUS_PUBLISHED &&
      globePointsGeoJson
        ? {
            type: 'FeatureCollection',
            features: [...featuresInStore, travelElementGeoJson],
          }
        : globePointsGeoJson

    return { travelElement, globePointsGeoJson: globePointsGeoJsonReturn }
  },
  getTravelElements: async (travelElementIds: string[]) => {
    const result = await client.query({
      query: GET_TRAVEL_ELEMENTS_BY_ID,
      variables: {
        travelElementIds,
      },
    })

    let travelElements = result?.data.travel_element
    travelElements = await Promise.all(
      travelElements?.map(async (te) => await completeTravelElementInfo(te))
    )
    return travelElements
  },
  completeTravelElementsInfo: async (
    travelElements: ITravelElement[]
  ): Promise<ITravelElement[]> =>
    await Promise.all(
      travelElements?.map(async (te) => completeTravelElementInfo(te))
    ),
  getGlobePoints: async () => {
    const result = await client.query({
      query: GET_TRAVEL_ELEMENTS,
    })

    const travelElements = await Promise.all(
      result.data.travel_element.map(async (te: ITravelElement) => {
        const relatedTravelogs = await travelogService.completeTravelogsInfo({
          travelogs: te.relatedTravelogs,
        })

        return {
          ...te,
          relatedTravelogs,
        }
      })
    )

    return travelElements
  },
  travelElementGeoJsonFactory: async () => {
    const result = await client.query({
      query: GET_TRAVEL_ELEMENTS,
      fetchPolicy: 'no-cache',
    })
    const travelElementsGeoJson = await Promise.all(
      result.data.travel_element.map(async (te) => {
        const relatedTravelogs = await travelogService.completeTravelogsInfo({
          travelogs: te.relatedTravelogs,
        })

        const location = formatPointToCoordinateType(te.location)
        return {
          type: 'Feature',
          properties: {
            ...te,
            relatedTravelogs,
          },
          geometry: {
            type: 'Point',
            coordinates: [location.longitude, location.latitude, 0.0],
          },
        }
      })
    )
    return travelElementsGeoJson
  },

  getOrAssignNewTravelElement: async (travelElementId: string) => {
    if (!isValidUuid(travelElementId)) {
      throw new InvalidUuidError('Invalid Trip Plan id')
    }

    let travelElement: ITravelElement
    const result = await client.query({
      query: GET_TRAVEL_ELEMENT,
      variables: {
        id: travelElementId,
      },
    })
    const retrieveTravelElement = result?.data.travel_element_by_pk

    if (result.data.travel_element_by_pk) {
      const travelElementContents = orderTravelElementContents(
        retrieveTravelElement.travelElementContents
      )
      const travelElementOrderedContents = {
        ...retrieveTravelElement,
        travelElementContents,
      }
      travelElement = await completeTravelElementInfo(
        travelElementOrderedContents
      )
    } else {
      travelElement = { id: travelElementId, statusId: IdsEnum.STATUS_DRAFT }
    }

    return travelElement
  },
  addYoutubeContent: async (
    travelElementId: string,
    travelElementContentUrl: string,
    contentLength: number
  ) => {
    const travelElementContentVideo = {
      travel_element_content_url: travelElementContentUrl,
      entity_type_id: IdsEnum.ENTITY_TYPE_YOUTUBE,
      travel_element_id: travelElementId,
      metadata: { order: contentLength },
      id: uuidv4(),
    }
    const result = await client.mutate({
      mutation: INSERT_TRAVEL_ELEMENT_CONTENTS,
      variables: { travelElementContents: travelElementContentVideo },
    })

    const newTravelElementContents =
      result?.data?.insert_travel_element_content.returning

    return newTravelElementContents
  },

  addTravelElementContentWithUrl: async (
    travelElementId: string,
    travelElementMediaFiles,
    contentLength: number
  ) => {
    const travelElementContents = travelElementMediaFiles.map((mediaFile) => {
      const file = mediaFile.file
      const localization = mediaFile.localization
      return {
        file: file,
        name: file.name,
        type: file.type,
        location: localization?.latitude
          ? `(${localization?.latitude}, ${localization?.longitude})`
          : null,
        metadata: { description: mediaFile.description, order: contentLength },
        path: file.path,
        entity_type_id: file.type.includes('image')
          ? IdsEnum.ENTITY_TYPE_IMAGE
          : file.type.includes('video')
          ? IdsEnum.ENTITY_TYPE_VIDEO
          : file.type.includes('pdf') && IdsEnum.ENTITY_TYPE_PDF,
      }
    })

    const travelElementContentIdAssign = travelElementContents?.map((tec) => {
      return { ...tec, id: uuidv4() }
    })

    const travelElementContentInsertInput = travelElementContentIdAssign?.map(
      (tec) => {
        return {
          id: tec.id,
          location: tec.location,
          metadata: { ...tec.metadata },
          path: tec.path,
          entity_type_id: tec.file.type.includes('image')
            ? IdsEnum.ENTITY_TYPE_IMAGE
            : tec.file.type.includes('video')
            ? IdsEnum.ENTITY_TYPE_VIDEO
            : tec.file.type.includes('pdf') && IdsEnum.ENTITY_TYPE_PDF,
          travel_element_id: travelElementId,
        }
      }
    )

    const result = await client.mutate({
      mutation: INSERT_TRAVEL_ELEMENT_CONTENTS,
      variables: { travelElementContents: travelElementContentInsertInput },
    })

    const newTravelElementContents =
      result?.data?.insert_travel_element_content.returning

    await Promise.all(
      travelElementContentIdAssign.map(async (tec) => {
        await uploadFile({
          rawKey: `${tec.id}/${tec.name}`,
          contentType: tec.type,
          file: tec.file,
        })
      })
    )

    const newTravelElementContentsWithUrl = await Promise.all(
      newTravelElementContents.map(async (tec: ITravelElementContent) => {
        const travelElementContentUrl = await getTripCardContentUrl(
          tec.id,
          tec.path
        )
        return {
          ...tec,
          travelElementContentUrl,
        }
      })
    )

    return newTravelElementContentsWithUrl
  },
  insertContents: async (
    filesWithLocation: IFileWithLocation[],
    travelElementId: string
  ): Promise<ITravelElementContent[]> => {
    const travelElementInsertInput = filesWithLocation.map(
      (fileWithLocalization) => {
        const file = fileWithLocalization.file
        const localization = fileWithLocalization.localization
        return {
          travelElementId,
          location: localization
            ? `(${localization?.latitude}, ${localization?.longitude})`
            : null,
          path: file.name,
          entity_type_id: file.type.includes('image')
            ? IdsEnum.ENTITY_TYPE_IMAGE
            : file.type.includes('video')
            ? IdsEnum.ENTITY_TYPE_VIDEO
            : file.type.includes('pdf') && IdsEnum.ENTITY_TYPE_PDF,
        }
      }
    )

    const result = await client.mutate({
      mutation: INSERT_TRAVEL_ELEMENT_CONTENTS,
      variables: { travelElementContents: travelElementInsertInput },
    })

    const newTravelElementContents =
      result?.data?.insert_travel_element_content?.returning

    const newTravelElementContentsWithUrl = await Promise.all(
      newTravelElementContents.map(async (tec, index) => {
        const file = filesWithLocation[index].file
        const key = await uploadFile({
          rawKey: `${tec.id}/${file.name}`,
          contentType: file.type,
          file: file,
        })
        const url = await getImageUrl(key)
        return {
          ...tec,
          travelElementContentUrl: url,
        }
      })
    )

    return newTravelElementContentsWithUrl
  },
  insertTravelElementBlogContent: async (blog, contentLength) => {
    const travelElementContentInsertInput = {
      travel_element_id: blog.travelElementId,
      path: blog.id,
      entity_type_id: IdsEnum.ENTITY_TYPE_BLOG_POST,
      metadata: { order: contentLength },
    }

    const result = await client.mutate({
      mutation: INSERT_TRAVEL_ELEMENT_CONTENTS,
      variables: { travelElementContents: travelElementContentInsertInput },
    })

    const newTravelElementContent =
      result?.data?.insert_travel_element_content.returning[0]

    return newTravelElementContent
  },
  getMoreFollowingTravelElements: async (userId, offset) => {
    const result = await client.query({
      query: GET_MORE_FOLLOWING_TRAVEL_ELEMENTS,
      variables: {
        userId,
        offset,
      },
    })
    const travelElements = await Promise.all(
      result?.data?.user_by_pk?.following_travel_elements.map(async (te) => {
        const travelElement = await completeTravelElementInfo(te)
        return travelElement
      })
    )
    return travelElements
  },
  getMoreComments: async (oldTravelElement: ITravelElement) => {
    const offset = oldTravelElement.comments.length
    const path = `${EntityTypeEnum.TRAVEL_ELEMENT}.${removeDashes(
      oldTravelElement.id
    )}`
    const comments = await commentService.getMoreComments(path, offset)
    const travelElement = {
      ...oldTravelElement,
      comments: [...oldTravelElement.comments, ...comments],
    }
    return travelElement
  },
}
