import client from '../gql/clients/apollo-client'
import { getImageUrl, uploadFile } from '../utils/useS3'
import { UPDATE_USER } from '../gql/mutations/profile'
import { getProfilePhotoUrl } from '../utils/profile'
import {
  GET_MORE_LIKED_CONTENT,
  GET_PROFILE_LIKED_CONTENT,
  GET_PROFILE,
  SEARCH_BY_NAME,
  SEARCH_PROFILE_BY_NAME,
} from '../gql/querys/profile'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import { isValidUuid } from '../utils'
import { IProfile, IProfileInfo, ITravatar, ITripPlan } from '../types'
import tripPlanService, { getTripPlanCoverImage } from './tripPlanService'
import travelElementService from './travelElementService'
import { getTravatarCoverPhotoUrl } from './travatarService'
import travelogService from './travelogService'
import { GET_PROFILE_FOLLOWED_CONTENT } from '../gql/querys/follow'

export const getProfileImageUrl = async (profile) => {
  const profilePhoto = profile?.profilePhoto

  let imageUrl = null

  if (profilePhoto) {
    imageUrl = await getImageUrl(`${profile.id}/${profilePhoto}`)
  }

  return {
    ...profile,
    profilePhotoUrl: imageUrl,
  }
}

export default {
  getProfile: async (id: string) => {
    if (!isValidUuid(id)) {
      throw new InvalidUuidError('Invalid profile id')
    }

    const result = await client.query({
      query: GET_PROFILE,
      variables: {
        id,
      },
    })

    if (!result.data.user_by_pk) {
      throw new InvalidUuidError('Invalid profile id')
    }
    const profile = await getProfileImageUrl(result?.data?.user_by_pk)

    const profileFollowerProfiles = await Promise.all(
      profile.followerUsers.map((profile) => getProfileImageUrl(profile))
    )
    const profileFollowingProfiles = await Promise.all(
      profile.followingUsers.map(
        async (profile) => await getProfileImageUrl(profile)
      )
    )

    const profileTravatars: ITravatar[] = await Promise.all(
      profile?.travatars?.map(async (travatar) => {
        const coverPhotoUrl = await getTravatarCoverPhotoUrl(
          travatar?.id,
          travatar?.profilePhoto
        )
        return {
          ...travatar,
          coverPhotoUrl,
        }
      })
    )

    let profileTripPlans = []
    const tripPlansC = profile.tripPlanCollaborations.map((tpc) => {
      return { ...tpc.tripPlan, collaborating: true }
    })

    const tripPlanCollaborations: ITripPlan[] =
      (await tripPlanService.completeTripPlansInfo(tripPlansC)) || []
    const limit =
      tripPlanCollaborations.length < 10
        ? 10 - tripPlanCollaborations.length
        : 0

    const tripPlans: ITripPlan[] = profile?.tripPlans?.slice()

    const completeProfileTripPlans =
      (await tripPlanService.completeTripPlansInfo(
        tripPlans?.splice(0, limit)
      )) || []

    profileTripPlans = [...tripPlanCollaborations, ...completeProfileTripPlans]

    const profileTravelogs = await travelogService.completeTravelogsInfo({
      travelogs: profile.travelogs,
    })

    const profileTravelElements =
      await travelElementService.completeTravelElementsInfo(
        profile?.travelElements
      )

    const cacheContents = {
      travelElements: profileTravelElements,
      travelogs: profileTravelogs,
      tripPlans: profileTripPlans,
    }

    return {
      profile,
      profileFollowerProfiles,
      profileFollowingProfiles,
      profileTripPlans,
      profileTravelogs,
      profileTravelElements,
      profileBlogs: profile.blogs,
      profileTravatars,
      cacheContents,
    }
  },
  editProfile: async (newProfileInfo): Promise<IProfile> => {
    if (!isValidUuid(newProfileInfo.profile.id)) {
      throw new InvalidUuidError('Invalid profile id')
    }

    const result = await client.mutate({
      mutation: UPDATE_USER,
      variables: {
        id: newProfileInfo.profile.id,
        description: newProfileInfo.description,
        name: newProfileInfo.name,
        profilePhoto: newProfileInfo.newProfilePhoto
          ? newProfileInfo.newProfilePhoto?.name
          : newProfileInfo.profile.profilePhoto,
      },
    })

    if (newProfileInfo.newProfilePhoto) {
      await uploadFile({
        rawKey: `${newProfileInfo.profile.id}/${newProfileInfo.newProfilePhoto.name}`,
        file: newProfileInfo.newProfilePhoto,
        contentType: newProfileInfo.newProfilePhoto.type,
      })
    }

    const profilePhotoUrl = await getProfilePhotoUrl(
      result?.data.update_user.returning[0].profilePhoto,
      newProfileInfo.profile.id
    )
    const profile = {
      ...result?.data.update_user.returning[0],
      profilePhotoUrl,
    } as IProfile

    return profile
  },
  searchInDb: async (name, profileId) => {
    const result = await client.query({
      query: SEARCH_BY_NAME,
      variables: { name: `%${name}%`, profileId },
    })

    const travelogs = await Promise.all(
      result.data.travelog.map(async (travelog) => {
        const coverPhotoUrl = await getImageUrl(
          `${travelog?.id}/${travelog?.coverPhoto}`
        )
        return {
          ...travelog,
          coverPhotoUrl,
        }
      })
    )

    const travatars = await Promise.all(
      result.data.travatar.map(async (travatar) => {
        const coverPhotoUrl = await getTravatarCoverPhotoUrl(
          travatar.id,
          travatar.profilePhoto
        )
        return {
          ...travatar,
          coverPhotoUrl,
        }
      })
    )

    const travelElements = await Promise.all(
      result.data.travelElement.map(async (te) => {
        const coverPhotoUrl = await getImageUrl(`${te?.id}/${te?.coverPhoto}`)
        return {
          ...te,
          coverPhotoUrl,
        }
      })
    )

    const tripPlans = await Promise.all(
      result.data.tripPlan.map(async (tp) => {
        const coverPhoto = await getTripPlanCoverImage(tp)
        return {
          ...tp,
          coverPhoto,
        }
      })
    )

    return {
      travelogs,
      travatars,
      travelElements,
      tripPlans,
    }
  },
  getLikedContent: async (profileId: string) => {
    if (!isValidUuid(profileId)) {
      throw new InvalidUuidError('Invalid profile id')
    }
    const result = await client.query({
      query: GET_PROFILE_LIKED_CONTENT,
      variables: { id: profileId },
      fetchPolicy: 'no-cache',
    })

    if (!result.data.user_by_pk) {
      throw new InvalidUuidError('Invalid profile id')
    }

    const {
      likedTravelogs,
      likedTripPlans,
      likedTravelElements,
      likedTravelElementContent,
      likedTripCardContent,
    } = result.data.user_by_pk

    const travelogs = await travelogService.completeTravelogsInfo({
      travelogs: likedTravelogs,
    })
    const tripPlans = await tripPlanService.completeTripPlansInfo(
      likedTripPlans
    )
    const travelElements =
      await travelElementService.completeTravelElementsInfo(likedTravelElements)

    const contents = await Promise.all([
      ...likedTravelElementContent.map(async (tec) => {
        let travelElementContentUrl
        if (!tec.travelElementContentUrl) {
          travelElementContentUrl = await getImageUrl(`${tec?.id}/${tec?.path}`)
        } else {
          travelElementContentUrl = tec.travelElementContentUrl
        }
        return { ...tec, travelElementContentUrl }
      }),
      ...likedTripCardContent.map(async (tcc) => {
        const tripCardContentUrl = await getImageUrl(`${tcc?.id}/${tcc?.path}`)
        return { ...tcc, tripCardContentUrl }
      }),
    ])

    return {
      travelogs,
      tripPlans,
      travelElements,
      contents,
    }
  },
  getMoreLikedContent: async (userId, offsetTec, offsetTcc) => {
    const result = await client.query({
      query: GET_MORE_LIKED_CONTENT,
      variables: { userId, offsetTec, offsetTcc },
    })

    const contents = await Promise.all([
      ...result.data.user_by_pk.likedTravelElementContent.map(async (tec) => {
        let travelElementContentUrl
        if (!tec.travelElementContentUrl) {
          travelElementContentUrl = await getImageUrl(`${tec?.id}/${tec?.path}`)
        } else {
          travelElementContentUrl = tec.travelElementContentUrl
        }
        return { ...tec, travelElementContentUrl }
      }),
      ...result.data.user_by_pk.likedTripCardContent.map(async (tcc) => {
        const tripCardContentUrl = await getImageUrl(`${tcc?.id}/${tcc?.path}`)
        return { ...tcc, tripCardContentUrl }
      }),
    ])

    return contents
  },
  getFollowedContent: async (profileId: string) => {
    if (!isValidUuid(profileId)) {
      throw new InvalidUuidError('Invalid profile id')
    }
    const result = await client.query({
      query: GET_PROFILE_FOLLOWED_CONTENT,
      variables: { id: profileId },
      fetchPolicy: 'no-cache',
    })

    if (!result.data.user_by_pk) {
      throw new InvalidUuidError('Invalid profile id')
    }

    const travatars = await Promise.all(
      result.data.user_by_pk.following_travatars.map(async (travatar) => {
        const coverPhotoUrl = await getTravatarCoverPhotoUrl(
          travatar.id,
          travatar.profilePhoto
        )
        return {
          ...travatar,
          coverPhotoUrl,
        }
      })
    )
    const travelElements = await Promise.all(
      result.data.user_by_pk.following_travel_elements.map(async (te) => {
        const coverPhotoUrl = await getImageUrl(`${te?.id}/${te?.coverPhoto}`)
        return {
          ...te,
          coverPhotoUrl,
        }
      })
    )
    return { travatars, travelElements }
  },
  searchProfiles: async (
    name: string,
    offset?: number
  ): Promise<IProfileInfo[]> => {
    const result = await client.query({
      query: SEARCH_PROFILE_BY_NAME,
      variables: { name: `%${name}%`, offset: offset || 0 },
    })

    const profiles = await Promise.all(
      result.data.user.map(async (profile) => {
        const profilePhotoUrl = await getProfilePhotoUrl(
          profile.profilePhoto,
          profile.id
        )
        return {
          ...profile,
          profilePhotoUrl,
        }
      })
    )

    return profiles
  },
}
