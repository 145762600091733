import { ReactElement, useState } from 'react'
import { useStore } from '../../../store'
import { useForm, SubmitHandler } from 'react-hook-form'
import Modal from '@mui/material/Modal'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import styled from '@emotion/styled'
import { Auth } from 'aws-amplify'
import { ITheme } from '../../../types'
import { Box, CircularProgress } from '@mui/material'

type Inputs = {
  email: string
  password: string
  code?: string
}

const ContainerMain = styled.div(
  ({ theme }: ITheme) => `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 434px;
  width: 100%;
  min-height: 453px;
  background-color: #1b1a29;
  border: 1px solid #5a5784;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  & .close {
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
  }
  & .close svg {
    font-size: 30px;
  }
  & .form {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    font-family: 'Roboto Condensed', sans-serif;
    max-width: 362px;
    width: 97%;
  }
  & label {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    opacity: 0.8;
  }
  & .email,
  & .password {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    max-width: 362px;
    width: 100%;
    height: 45px;
    border-radius: 4px;
    border: none;
    background-color: ${theme.palette.componentBackground.main};
    padding: 12px;
    display: block;
    margin: 3px;
    font-family: inherit;
  }
  & .label-2 {
    margin-top: 21px;
  }
  & .code {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8);
    max-width: 362px;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: none;
    background-color: #32313f;
    padding: 8px 20px 9px;
    display: block;
    margin-top: 7px;
    font-family: inherit;
  }
  & .email:focus,
  & .password:focus {
    outline: #ea8942 1px solid;
    transition: all 0.3s;
  }
  input::placeholder {
    font-size: 15px;
    color: #fff;
    opacity: 0.4;
    font-family: inherit;
  }

  & input[type='submit'] {
    margin: 32px auto 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 37px;
    border-radius: 5px;
    border: none;
    background-color: #ea8942;
    font-size: 16px;
    color: #fff;
    font-family: inherit;
    cursor: pointer;
  }
  & input[type='submit']:hover {
    opacity: 0.8;
    transition: all 0.3s;
  }
  & .form p {
    margin: 0;
    font-size: 14px;
  }
`
)

const Title = styled.h3`
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  margin: 39px 0 40px 0;
`
const TextBottom = styled.p`
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: #fff;
  padding-bottom: 20px;
  & span {
    color: #ea8942;
    cursor: pointer;
  }
`
const PasswordContainer = styled.div`
  position: relative;
  & button {
    position: absolute;
    top: 3px;
    right: 0;
    color: #fff;
    opacity: 0.8;
  }
`

const LoginModal = (): ReactElement => {
  const {
    openLogin,
    setCloseLogin,
    setOpenForgotPassword,
    setOpenSignUp,
    isLoading,
    setStartLoading,
    setEndLoading,
    setAlertMessage,
  } = useStore()
  const [type, setType] = useState(false)
  const [resend, setResend] = useState(false)
  const [showCode, setShowCode] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setStartLoading()
    try {
      const { email, password } = data
      if (showCode) {
        confirmSignUp(data)
      } else {
        await Auth.signIn(email, password)
        setCloseLogin()
      }
    } catch (e) {
      console.log(e)
      setAlertMessage(e.message, 'error')
      if (e.message === 'User is not confirmed.') {
        setResend(true)
      }
      console.error('error:', e.message)
    } finally {
      setEndLoading()
    }
  }

  const handleResendCode = async (): Promise<void> => {
    try {
      setShowCode(true)
      const { email } = getValues()
      await Auth.resendSignUp(email)
    } catch (err) {
      console.error(err)
      setAlertMessage(err.message, 'error')
    }
  }

  const confirmSignUp = async (data: Inputs): Promise<void> => {
    setStartLoading()
    const { email, password, code } = data

    try {
      await Auth.confirmSignUp(email, code)
      const amplifyUser = await Auth.signIn(email, password)

      if (amplifyUser) {
        setCloseLogin()
      } else {
        setAlertMessage('Something went wrong', 'error')
        throw new Error('Something went wrong')
      }
    } catch (error) {
      setAlertMessage(error.message, 'error')
      console.error(error.message)
    } finally {
      setEndLoading()
    }
  }

  const handleType = (): void => setType((state) => !state)

  return (
    <Modal
      open={openLogin}
      onClose={setCloseLogin}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ContainerMain>
        <IconButton onClick={setCloseLogin} className="close">
          <CloseIcon />
        </IconButton>
        <Title>Login</Title>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <label>
            Email
            <input
              {...register('email', { required: true })}
              className="email"
              placeholder="Enter Your Email"
              type="email"
            />
          </label>
          {errors.email && <p>The Email is required</p>}
          <label className="label-2">
            Password
            <PasswordContainer>
              <input
                {...register('password', { required: true })}
                className="password"
                placeholder="Enter Password"
                type={type ? 'text' : 'password'}
              />
              <IconButton onClick={handleType}>
                {type ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </PasswordContainer>
            {errors.password && <p>The Password is required</p>}
          </label>
          {showCode && (
            <>
              <label className="label-2">
                Code
                <input
                  {...register('code', { required: true })}
                  className="code"
                  placeholder="Enter your verification code"
                  type="text"
                />
              </label>
            </>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              sx={{ alignSelf: 'flex-end' }}
              onClick={setOpenForgotPassword}
            >
              Forgot Password?
            </Button>
            {resend && (
              <Button sx={{ alignSelf: 'flex-end' }} onClick={handleResendCode}>
                Resend Code
              </Button>
            )}
          </Box>

          <LoadingButton
            variant="contained"
            loading={isLoading.main}
            loadingIndicator={<CircularProgress size={15} color="primary" />}
            type="submit"
            sx={{ margin: '30px 0', width: 200, alignSelf: 'center' }}
          >
            Login
          </LoadingButton>
        </form>
        <TextBottom>
          New Here? <Button onClick={setOpenSignUp}>Sign-Up Now</Button>
        </TextBottom>
      </ContainerMain>
    </Modal>
  )
}

export default LoginModal
