import client from '../gql/clients/apollo-client'
import { DELETE_COMMENT, INSERT_COMMENT } from '../gql/mutations/comments'
import { GET_MORE_COMMENTS } from '../gql/querys/comment'
import { IComment } from '../types'
import { getProfilePhotoUrl } from '../utils/profile'
import removeDashes from '../utils/removeDashes'
import { getImageUrl } from '../utils/useS3'

export const completeCommentsInfo = async (
  rawComments
): Promise<IComment[]> => {
  const comments = await Promise.all(
    rawComments?.map(async (comment: IComment) => {
      const profilePhotoUrl = await getProfilePhotoUrl(
        comment?.user?.profilePhoto,
        comment?.user?.id
      )
      return {
        ...comment,
        user: {
          ...comment?.user,
          profilePhotoUrl,
        },
      }
    })
  )
  return comments
}

export default {
  addComment: async (
    commentId: string,
    entityId: string,
    userId: string,
    entityType: string,
    entityTypeId: string,
    comment: string
  ): Promise<IComment> => {
    const path = `${entityType}.${removeDashes(entityId)}`
    const result = await client.mutate({
      mutation: INSERT_COMMENT,
      variables: { path, userId, entityTypeId, comment, commentId },
    })
    const commentResult = result.data.insert_comment_one
    const imageUrl = await getImageUrl(
      `${commentResult.user.id}/${commentResult.user.profilePhoto}`
    )
    const commentReturn = {
      ...commentResult,
      user: { ...commentResult.user, profilePhotoUrl: imageUrl },
    }
    return commentReturn
  },
  deleteComment: async (id: string): Promise<void> => {
    await client.mutate({
      mutation: DELETE_COMMENT,
      variables: { id },
    })
  },
  getMoreComments: async (path, offset) => {
    const result = await client.query({
      query: GET_MORE_COMMENTS,
      variables: { path, offset },
    })
    const comments = await completeCommentsInfo(result.data.comment)
    return comments
  },
}
