import { ReactElement, useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import styled from '@emotion/styled'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { TiTick } from 'react-icons/ti'

const PasswordContainer = styled.div`
  position: relative;
  & button {
    position: absolute;
    top: 3px;
    right: 0;
    color: #fff;
    opacity: 0.8;
  }
`
type ValidationSignProps = {
  bgColor: boolean | RegExpMatchArray
}
const ValidationSign = styled.div<ValidationSignProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  background-color: ${(props) => (props.bgColor ? '#14AE5C' : '#D9D9D9')};
  border-radius: 10px;
  padding: 3px 8px;
  p {
    font-weight: 400;
    font-size: 8px;
    color: ${(props) => (props.bgColor ? 'white' : '#707070')};
  }
`

type PasswordAndValidationProps = {
  showCode?: boolean
  register: any
  errors: any
  setDisableButton: any
}

const PasswordAndValidation = ({
  showCode = false,
  register,
  errors,
  setDisableButton,
}: PasswordAndValidationProps): ReactElement => {
  const [type, setType] = useState(false)
  const [password, setPassword] = useState('')

  const handleType = (): void => setType((state) => !state)
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  useEffect(() => {
    if (
      /[0-9]/.test(password) &&
      password.match('.*[A-Z].*') &&
      password.length >= 8
    ) {
      setDisableButton(false)
    } else setDisableButton(true)
  }, [password])

  return (
    <>
      <PasswordContainer>
        <input
          {...register('password', { required: true })}
          className={`${showCode ? 'disabled' : ''} password`}
          placeholder="Enter Password"
          type={type ? 'text' : 'password'}
          disabled={showCode ? true : false}
          onChange={(e) => handlePasswordChange(e)}
        />
        <IconButton onClick={handleType}>
          {type ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        </IconButton>
      </PasswordContainer>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'flex-start',
          padding: '8px 3px;',
        }}
      >
        <ValidationSign bgColor={/[0-9]/.test(password)}>
          <TiTick color={/[0-9]/.test(password) ? 'white' : '#707070'} />
          <p>number</p>
        </ValidationSign>
        <ValidationSign bgColor={password.match('.*[A-Z].*')}>
          <TiTick color={password.match('.*[A-Z].*') ? 'white' : '#707070'} />
          <p>uppercase</p>
        </ValidationSign>
        <ValidationSign bgColor={password.length >= 8}>
          <TiTick color={password.length >= 8 ? 'white' : '#707070'} />
          <p>8 characters</p>
        </ValidationSign>
      </Box>
      {errors.password && <p className="error">The Password is required</p>}
    </>
  )
}

export default PasswordAndValidation
