import { nanoid } from 'nanoid'
import { StoreSlice } from '.'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import commentService from '../services/commentService'
import likeService from '../services/likeService'
import travelElementService from '../services/travelElementService'
import tripCardContentService from '../services/tripCardContentService'
import tripCardService from '../services/tripCardService'
import tripPlanService, {
  completeTripPlanInfo,
} from '../services/tripPlanService'
import {
  IChat,
  IComment,
  IFileWithLocation,
  ITravelElement,
  ITravelog,
  ITripCard,
  ITripCardContent,
  ITripPlan,
  ITripPlanLog,
} from '../types'
import { formatTripCardContentByDays } from '../utils/travelContent'
import { formatItinerary } from '../utils/tripPlan'
import { getImageUrl } from '../utils/useS3'
import { v4 as uuidv4 } from 'uuid'
import removeDashes from '../utils/removeDashes'
import chatService from '../services/chatService'

type TripPlanSliceState = {
  tripPlan: ITripPlan | null
  itinerary: any
  deletedCards: string[]
  tripCardContents: ITripCardContent[] | []
  tripCardContentsPublicAndPrivate: any
  itineraryTravelElements: ITravelElement[]
  tripPlanChat: IChat[]
  tripPlanLog: ITripPlanLog[]
  myTripPlanLogs: ITripPlanLog[]
  selectedCardId: string
}

type TripPlanSliceActions = {
  getOrAssignNewTripPlan(tripPlanId: string, userId: string): void
  getTripPlan(tripPlanId: string): void
  getMoreTripPlans(): void
  getMoreLikedTripPlans(): void
  setTripPlan(tripPlan: ITripPlan): void
  completeAndSetTripPlan(tripPlan: ITripPlan): void
  setItinerary(itinerary: any): void
  insertOrEditTripPlan(newTripPlanInfo, userId)
  refreshItinerary(itinerary: any): void
  addDeletedCard(tripCardId: string): void
  cleanItinerary(): void
  cleanItineraryTravelElements(): void
  cleanTripPlan(): void
  removeTripPlanFromStore(tripPlanId: string): void
  getTripCardContentPublicAndPrivate(tripPlanId: string): void
  removeTripCardContent(travelContents: string): void
  insertTripCardContent(files: IFileWithLocation[], tripCardId: string): void
  insertTripCardContentTravelog(
    files: IFileWithLocation[],
    tripCardId: string
  ): void
  editTripCardContent(travelContent: ITripCardContent): void
  addTripCardContentLike(travelContentId, profileId): Promise<void>
  deleteTripCardContentLike(travelContentId, profileId): Promise<void>
  addTripPlanTravelElement(travelElement: ITravelElement): void
  setTripPlanTravelElements(travelElementsIds: string[]): void
  addTripCardContentComment(
    tripCardContentId: string,
    comment: string,
    user: any
  ): Promise<void>
  deleteTripCardContentComment(
    commentId: string,
    tripCardContentId: string
  ): void
  addTripCardContentDescription(
    description: string,
    tripCardContentId: string
  ): void
  updateTripPlanInStore(tripPlan: ITripPlan): void
  inviteToCollaborate(profileId: string, tripPlanId: string): void
  inviteToCollaborateViaEmail(
    email: string,
    userName: string,
    userId: string,
    path: string
  ): void
  inviteAdvisor(path: string, userName: string, userId: string): Promise<void>
  acceptCollaboration(
    invitationId: string,
    profileId: string
  ): Promise<ITripPlan>
  removeCollaborator(tripPlanId, profileId): void
  setTripPlanChat(chat: IChat[]): void
  fetchMoreTripPlanChats(path, offset): Promise<void>
  setTripPlanLog(log: ITripPlanLog[]): void
  getMoreTripPlanLogs(tripPlanId: string, offset: number): Promise<void>
  setSelectedCardId(id: string): void
  getOrAssignTravelElementToTripPlan(travelElementIds: string[]): Promise<void>
  addTripPlanLog(log): void
  copyTripPlan(tripPlan: ITripPlan | ITravelog, userId: string): Promise<void>
  updateTripCardContentLocation(): void
  updateTripCardLocation(): void
}

export type TripPlanSlice = TripPlanSliceState & TripPlanSliceActions

const defaultTripPlanSliceState: TripPlanSliceState = {
  tripPlan: null,
  itinerary: {
    1: [{ order: 0, description: '', draggableId: nanoid() }],
  },
  deletedCards: [],
  //Default content
  tripCardContents: [],
  tripCardContentsPublicAndPrivate: [],
  itineraryTravelElements: [],
  tripPlanChat: [],
  tripPlanLog: [],
  myTripPlanLogs: [],
  selectedCardId: '',
}

export const createTripPlanSlice: StoreSlice<TripPlanSlice> = (set, get) => ({
  ...defaultTripPlanSliceState,

  getOrAssignTravelElementToTripPlan: async (travelElementIds) => {
    try {
      const travelElements = await travelElementService.getTravelElements(
        travelElementIds
      )

      const itineraryTravelElements = [
        ...get().itineraryTravelElements,
        ...travelElements,
      ]

      set({ itineraryTravelElements })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
      console.log(error)
    }
  },

  getOrAssignNewTripPlan: async (tripPlanId: string, userId: string) => {
    get().setStartLoading()
    try {
      const profileTripPlans = get().profileTripPlans

      let tripPlan = profileTripPlans?.find(
        (tp: ITripPlan) => tp.id === tripPlanId
      )

      if (!tripPlan) {
        tripPlan = await tripPlanService.getOrAssignNewTripPlan(
          tripPlanId,
          userId
        )
      }

      const completeTravelElements =
        await tripPlanService.getTripPlanTravelElements(tripPlan)

      set({ itineraryTravelElements: completeTravelElements }) //TODO: MAKE A COMPUTED FIELD TO AVOID THIS
      get().setTripPlan(tripPlan)
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
        console.log(error)
      }
    } finally {
      get().setEndLoading()
    }
  },
  getTripPlan: async (tripPlanId) => {
    get().setStartLoading()
    try {
      const profileTripPlans = get().profileTripPlans

      let tripPlan = profileTripPlans?.find(
        (tp: ITripPlan) => tp.id === tripPlanId
      )

      if (!tripPlan) {
        tripPlan = await tripPlanService.getTripPlan(tripPlanId)
      }

      const completeTravelElements =
        await tripPlanService.getTripPlanTravelElements(tripPlan)

      set({ itineraryTravelElements: completeTravelElements })

      get().setTripPlan(tripPlan)
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
        console.log(error)
      }
    } finally {
      get().setEndLoading()
    }
  },
  getMoreTripPlans: async () => {
    try {
      const userId = get().profile.id
      const searchInput = get().searchInput
      const searchResultStore = get().searchResult
      const searchResultTripPlans = searchResultStore?.tripPlans
      const searchTravatarId = get().searchTravatarId
      const currentProfileTripPlans: ITripPlan[] = get().profileTripPlans

      const collaborationOffset = currentProfileTripPlans?.reduce(
        (acc, tp: ITripPlan) => {
          if (tp?.collaborating) {
            return acc + 1
          }
          return acc
        },
        0
      )
      const offsetSearch = searchResultTripPlans?.length
      const offsetNotSearch =
        currentProfileTripPlans.length - collaborationOffset
      const profileOffset: number = searchInput ? offsetSearch : offsetNotSearch

      const newSearchTripPlans = await tripPlanService.getMoreTripPlans({
        userId,
        profileOffset,
        collaborationOffset,
        travatarId: searchTravatarId,
        searchInput,
      })

      if (searchInput && newSearchTripPlans?.length) {
        const searchResult = {
          ...searchResultStore,
          tripPlans: [...searchResultTripPlans, ...newSearchTripPlans],
        }
        set({ searchResult })
      } else if (newSearchTripPlans?.length) {
        const profileTripPlans = [
          ...currentProfileTripPlans,
          ...newSearchTripPlans,
        ]
        set({
          profileTripPlans,
        })
      }
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
        console.log(error)
      }
    }
  },
  getMoreLikedTripPlans: async () => {
    try {
      const userId = get().profile.id
      const likedContentInStore = get().likedContent
      const likedTripPlans = likedContentInStore.tripPlans
      const offset = likedTripPlans.length

      const tripPlans = await tripPlanService.getMoreLikedTripPlans(
        userId,
        offset
      )

      const likedContent = {
        ...likedContentInStore,
        tripPlans: [...likedTripPlans, ...tripPlans],
      }
      set({ likedContent })
    } catch (err) {
      get().setAlertMessage(err.message, 'error')
      console.log(err)
    }
  },
  setTripPlan: (tripPlan) => {
    const itinerary = formatItinerary(tripPlan.tripCards)

    get().setItinerary(itinerary)

    set({
      tripPlan,
    })
  },
  completeAndSetTripPlan: async (tripPlan: ITripPlan) => {
    try {
      const completeTripPlan = await completeTripPlanInfo(tripPlan)

      const travelElementsOnTripPlan = tripPlan?.tripCards?.map(
        (tc) => tc?.travelElement
      )

      const itineraryTravelElements =
        await travelElementService.completeTravelElementsInfo(
          travelElementsOnTripPlan
        )

      set({ itineraryTravelElements })
      get().setTripPlan(completeTripPlan)
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  setItinerary: (itinerary) => {
    set({ itinerary })
  },

  insertOrEditTripPlan: async (newTripPlanInfo: ITripPlan, userId: string) => {
    get().setStartLoading({ tripPlan: { mutation: true } })
    try {
      const tripPlan = await tripPlanService.insertOrEditTripPlan(
        newTripPlanInfo
      )

      const tripPlansProfile = get().profileTripPlans.filter(
        (tp: ITripPlan) => tp.id !== tripPlan.id
      )

      const profileTripPlans = [tripPlan, ...tripPlansProfile]

      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }

      const tripCardsToDelete = get().deletedCards

      if (tripCardsToDelete.length) {
        await tripCardService.removeTripCards(tripCardsToDelete)
        tripPlan.tripCards = tripPlan.tripCards.filter(
          (tc) => !tripCardsToDelete.includes(tc.id)
        )
      }

      const itinerary = get().itinerary

      const deletedCards = []

      const newTripCards = await tripCardService.insertOrEditTripCards(
        itinerary,
        tripPlan.id
      )

      await tripPlanService.insertTripPlanLogs(
        newTripPlanInfo,
        get().tripPlan,
        get().myTripPlanLogs,
        userId
      )

      if (newTripCards.length) {
        tripPlan.tripCards = [...newTripCards]
      }

      get().setAlertMessage('Trip Plan Saved', 'success')
      set({
        tripPlan,
        deletedCards,
        profileTripPlans,
        cacheContents,
        myTripPlanLogs: [],
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  addDeletedCard: (tripCardId) => {
    if (tripCardId) {
      const deletedCards = [...get().deletedCards, tripCardId]
      set({
        deletedCards,
      })
    }
  },
  cleanItinerary: () => {
    set({
      itinerary: {
        1: [{ order: 0, description: '', draggableId: nanoid() }],
      },
    })
  },
  cleanItineraryTravelElements: () => {
    set({
      itineraryTravelElements: [],
    })
  },

  cleanTripPlan: () => {
    set({
      tripPlan: null,
      tripPlanChat: [],
      tripPlanLog: [],
    })
  },
  removeTripPlanFromStore: (tripPlanId: string) => {
    const profileTripPlans = get()?.profileTripPlans?.filter(
      (tp: ITripPlan) => tp.id !== tripPlanId
    )
    const cacheContentsInStore = get().cacheContents
    const cacheContents = {
      ...cacheContentsInStore,
      tripPlans: cacheContentsInStore?.tripPlans?.filter(
        (tp: ITripPlan) => tp.id !== tripPlanId
      ),
    }
    set({ profileTripPlans, cacheContents })
  },
  getTripCardContentPublicAndPrivate: async (tripPlanId) => {
    const tripCardContentsPublicAndPrivate =
      await tripCardContentService.getPublicAndPrivates(tripPlanId)
    set({ tripCardContentsPublicAndPrivate })
  },
  removeTripCardContent: async (travelContentId) => {
    try {
      await tripCardContentService.removeTripCardContents(travelContentId)

      const profileTripPlans = get().profileTripPlans.map((tp: ITripPlan) => {
        const tripCards = tp.tripCards.map((tc) => {
          const tripCardContents = tc.tripCardContents.filter(
            (tc) => tc.id !== travelContentId
          )
          return { ...tc, tripCardContents }
        })
        return { ...tp, tripCards }
      })

      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }

      const travelContents = Object.values(
        get().tripCardContentsPublicAndPrivate
      )
        .flat()
        .filter((tc: ITripCardContent) => tc.id !== travelContentId) as unknown
      const tripCardContentsPublicAndPrivate = formatTripCardContentByDays(
        travelContents as ITripCardContent[]
      )

      get().setAlertMessage(
        'The travel content was deleted succesfully',
        'success'
      )
      set({
        profileTripPlans,
        tripCardContentsPublicAndPrivate,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  insertTripCardContent: async (
    files: IFileWithLocation[],
    tripCardId: string
  ) => {
    get().setStartLoading({ travelog: { mutation: true } })
    try {
      const tripCardContents = await tripCardContentService.insertToTripCard(
        files,
        tripCardId
      )

      const tripPlan = get().tripPlan
      const tripCards = tripPlan.tripCards
      tripCards.map((tc) => {
        if (tc.id === tripCardId) {
          tc.tripCardContents = [...tc.tripCardContents, ...tripCardContents]
        }
      })
      tripPlan.tripCards = tripCards
      get().setTripPlan(tripPlan)

      get().setAlertMessage('Images uploaded successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    } finally {
      get().setEndLoading()
    }
  },
  insertTripCardContentTravelog: async (
    files: IFileWithLocation[],
    tripCardId: string
  ) => {
    get().setStartLoading({ travelog: { mutation: true } })
    try {
      const tripCardContents = await tripCardContentService.insertToTripCard(
        files,
        tripCardId
      )

      const travelog = get().travelog
      const tripCards = travelog.tripCards
      tripCards.map((tc) => {
        if (tc.id === tripCardId) {
          tc.tripCardContents = [...tc.tripCardContents, ...tripCardContents]
        }
      })
      travelog.tripCards = tripCards
      get().setTravelog(travelog)

      get().setAlertMessage('Images uploaded successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    } finally {
      get().setEndLoading()
    }
  },
  editTripCardContent: async (tripCardContent: ITripCardContent) => {
    try {
      const tripCardContentUpdated =
        await tripCardContentService.editTripCardContent(tripCardContent)

      const profileTripPlans = get().profileTripPlans.map((tp: ITripPlan) => {
        const tripCards = tp.tripCards.map((tc) => {
          const tripCardContents = tc.tripCardContents.map((tc) => {
            if (tc.id === tripCardContentUpdated.id) {
              return tripCardContentUpdated
            }
            return tc
          })
          return { ...tc, tripCardContents }
        })
        return { ...tp, tripCards }
      })

      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }

      set({
        profileTripPlans,
        cacheContents,
      })

      get().setAlertMessage('Media updated successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTripCardContentLike: async (tripCardContentId, profileId) => {
    try {
      const tripPlan = get().tripPlan
      let content

      const tripCards = tripPlan.tripCards?.map((tc: ITripCard) => {
        const tripCardContents = tc?.tripCardContents.map((tcc) => {
          if (tcc.id === tripCardContentId) {
            content = tcc
            return {
              ...tcc,
              likesAmount: tcc.likesAmount + 1,
              iLiked: true,
            }
          }
          return tcc
        })
        return {
          ...tc,
          tripCardContents,
        }
      })

      tripPlan.tripCards = tripCards
      const profileTripPlans = get().profileTripPlans.map((tp) => {
        if (tp.id === tripPlan.id) {
          return tripPlan
        }
        return tp
      })

      const likedContentInStore = get().likedContent
      let likedContent = null
      if (likedContentInStore) {
        likedContent = {
          ...likedContentInStore,
          contents: [content, ...likedContentInStore.contents],
        }
      }

      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }

      likeService.addLike(
        tripCardContentId,
        profileId,
        EntityTypeEnum.TRIP_CARD_CONTENT,
        IdsEnum.ENTITY_TYPE_TRIP_CARD_CONTENT
      )
      set({
        tripPlan,
        profileTripPlans,
        likedContent,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  deleteTripCardContentLike: async (tripCardContentId, profileId) => {
    try {
      const tripPlan = get().tripPlan

      const tripCards = tripPlan.tripCards?.map((tc: ITripCard) => {
        const tripCardContents = tc?.tripCardContents.map((tcc) => {
          if (tcc.id === tripCardContentId) {
            return {
              ...tcc,
              likesAmount: tcc.likesAmount - 1,
              iLiked: false,
            }
          }
          return tcc
        })
        return {
          ...tc,
          tripCardContents,
        }
      })
      tripPlan.tripCards = tripCards

      const profileTripPlans = get().profileTripPlans.map((tp) => {
        if (tp.id === tripPlan.id) {
          return tripPlan
        }
        return tp
      })

      const likedContentInStore = get().likedContent as any
      let likedContent = null
      if (likedContentInStore) {
        likedContent = {
          ...likedContentInStore,
          contents: likedContentInStore.contents.filter(
            (content) => content.id !== tripCardContentId
          ),
        }
      }

      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }

      likeService.deleteLike(
        tripCardContentId,
        profileId,
        EntityTypeEnum.TRIP_CARD_CONTENT
      )

      set({
        tripPlan,
        profileTripPlans,
        likedContent,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTripPlanTravelElement: async (travelElement: ITravelElement) => {
    try {
      const oldTripPlanTravelElements = get().itineraryTravelElements
      const itineraryTravelElements = [
        ...oldTripPlanTravelElements,
        travelElement,
      ]
      set({
        itineraryTravelElements,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  setTripPlanTravelElements: async (travelElementsIds: string[]) => {
    try {
      const itineraryTravelElements =
        await travelElementService.getTravelElements(travelElementsIds)

      set({
        itineraryTravelElements,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTripCardContentComment: async (
    tripCardContentId: string,
    comment: string,
    user: any
  ) => {
    try {
      const tripPlan = get().tripPlan

      const commentId = uuidv4()

      const newComment: IComment = {
        id: commentId,
        comment,
        createdAt: Date.now(),
        likesAmount: 0,
        path: `${EntityTypeEnum.TRIP_CARD_CONTENT}.${removeDashes(
          tripCardContentId
        )}`,
        iLiked: false,
        user: {
          id: user.id,
          name: user.name,
          profilePhoto: user.profilePhoto,
          profilePhotoUrl: await getImageUrl(`${user.id}/${user.profilePhoto}`),
        },
      }

      commentService.addComment(
        commentId,
        tripCardContentId,
        user.id,
        EntityTypeEnum.TRIP_CARD_CONTENT,
        IdsEnum.ENTITY_TYPE_TRIP_CARD_CONTENT,
        comment
      )

      const tripCards = tripPlan.tripCards?.map((tc: ITripCard) => {
        const tripCardContents = tc?.tripCardContents.map((tcc) => {
          if (tcc.id === tripCardContentId) {
            return {
              ...tcc,
              comments: [newComment, ...tcc.comments],
            }
          }
          return tcc
        })
        return {
          ...tc,
          tripCardContents,
        }
      })
      tripPlan.tripCards = tripCards

      const profileTripPlans = get().profileTripPlans.map((tp) => {
        if (tp.id === tripPlan.id) {
          return tripPlan
        }
        return tp
      })
      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }

      set({
        tripPlan,
        profileTripPlans,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  deleteTripCardContentComment: (
    commentId: string,
    tripCardContentId: string
  ) => {
    try {
      const tripPlan = get().tripPlan

      const tripCards = tripPlan.tripCards?.map((tc: ITripCard) => {
        const tripCardContents = tc?.tripCardContents.map((tcc) => {
          if (tcc.id === tripCardContentId) {
            return {
              ...tcc,
              comments: tcc.comments.filter(
                (comment) => comment.id !== commentId
              ),
            }
          }
          return tcc
        })
        return {
          ...tc,
          tripCardContents,
        }
      })

      tripPlan.tripCards = tripCards
      const profileTripPlans = get().profileTripPlans.map((tp) => {
        if (tp.id === tripPlan.id) {
          return tripPlan
        }
        return tp
      })

      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }

      set({
        tripPlan,
        profileTripPlans,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTripCardContentDescription: async (
    description: string,
    tripCardContentId: string
  ) => {
    try {
      const { tripPlan, profileTripPlans } =
        await tripCardContentService.addDescription(
          tripCardContentId,
          description,
          get().tripPlan,
          get().profileTripPlans
        )

      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }
      get().setAlertMessage('Description saved!', 'success')
      set({ tripPlan, profileTripPlans, cacheContents })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  updateTripPlanInStore: (tripPlan: ITripPlan) => {
    const profileTripPlans: ITripPlan[] = get().profileTripPlans
    const indexOfTripPlan = profileTripPlans.findIndex(
      (tp) => tp.id === tripPlan.id
    )
    if (indexOfTripPlan !== -1) {
      profileTripPlans.splice(indexOfTripPlan, 1, tripPlan)
    }
    set({ tripPlan, profileTripPlans })
  },
  inviteToCollaborate: async (profileId: string, tripPlanId: string) => {
    try {
      const user = get().profile
      const userId = user?.id
      const path = `${user.id}/${user.profilePhoto}`

      const tripPlan = get().tripPlan
      if (!tripPlan.userId) {
        await get().insertOrEditTripPlan(
          {
            ...tripPlan,
            userId,
          },
          userId
        )
      }
      await tripPlanService.inviteToCollaborate(
        profileId,
        tripPlanId,
        user.name,
        path
      )
      get().setAlertMessage('Invitation sent successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  inviteToCollaborateViaEmail: async (
    email: string,
    userName: string,
    userId: string,
    path: string
  ) => {
    try {
      const tripPlan = get().tripPlan

      if (!tripPlan.userId) {
        await get().insertOrEditTripPlan(
          {
            ...tripPlan,
            userId,
          },
          userId
        )
      }

      await tripPlanService.inviteToCollaborateViaEmail(
        email,
        tripPlan.id,
        userName,
        path,
        get().storeUser?.profilePhotoUrl
      )

      get().setAlertMessage('Invitation sent successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  inviteAdvisor: async (path, userName, userId) => {
    try {
      const tripPlan = get().tripPlan

      if (!tripPlan.userId) {
        await get().insertOrEditTripPlan(
          {
            ...tripPlan,
            userId,
          },
          userId
        )
      }
      await tripPlanService.inviteAdvisor(tripPlan?.id, userName, path)
      get().setAlertMessage('Invitation sent successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  acceptCollaboration: async (
    invitationId: string,
    profileId: string
  ): Promise<ITripPlan> => {
    try {
      const tripPlan = await tripPlanService.acceptTripPlanCollaboration(
        invitationId,
        profileId
      )

      const profileTripPlansInStore = get().profileTripPlans

      const isTripPlanInStore = profileTripPlansInStore?.find(
        (tp: ITripPlan) => tp.id === tripPlan.id
      )

      if (!isTripPlanInStore && tripPlan && profileTripPlansInStore?.length) {
        const profileTripPlans = [tripPlan, ...profileTripPlansInStore]
        set({
          profileTripPlans,
          cacheContents: {
            ...get().cacheContents,
            tripPlans: profileTripPlans,
          },
        })
      } else if (!isTripPlanInStore && tripPlan) {
        set({
          profileTripPlans: [tripPlan],
          cacheContents: {
            ...get().cacheContents,
            tripPlans: [tripPlan],
          },
        })
      }
      return tripPlan
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  removeCollaborator: async (tripPlanId: string, profileId: string) => {
    try {
      const tripPlanInStore = get().tripPlan
      const profileTripPlans = get().profileTripPlans

      const tripPlan = {
        ...tripPlanInStore,
        tripPlanCollaborators: tripPlanInStore.tripPlanCollaborators.filter(
          (tp) => tp.id !== profileId
        ),
      }

      const index = profileTripPlans.findIndex(
        (tp: ITripPlan) => tp.id === tripPlan.id
      )

      if (index !== -1) {
        profileTripPlans.splice(index, 1, tripPlan)
      }

      await tripPlanService.removeCollaborator(tripPlanId, profileId)

      set({ tripPlan })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  setTripPlanChat: async (chats: IChat[]) => {
    try {
      if (chats) {
        const tripPlanChatInStore = get().tripPlanChat
        const tripPlanChat = await chatService.filterAndCompleteChat(
          chats,
          tripPlanChatInStore
        )
        set({ tripPlanChat })
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  fetchMoreTripPlanChats: async (path, offset) => {
    try {
      const chats = await chatService.getMoreChat(path, offset)
      const tripPlanChat = [...get().tripPlanChat, ...chats]
      set({ tripPlanChat })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  setTripPlanLog: async (logs: ITripPlanLog[]) => {
    try {
      if (logs) {
        const tripPlanLogInStore = get().tripPlanLog

        const tripPlanLog = await tripPlanService.filterAndCompleteTripPlanLogs(
          logs,
          tripPlanLogInStore
        )
        set({ tripPlanLog })
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  setSelectedCardId: (selectedCardId: string) => {
    set({ selectedCardId })
  },
  getMoreTripPlanLogs: async (tripPlanId: string, offset: number) => {
    const tripPlanLogs = await tripPlanService.getMoreTripPlanLogs(
      tripPlanId,
      offset
    )
    set({ tripPlanLog: [...get().tripPlanLog, ...tripPlanLogs] })
  },
  refreshItinerary: async (newItinerary) => {
    set({ itinerary: newItinerary })
  },
  addTripPlanLog: (log) => {
    const logsInStore = get().myTripPlanLogs
    const logs = [log, ...logsInStore]
    set({ myTripPlanLogs: logs })
  },
  copyTripPlan: async (tripPlan: ITripPlan | ITravelog, userId: string) => {
    try {
      const { profileTripPlans, tripPlanId } =
        await tripPlanService.copyTripPlan(
          tripPlan,
          userId,
          get().profileTripPlans
        )
      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }
      get().setAlertMessage(
        'Trip plan copied into your profile successfully',
        'success'
      )
      set({ profileTripPlans, cacheContents })
      return tripPlanId
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  updateTripCardContentLocation: async () => {
    try {
      const mapCoordinate = get().mapCoordinate
      const tripCardContentId = get().relocateContentId

      const { profileTripPlans, cacheContents, tripPlan } =
        await tripCardContentService.updateTripCardContentLocation(
          mapCoordinate,
          tripCardContentId,
          get().profileTripPlans,
          get().cacheContents,
          get().tripPlan
        )

      get().setTripPlan(tripPlan)
      set({
        profileTripPlans,
        cacheContents,
      })
      get().setAlertMessage('Location saved successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  updateTripCardLocation: async () => {
    try {
      const mapCoordinate = get().mapCoordinate
      const tripCardId = get().relocateContentId

      const { entity: tripPlan, profileEntity: profileTripPlans } =
        await tripCardService.updateTripCardLocation(
          mapCoordinate,
          tripCardId,
          get().profileTripPlans,
          get().tripPlan
        )

      const cacheContents = {
        ...get().cacheContents,
        tripPlans: profileTripPlans,
      }

      get().setTripPlan(tripPlan)
      set({
        profileTripPlans,
        cacheContents,
      })
      get().setAlertMessage('Location saved successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
})
