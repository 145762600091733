import { gql } from '@apollo/client'
import { TRAVEL_ELEMENT_FRAGMENT } from '../fragments/travelElement'

const INSERT_OR_UPDATE_TRAVEL_ELEMENT = gql`
  mutation InsertOrUpdateTravelElement(
    $cover_photo: String
    $description: String
    $name: String
    $travatarId: uuid
    $statusId: uuid
    $location: point
    $metadata: jsonb
    $entityTypeId: uuid
    $id: uuid!
    $userId: uuid
  ) {
    insert_travel_element_one(
      object: {
        user_id: $userId
        description: $description
        status_id: $statusId
        location: $location
        travatar_id: $travatarId
        cover_photo: $cover_photo
        entity_type_id: $entityTypeId
        metadata: $metadata
        name: $name
        id: $id
      }
      on_conflict: {
        constraint: travel_element_pkey
        update_columns: [
          user_id
          description
          location
          status_id
          travatar_id
          cover_photo
          entity_type_id
          metadata
          name
        ]
      }
    ) {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`

export { INSERT_OR_UPDATE_TRAVEL_ELEMENT }
