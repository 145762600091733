import { gql } from '@apollo/client'

const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on notification {
    id
    body
    to
    isRead: read
    createdAt: created_at
  }
`
export { NOTIFICATION_FRAGMENT }
