/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactElement, useEffect } from 'react'
import { useStore } from '../../../store'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import styled from '@emotion/styled'
import Image from 'next/image'
import { Typography } from '@mui/material'

const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 434px;
  width: 100%;
  min-height: 353px;
  background-color: #1b1a29;
  border: 1px solid #5a5784;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  & .close {
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
  }
  & .close svg {
    font-size: 30px;
  }
`

const AboutSkipd = (): ReactElement => {
  const { openAboutSkipd, setCloseAboutSkipd, appVersion, getAppVersion } =
    useStore()

  useEffect(() => {
    if (!appVersion) {
      getAppVersion()
    }
  }, [appVersion])

  return (
    <Modal open={openAboutSkipd} onClose={setCloseAboutSkipd}>
      <>
        <MainContainer>
          <IconButton onClick={setCloseAboutSkipd} className="close">
            <CloseIcon />
          </IconButton>
          <Image width="200px" height="240px" src="/images/logoglobe.svg" />
          <Typography variant="body1">App version: {appVersion}</Typography>
        </MainContainer>
      </>
    </Modal>
  )
}

export default AboutSkipd
