import { StoreSlice } from '.'
import travatarService from '../services/travatarService'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import likeService from '../services/likeService'
import followService from '../services/followService'

import { ITravatar, ITravelElement, ITravelog, ITripPlan } from '../types'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import travelElementService from '../services/travelElementService'
import tripPlanService from '../services/tripPlanService'
import travelogService from '../services/travelogService'
import { getProfilePhotoUrl } from '../utils/profile'

type TravatarSliceState = {
  travatar: ITravatar | null
  travatars: ITravatar[] | []
  travatarTravelogs: ITravelog[] | []
  travatarTripPlans: ITripPlan[] | []
  travatarTravelElements: ITravelElement[] | []
}

type TravatarSliceActions = {
  getCompleteTravatar(travatarId: string): Promise<void>
  cleanTravatar(): void
  createOrEditTravatar(newPhoto: any, newTravatar)
  editTravatar(newPhoto: any, newTravatarData: ITravatar)
  addTravatarLike(travatarId: string, profileId: string): Promise<void>
  deleteTravatarLike(travatarId: string, profileId: string): Promise<void>
  insertTravatarFollow(travatarId: string, profileId: string): Promise<void>
  deleteTravatarFollow(travatarId: string, profileId: string): Promise<void>
  deleteTravatar(travatarId: string, profileId: string): Promise<void>
  getOrAssignNewTravatar(travatarId: string): void
  getMoreTravatarTravelElements(): Promise<void>
  getMoreTravatarTravelogs(): Promise<void>
  getMoreTravatarTripPlans(): Promise<void>
  getMoreFollowingTravatars(): Promise<void>
}

export type TravatarSlice = TravatarSliceState & TravatarSliceActions

const defaultTravatarSliceState: TravatarSliceState = {
  travatar: null,
  travatars: null,
  travatarTravelogs: [],
  travatarTripPlans: [],
  travatarTravelElements: [],
}

export const createTravatarSlice: StoreSlice<TravatarSlice> = (set, get) => ({
  ...defaultTravatarSliceState,

  getCompleteTravatar: async (travatarId: string) => {
    get().setStartLoading()
    try {
      const {
        travatar: fetchTravatar,
        travatarTravelogs,
        travatarTripPlans,
        travatarTravelElements,
      } = await travatarService.getCompleteTravatar(travatarId)

      const travatars = get().travatars || []
      const travatar = get().travatar || {}

      const travatarInStore = [
        ...get().profileTravatars,
        ...travatars,
        travatar,
      ].find((travatar: ITravatar) => travatar?.id === travatarId)

      const setTravatar = travatarInStore || fetchTravatar

      set({
        travatar: setTravatar,
        travatarTravelogs,
        travatarTripPlans,
        travatarTravelElements,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  getOrAssignNewTravatar: async (travatarId) => {
    get().setStartLoading()
    try {
      const profileTravatars = get().profileTravatars

      let travatar = profileTravatars.find(
        (t: ITravatar) => t?.id === travatarId
      )
      if (!travatar) {
        travatar = await travatarService.getOrAssignNewTravatar(travatarId)
      }
      set({
        travatar,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  createOrEditTravatar: async (newPhoto, newTravatarData) => {
    get().setStartLoading({ travatar: { mutation: true } })
    try {
      const currentProfileTravatars = get().profileTravatars

      const newTravatar = await travatarService.createOrEditTravatar(
        newPhoto,
        newTravatarData
      )

      const filterTravatars = currentProfileTravatars.filter(
        (travatar: ITravatar) => travatar.id !== newTravatarData.id
      )

      const profileTravatars = [newTravatar, ...filterTravatars]
      const cacheContents = {
        ...get().cacheContents,
        travatars: profileTravatars,
      }
      const travatar = newTravatar

      set({
        profileTravatars,
        travatar,
        cacheContents,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  editTravatar: async (newPhoto, newTravatarData: ITravatar) => {
    get().setStartLoading({ travatar: { mutation: true } })

    try {
      const travatars = get().profileTravatars

      const travatar = await travatarService.editTravatar(
        newPhoto,
        newTravatarData
      )

      const filterTravatars = travatars.filter(
        (travatar: ITravatar) => travatar.id !== newTravatarData.id
      )
      const profileTravatars = [travatar, ...filterTravatars]
      const cacheContents = {
        ...get().cacheContents,
        travatars: profileTravatars,
      }

      set({
        profileTravatars,
        cacheContents,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  addTravatarLike: async (travatarId: string, profileId: string) => {
    const travatarInStore = get().travatar

    const travatar = {
      ...travatarInStore,
      likesAmount: ++travatarInStore.likesAmount,
      iLiked: true,
    }

    likeService.addLike(
      travatarId,
      profileId,
      EntityTypeEnum.TRAVATAR,
      IdsEnum.ENTITY_TYPE_TRAVATAR
    )

    set({
      travatar,
    })
  },
  deleteTravatarLike: async (travatarId: string, profileId: string) => {
    const travatarInStore = get().travatar

    const travatar = {
      ...travatarInStore,
      likesAmount: travatarInStore.likesAmount - 1,
      iLiked: false,
    }

    likeService.deleteLike(travatarId, profileId, EntityTypeEnum.TRAVATAR)

    set({
      travatar,
    })
  },
  insertTravatarFollow: async (travatarId: string, profileId: string) => {
    try {
      const follow = await followService.addFollow(
        travatarId,
        profileId,
        EntityTypeEnum.TRAVATAR,
        IdsEnum.ENTITY_TYPE_TRAVATAR
      )

      const oldTravatars: ITravatar[] = get()?.travatars
      const travatarInStore = get().travatar
      const travatar: ITravatar = {
        ...travatarInStore,
        followersAmount: travatarInStore.followersAmount + 1,
        iFollow: true,
      }

      const url = await getProfilePhotoUrl(
        follow?.user?.profilePhoto,
        follow?.userId
      )
      travatar.followerUsers.push({
        id: follow?.userId,
        name: follow?.user?.name,
        profilePhotoUrl: url,
      })

      const travatars = oldTravatars?.filter(
        (travatar: ITravatar) => travatar.id !== travatarId
      )

      if (travatars) travatars?.push(travatar)

      const followingContentInStore = get().followingContent || {
        travatars: [],
      }
      const followingContent = {
        ...followingContentInStore,
        travatars: [travatar, ...followingContentInStore.travatars],
      }

      set({
        travatar,
        travatars,
        followingContent,
      })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
      console.log(error)
    }
  },
  deleteTravatarFollow: async (travatarId: string, profileId: string) => {
    try {
      await followService.deleteFollow(
        travatarId,
        profileId,
        EntityTypeEnum.TRAVATAR
      )

      const oldTravatars: ITravatar[] = get()?.travatars
      const travatars = oldTravatars?.filter(
        (travatar: ITravatar) => travatar.id !== travatarId
      )
      const travatarInStore = get().travatar

      const travatar: ITravatar = travatarInStore
        ? {
            ...travatarInStore,
            followersAmount: travatarInStore.followersAmount - 1,
            iFollow: false,
            followerUsers: travatarInStore.followerUsers.filter(
              (user) => user.id !== profileId
            ),
          }
        : ({} as ITravatar)

      if (travatarInStore) {
        travatars?.push(travatar)
      }

      const followingContentInStore = get().followingContent
      const followingContent = {
        ...followingContentInStore,
        travatars: followingContentInStore.travatars.filter(
          (travatar: ITravatar) => travatar.id !== travatarId
        ),
      }

      set({
        travatar,
        travatars,
        followingContent,
      })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
      console.log(error)
    }
  },
  deleteTravatar: async (travatarId: string) => {
    try {
      await travatarService.deleteTravatar(travatarId)

      const travatars = get().profileTravatars
      const profileTravatars = travatars.filter(
        (travatar: ITravatar) => travatar.id !== travatarId
      )

      get().setAlertMessage('Travatar deleted successfully', 'success')
      set({ profileTravatars })
    } catch (err) {
      if (err.message.includes('Foreign key violation'))
        get().setAlertMessage(
          'Travatar could not be deleted because it has Travelogs or Trip Plans',
          'error'
        )
      else {
        get().setAlertMessage(err.message, 'error')
        console.log(err)
      }
    }
  },
  cleanTravatar: () => {
    set({ travatar: null })
  },
  getMoreTravatarTravelElements: async () => {
    try {
      const travatar = get().travatar
      const travatarTravelElementsInStore = get().travatarTravelElements
      const offset = travatarTravelElementsInStore.length

      const travelElements = await travelElementService.getMoreTravelElements(
        travatar.userId,
        offset,
        travatar.id
      )

      const travatarTravelElements = [
        ...travatarTravelElementsInStore,
        ...travelElements,
      ]
      set({ travatarTravelElements })
    } catch (err) {
      get().setAlertMessage(err.message, 'error')
      console.log(err)
    }
  },
  getMoreTravatarTripPlans: async () => {
    try {
      const travatar = get().travatar
      const travatarTripPlansInStore = get().travatarTripPlans
      const offset = travatarTripPlansInStore.length

      const tripPlans = await tripPlanService.getMoreTripPlans({
        userId: travatar.userId,
        profileOffset: offset,
        travatarId: travatar?.id,
      })

      const travatarTripPlans = [...travatarTripPlansInStore, ...tripPlans]
      set({ travatarTripPlans })
    } catch (err) {
      get().setAlertMessage(err.message, 'error')
      console.log(err)
    }
  },
  getMoreTravatarTravelogs: async () => {
    try {
      const travatar = get().travatar
      const travatarTravelogsInStore = get().travatarTravelogs
      const offset = travatarTravelogsInStore.length

      const travelogs = await travelogService.getMoreTravelogs(
        travatar.userId,
        offset,
        travatar.id
      )

      const travatarTravelogs = [...travatarTravelogsInStore, ...travelogs]
      set({ travatarTravelogs })
    } catch (err) {
      get().setAlertMessage(err.message, 'error')
      console.log(err)
    }
  },
  getMoreFollowingTravatars: async () => {
    const followingContentInStore = get().followingContent
    const profileId = get().profile.id
    const offset = followingContentInStore.travatars.length

    const followingTravatars = await travatarService.getMoreFollowingTravatars(
      profileId,
      offset
    )

    const followingContent = {
      ...followingContentInStore,
      travatars: [...followingContentInStore.travatars, ...followingTravatars],
    }
    set({ followingContent })
  },
})
