import create, { SetState, GetState } from 'zustand'
import { persist } from 'zustand/middleware'
import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { ProfileSlice, createProfileSlice } from './profile-slice'
import { TravatarSlice, createTravatarSlice } from './travatar-slice'
import { TripPlanSlice, createTripPlanSlice } from './trip-plan-slice'
import { TravelogSlice, createTravelogSlice } from './travelog-slice'
import { SocialSlice, createSocialSlice } from './social-slice'
import { AppSlice, createAppSlice } from './app-slice'

import { isBrowser } from '../utils'
import {
  createTravelElementSlice,
  TravelElementSlice,
} from './travel-element-slice'
import { createMapSlice, MapSlice } from './map-slice'
import { BlogSlice, createBlogSlice } from './blog-slice'
import { createUserSlice, UserSlice } from './user-slice'

// typescript slicing: https://bit.ly/3qgvLbn
export type AppState = ProfileSlice &
  TravatarSlice &
  TripPlanSlice &
  TravelElementSlice &
  BlogSlice &
  TravelogSlice &
  SocialSlice &
  AppSlice &
  MapSlice &
  UserSlice
export type StoreSlice<T> = (
  set: SetState<AppState>,
  get: GetState<AppState>
) => T

// standard zustand store https://github.com/pmndrs/zustand
const useStoreBase = create<AppState>(
  // persist middleware:  https://bit.ly/33nrQkj
  persist(
    // compose all slices into AppState
    (set, get) => ({
      ...createProfileSlice(set, get),
      ...createTravatarSlice(set, get),
      ...createTripPlanSlice(set, get),
      ...createTravelogSlice(set, get),
      ...createTravelElementSlice(set, get),
      ...createAppSlice(set, get),
      ...createSocialSlice(set, get),
      ...createMapSlice(set, get),
      ...createBlogSlice(set, get),
      ...createUserSlice(set, get),
    }),
    {
      // localstorage prefix
      name: 'Skipd',
      // whitelist state to be stored in local storage
      partialize: (/*state*/) => ({}),
    }
  )
)

// enable devtools on testing environment https://github.com/beerose/simple-zustand-devtools
if (isBrowser) mountStoreDevtool('skipd', useStoreBase as any)

// typescript selector hooks: https://bit.ly/3fbBHfo
export const useStore = createSelectorHooks(useStoreBase)
