import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import TripPlanLogsEnum from '../constants/tripPlanLogs.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import client from '../gql/clients/apollo-client'
import { INSERT_NOTIFICATION } from '../gql/mutations/notification'
import {
  ACCCEPT_TRIP_PLAN_COLLABORATATION,
  INSERT_TRIP_PLAN_COLLABORATOR,
  INSERT_OR_EDIT_TRIP_PLAN,
  REMOVE_COLLABORATOR,
  INSERT_TRIP_PLAN_LOGS,
  COPY_TRIP_PLAN,
} from '../gql/mutations/tripPlan'
import { GET_PROFILE_BY_EMAIL } from '../gql/querys/profile'
import {
  GET_ALL_TRIP_PLANS,
  GET_MORE_COLLABORATION_TRIP_PLANS,
  GET_MORE_LIKED_TRIP_PLANS,
  GET_MORE_TRIP_PLANS,
  GET_MORE_TRIP_PLAN_LOGS,
  GET_TRIP_PLAN_BY_PK,
  GET_TRIP_PLAN_COLLABORATORS_BY_EMAIL,
  GET_TRIP_PLAN_COLLABORATORS_BY_USER_ID,
  GET_TRIP_PLAN_COLLABORATOR_BY_PK,
} from '../gql/querys/tripPlan'
import {
  ITravelElement,
  ITravelog,
  ITripCard,
  ITripPlan,
  ITripPlanCollaborator,
  ITripPlanLog,
} from '../types'
import { getMentions, getTags, isValidUuid } from '../utils'
import { emailSender } from '../utils/emailSending'
import { getProfilePhotoUrl } from '../utils/profile'
import { formatItinerary } from '../utils/tripPlan'
import { getImageUrl } from '../utils/useS3'
import travelElementService from './travelElementService'
import tripCardContentService from './tripCardContentService'
import { v4 as uuidv4 } from 'uuid'
import inviteToCollaborateTemplate from '../emailTemplates/inviteToCollaborate'

type getMoreTripPlansProps = {
  userId: string
  profileOffset?: number
  collaborationOffset?: number
  travatarId?: string
  searchInput?: string
}

const getTripPlanPhotoUrl = async (
  id: string,
  photoName: string
): Promise<string> => {
  if (photoName) return await getImageUrl(`${id}/${photoName}`)
}

export const getTripPlanCoverImage = async (
  tripPlan: ITripPlan
): Promise<string> => {
  const tripCards = tripPlan?.tripCards

  const travelContents = tripCards
    ?.map((tc: ITripCard) => tc?.tripCardContents)
    .flat()

  let photoUrl = null
  for (const travelContent of travelContents) {
    if (
      travelContent?.entityType?.name === EntityTypeEnum.IMAGE &&
      travelContent.path
    ) {
      photoUrl = await getTripPlanPhotoUrl(
        travelContent?.id,
        travelContent?.path
      )
      break
    }
  }
  return photoUrl
}

export const completeTripPlanInfo = async (tripPlan): Promise<ITripPlan> => {
  const tripCards = await Promise.all(
    tripPlan?.tripCards?.map(async (tc: ITripCard) => {
      return {
        ...tc,
        tripCardContents: await tripCardContentService.completeTripCardContents(
          tc?.tripCardContents
        ),
      }
    })
  )

  const hasTravelog = tripPlan?.travelogs?.length ? true : false
  let travatarInfo
  const profileInfo = {
    id: tripPlan?.user?.id,
    name: tripPlan?.user?.name,
    profilePhotoUrl: await getProfilePhotoUrl(
      tripPlan?.user?.profilePhoto,
      tripPlan?.user?.id
    ),
  }
  let coverPhoto
  if (tripPlan?.travatarId) {
    travatarInfo = {
      id: tripPlan?.travatarId,
      name: tripPlan?.travatar.name,
    }
  }
  if (tripPlan.tripCards.length) {
    coverPhoto = await getTripPlanCoverImage(tripPlan)
  }

  const tripPlanCollaborators = await Promise.all(
    tripPlan?.tripPlanCollaborators?.map(async (tpCollaborator) => {
      const url = await getProfilePhotoUrl(
        tpCollaborator?.user?.profilePhoto,
        tpCollaborator?.user?.id
      )

      return {
        ...tpCollaborator?.user,
        profilePhotoUrl: url,
        isAdvisor: tpCollaborator.isAdvisor,
      }
    })
  )

  const collaborating = tripPlan?.collaborating ? true : false

  const originalTripPlan = tripPlan?.originalTripPlan
    ? {
        ...tripPlan?.originalTripPlan,
        profileInfo: {
          ...tripPlan?.originalTripPlan?.user,
          profilePhotoUrl: await getProfilePhotoUrl(
            tripPlan?.originalTripPlan?.user?.profilePhoto,
            tripPlan?.originalTripPlan?.user?.id
          ),
        },
      }
    : null

  return {
    ...tripPlan,
    tripCards,
    coverPhoto,
    hasTravelog,
    profileInfo,
    travatarInfo,
    tripPlanCollaborators,
    collaborating,
    originalTripPlan,
  }
}

/*const getTripPlanStatus = (tripPlan): string => {
  if (
    tripPlan?.title &&
    // tripPlan?.description &&
    tripPlan?.travatarId &&
    tripPlan?.hasCards &&
    tripPlan?.statusId !== IdsEnum.STATUS_PUBLISHED
  ) {
    return IdsEnum.STATUS_COMPLETE
  } else if (tripPlan?.statusId !== IdsEnum.STATUS_PUBLISHED) {
    return IdsEnum.STATUS_DRAFT
  } else {
    return IdsEnum.STATUS_PUBLISHED
  }
}*/

type getOrInsertTripPlanCollaboratorProps = {
  tripPlanId: string
  userId?: string
  email?: string
  isAdvisor?: boolean
}

const getOrInsertTripPlanCollaborator = async ({
  userId,
  tripPlanId,
  email,
  isAdvisor = false,
}: getOrInsertTripPlanCollaboratorProps): Promise<ITripPlanCollaborator> => {
  let result

  if (email && !userId) {
    result = await client.query({
      query: GET_TRIP_PLAN_COLLABORATORS_BY_EMAIL,
      variables: { tripPlanId, emailInvited: email },
      fetchPolicy: 'no-cache',
    })
  } else if (!email && userId) {
    result = await client.query({
      query: GET_TRIP_PLAN_COLLABORATORS_BY_USER_ID,
      variables: { tripPlanId, userId },
      fetchPolicy: 'no-cache',
    })
  }

  const tripPlanCollaboration = result?.data?.trip_plan_collaborators[0]

  if (tripPlanCollaboration?.id) {
    return tripPlanCollaboration
  } else {
    const resultInsert = await client.mutate({
      mutation: INSERT_TRIP_PLAN_COLLABORATOR,
      variables: {
        tripPlanId,
        userId,
        email,
        isAdvisor,
      },
    })
    return resultInsert?.data?.insert_trip_plan_collaborators_one
  }
}

const inviteUserToCollaborate = async (
  profileId: string,
  tripPlanId: string,
  userName: string,
  path: string
): Promise<void> => {
  const resultInvitation = await getOrInsertTripPlanCollaborator({
    tripPlanId,
    userId: profileId,
  })

  const link = `${process.env.NEXT_PUBLIC_ROOT}/accept-invitation/${resultInvitation.id}`

  const body = {
    title: 'Invite to collaborate',
    description: `${userName} has invited you to collaborate on his journey`,
    link,
    path,
  }

  await client.mutate({
    mutation: INSERT_NOTIFICATION,
    variables: { toId: profileId, body },
  })
}
const inviteAdvisorToCollaborate = async (
  profileId: string,
  userName: string,
  path: string,
  link: string
): Promise<void> => {
  const body = {
    title: 'Invite to advise',
    description: `${userName} asked you to be his trip advisor`,
    link,
    path,
  }

  await client.mutate({
    mutation: INSERT_NOTIFICATION,
    variables: { toId: profileId, body },
  })
}

const inviteUserToCollaborateViaEmail = async (
  tripPlanId,
  email,
  userName,
  userImageUrl
): Promise<void> => {
  const invitationResult = await getOrInsertTripPlanCollaborator({
    tripPlanId,
    email,
  })
  const invitationUrl = `${process.env.NEXT_PUBLIC_SKIPD_ENVIROMENT}${process.env.NEXT_PUBLIC_ROOT}/accept-invitation/${invitationResult?.id}`
  const message = {
    from_email: 'info@skipd.com',
    subject: 'Invitation to collaborate on a Trip Plan',
    html: inviteToCollaborateTemplate(invitationUrl, userImageUrl, userName),
    to: [
      {
        email: email,
        type: 'to',
      },
    ],
  }
  await emailSender(message)
}
const inviteAdvisorViaEmail = async (email, invitationId): Promise<void> => {
  const message = {
    from_email: 'info@skipd.com',
    subject: 'Invitation to advise on a Trip Plan',
    html: `<body style="margin: 0;"> <a href='${process.env.NEXT_PUBLIC_SKIPD_ENVIROMENT}${process.env.NEXT_PUBLIC_ROOT}/accept-invitation/${invitationId}'> Click here to go to trip plan invitation </a> </body>`,
    to: [
      {
        email: email,
        type: 'to',
      },
    ],
  }
  await emailSender(message)
}

export const findInvidtation = async (invitationId: string) => {
  const result = await client.query({
    query: GET_TRIP_PLAN_COLLABORATOR_BY_PK,
    variables: { id: invitationId },
  })
  if (result.data.trip_plan_collaborators_by_pk) {
    return result.data.trip_plan_collaborators_by_pk
  } else {
    throw new Error('Invalid invitation')
  }
}

const completeTripPlanLog = async (
  log: ITripPlanLog
): Promise<ITripPlanLog> => {
  const user = log?.profileInfo
  const profileInfo = {
    id: user?.id,
    name: user?.name,
    profilePhotoUrl: await getProfilePhotoUrl(user?.profilePhoto, user?.id),
  }
  return {
    ...log,
    profileInfo,
  }
}

export default {
  completeTripPlansInfo: async (
    tripPlans: ITripPlan[]
  ): Promise<ITripPlan[]> => {
    return Promise.all(
      tripPlans?.map(async (tripPlan) => completeTripPlanInfo(tripPlan))
    )
  },
  insertOrEditTripPlan: async (tripPlan: ITripPlan) => {
    const {
      id,
      title,
      description,
      travatarId,
      metadata,
      userId,
      collaborating,
    } = tripPlan

    //ALONZO: I commented the function getTripPlanStatus because we will eliminate the publish status from the trip plan
    const status = IdsEnum.STATUS_PUBLISHED //getTripPlanStatus(tripPlan)

    if (!isValidUuid(id)) {
      throw new InvalidUuidError('Invalid Trip Plan id')
    }

    const metadataWithhTagsAndMentions = {
      ...metadata,
      tags: getTags(description || ''),
      mentions: getMentions(description || ''),
    }

    const result = await client.mutate({
      mutation: INSERT_OR_EDIT_TRIP_PLAN,
      variables: {
        id,
        title,
        statusId: status,
        travatarId,
        userId,
        description,
        metadata: metadataWithhTagsAndMentions,
      },
    })

    const tripPlanComplete = completeTripPlanInfo({
      ...result?.data.insert_trip_plan_one,
      collaborating,
    })

    return tripPlanComplete
  },
  getTripPlan: async (tripPlanId: string) => {
    if (!isValidUuid(tripPlanId)) {
      throw new InvalidUuidError('Invalid Trip Plan id')
    }

    const result = await client.query({
      query: GET_TRIP_PLAN_BY_PK,
      fetchPolicy: 'no-cache',
      variables: {
        tripPlanId,
      },
    })

    const tripPlan = await completeTripPlanInfo(result.data.trip_plan_by_pk)

    return tripPlan
  },
  getMoreTripPlans: async ({
    userId,
    profileOffset,
    collaborationOffset,
    travatarId,
    searchInput,
  }: getMoreTripPlansProps) => {
    if (!isValidUuid(userId)) {
      throw new InvalidUuidError('Invalid User id')
    }

    let tripPlans: ITripPlan[] = []

    if (collaborationOffset % 10 == 0 && !travatarId && !searchInput) {
      const result = await client.query({
        query: GET_MORE_COLLABORATION_TRIP_PLANS,
        variables: {
          userId,
          offset: collaborationOffset,
        },
      })

      const tripPlansComplete =
        (await Promise.all(
          result?.data?.user_by_pk.tripPlanCollaborations?.map(
            async (tpc) =>
              await completeTripPlanInfo({
                ...tpc.tripPlan,
                collaborating: true,
              })
          )
        )) || []
      tripPlans = tripPlansComplete
    }

    if (tripPlans?.length < 10) {
      const limit = 10 - tripPlans.length

      const titleComparisonExp = searchInput
        ? {
            _ilike: `%${searchInput}%`,
          }
        : {}
      const travatarIdComparisonExp = travatarId ? { _eq: travatarId } : {}

      const result = await client.query({
        query: GET_MORE_TRIP_PLANS,
        variables: {
          userId,
          limit,
          offset: profileOffset,
          titleComparisonExp,
          travatarIdComparisonExp,
        },
      })

      const tripPlansComplete = await Promise.all(
        result?.data?.trip_plan?.map(
          async (tp) => await completeTripPlanInfo(tp)
        )
      )

      tripPlans = tripPlans.concat(tripPlansComplete)
    }
    return tripPlans
  },
  getMoreLikedTripPlans: async (userId, offset) => {
    const result = await client.query({
      query: GET_MORE_LIKED_TRIP_PLANS,
      variables: { userId, offset },
    })
    const travelElements = (await Promise.all(
      result.data.user_by_pk.likedTravelElements.map(
        async (te) => await completeTripPlanInfo(te)
      )
    )) as ITripPlan[]

    return travelElements
  },
  getOrAssignNewTripPlan: async (tripPlanId: string, userId: string) => {
    if (!isValidUuid(tripPlanId)) {
      throw new InvalidUuidError('Invalid Trip Plan id')
    }

    let tripPlan: ITripPlan
    const result = await client.query({
      query: GET_TRIP_PLAN_BY_PK,
      variables: {
        tripPlanId,
      },
    })
    tripPlan = result.data.trip_plan_by_pk

    const collaborating = tripPlan?.userId === userId ? false : true

    if (tripPlan) {
      tripPlan = await completeTripPlanInfo({ ...tripPlan, collaborating })
    } else {
      tripPlan = {
        id: tripPlanId,
        statusId: IdsEnum.STATUS_DRAFT,
        tripCards: [
          {
            id: uuidv4(),
            tripPlanId: tripPlanId,
            metadata: {
              itinerary: { day: 1, order: 1 },
              description: '',
              isInvisible: true,
            },
            tripCardContents: [],
            travelElementId: null,
            location: null,
          },
        ],
      }
    }

    return tripPlan
  },
  inviteToCollaborate: async (profileId, tripPlanId, userName, path) => {
    inviteUserToCollaborate(profileId, tripPlanId, userName, path)
  },
  inviteToCollaborateViaEmail: async (
    email,
    tripPlanId,
    userName,
    path,
    userImageUrl
  ) => {
    const resultProfile = await client.query({
      query: GET_PROFILE_BY_EMAIL,
      variables: { email },
    })
    const profile = resultProfile.data.user[0]

    if (profile?.id) {
      await inviteUserToCollaborate(profile.id, tripPlanId, userName, path)
    } else {
      await inviteUserToCollaborateViaEmail(
        tripPlanId,
        email,
        userName,
        userImageUrl
      )
    }
  },
  inviteAdvisor: async (tripPlanId, userName, path) => {
    const advisorId = process.env.NEXT_PUBLIC_ADVISOR_ID
    const advisorEmail = process.env.NEXT_PUBLIC_ADVISOR_EMAIL

    const resultInvitation = await getOrInsertTripPlanCollaborator({
      tripPlanId,
      userId: advisorId,
      isAdvisor: true,
    })

    const link = `${process.env.NEXT_PUBLIC_ROOT}/accept-invitation/${resultInvitation.id}`

    await Promise.all([
      inviteAdvisorToCollaborate(advisorId, userName, path, link),
      inviteAdvisorViaEmail(tripPlanId, advisorEmail),
    ])
  },
  acceptTripPlanCollaboration: async (
    invitationId: string,
    userId
  ): Promise<ITripPlan> => {
    if (!isValidUuid(invitationId) || !isValidUuid(userId)) {
      throw new InvalidUuidError('Invalid Invitation or User id')
    }

    const result = await client.mutate({
      mutation: ACCCEPT_TRIP_PLAN_COLLABORATATION,
      variables: {
        id: invitationId,
        userId,
      },
    })

    const resultTripPlan =
      result.data.update_trip_plan_collaborators.returning[0]?.tripPlan

    if (resultTripPlan) {
      const rawTripPlan = {
        ...result.data.update_trip_plan_collaborators.returning[0]?.tripPlan,
        collaborating: true,
      }

      const tripPlan = await completeTripPlanInfo(rawTripPlan)
      return tripPlan
    } else {
      throw new Error('Trip Plan Collaboration not found')
    }
  },
  removeCollaborator: async (tripPlanId, profileId) => {
    await client.mutate({
      mutation: REMOVE_COLLABORATOR,
      variables: { tripPlanId, userId: profileId },
    })
  },
  insertTripPlanLogs: async (
    newTripPlanData: ITripPlan,
    tripPlanInStore: ITripPlan,
    logsInStore: ITripPlanLog[],
    userId: string
  ) => {
    if (logsInStore?.length) {
      const logs = logsInStore
      const logIds = {
        user_id: userId,
        trip_plan_id: tripPlanInStore?.id,
      }

      if (!tripPlanInStore?.userId) {
        logs.push({
          ...logIds,
          log: TripPlanLogsEnum.CREATED,
        })
      }

      if (tripPlanInStore?.title !== newTripPlanData.title) {
        logs.push({
          ...logIds,
          log: TripPlanLogsEnum.TITLED,
        })
      }

      await client.mutate({
        mutation: INSERT_TRIP_PLAN_LOGS,
        variables: { tripPlanLogs: logs },
      })
    }
  },
  filterAndCompleteTripPlanLogs: async (
    tripPlanLogs: ITripPlanLog[],
    tripPlanLogsInStore?: ITripPlanLog[]
  ) => {
    if (tripPlanLogsInStore?.length) {
      const logsNotInStore = await Promise.all(
        tripPlanLogs
          .filter((log) => !tripPlanLogsInStore.find((l) => l.id === log.id))
          .map(async (log) => await completeTripPlanLog(log))
      )

      return [...logsNotInStore, ...tripPlanLogsInStore]
    } else {
      const tripPlanLog: ITripPlanLog[] = await Promise.all(
        tripPlanLogs.map(async (log) => await completeTripPlanLog(log))
      )

      return tripPlanLog
    }
  },
  getMoreTripPlanLogs: async (tripPlanId, offset) => {
    const result = await client.query({
      query: GET_MORE_TRIP_PLAN_LOGS,
      variables: { tripPlanId, offset },
    })
    const tripPlanLogs = await Promise.all(
      result.data.trip_plan_log.map(async (log) => {
        const user = log?.profileInfo
        const profileInfo = {
          id: user?.id,
          name: user?.name,
          profilePhotoUrl: await getProfilePhotoUrl(
            user?.profilePhoto,
            user?.id
          ),
        }
        return {
          ...log,
          profileInfo,
        }
      })
    )
    return tripPlanLogs
  },
  getTripPlanTravelElements: async (
    tripPlan: ITripPlan
  ): Promise<ITravelElement[]> => {
    const travelElementsOnTripPlan = tripPlan?.tripCards?.filter(
      (tc) => tc?.travelElementId
    )

    const orderItineraryCards = formatItinerary(travelElementsOnTripPlan)

    const tripPlanTravelElements = []
    Object.keys(orderItineraryCards)?.map((day) => {
      orderItineraryCards[day]?.map((te) => {
        tripPlanTravelElements.push(te.travelElement)
      })
    })

    const completeTravelElements =
      await travelElementService.completeTravelElementsInfo(
        tripPlanTravelElements
      )

    return completeTravelElements
  },
  copyTripPlan: async (
    tripPlanToCopy: ITripPlan | ITravelog,
    userId: string,
    profileTripPlans: ITripPlan[]
  ) => {
    const tripPlanId = uuidv4()
    const tripPlan = {
      id: tripPlanId,
      title: `${tripPlanToCopy.title} Copy`,
      user_id: userId,
    }

    const tripCards = tripPlanToCopy.tripCards.map((tc: ITripCard) => {
      return {
        metadata: tc.metadata,
        travel_element_id: tc.travelElementId || null,
        trip_plan_id: tripPlanId,
      }
    })

    const result = await client.mutate({
      mutation: COPY_TRIP_PLAN,
      variables: { tripPlan, tripCards },
    })
    const [lastTripPlan] = profileTripPlans.slice(-1)
    if (!lastTripPlan?.collaborating || profileTripPlans?.length < 10) {
      const tripPlanResult = {
        ...result.data.insert_trip_plan_one,
        tripCards: result.data.insert_trip_card.returning,
      }
      const tripPlanComplete = await completeTripPlanInfo(tripPlanResult)
      const profileTripPlansReturn = [...profileTripPlans, tripPlanComplete]
      return { profileTripPlansReturn, tripPlanId }
    }
    return { profileTripPlans, tripPlanId }
  },
  getAllTripPlans: async (userId: string) => {
    const result = await client.query({
      query: GET_ALL_TRIP_PLANS,
      variables: { userId },
    })
    const tripPlans = await Promise.all(
      result.data.tripPlans.map(async (tp) => {
        return await completeTripPlanInfo(tp)
      })
    )
    return tripPlans
  },
}
