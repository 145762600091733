import { gql } from '@apollo/client'
import {
  TRAVATAR_FRAGMENT,
  TRAVATAR_INFO_FRAGMENT,
} from '../fragments/travatar'
import { TRAVEL_ELEMENT_FRAGMENT } from '../fragments/travelElement'
import { TRAVELOG_FRAGMENT } from '../fragments/travelog'
import { TRIP_PLAN_FRAGMENT } from '../fragments/tripPlan'

const GET_TRAVATAR_BY_ID = gql`
  query getTravatarById($id: uuid!) {
    travatar_by_pk(id: $id) {
      ...TravatarInfo
    }
  }
  ${TRAVATAR_INFO_FRAGMENT}
`

const GET_COMPLETE_TRAVATAR_BY_ID = gql`
  query getCompleteTravatarById($id: uuid!) {
    travatar_by_pk(id: $id) {
      ...Travatar
    }
    travelog(
      where: { travatar_id: { _eq: $id }, deleted_at: { _is_null: true } }
      limit: 10
      order_by: { created_at: desc }
    ) {
      ...Travelog
    }
  }
  ${TRAVATAR_FRAGMENT}
  ${TRAVELOG_FRAGMENT}
`

const SEARCH_BY_TRAVATAR_ID = gql`
  query searchByTravatarId($travatarId: uuid!, $profileId: uuid!) {
    travelog(
      where: {
        travatar_id: { _eq: $travatarId }
        user_id: { _eq: $profileId }
        deleted_at: { _is_null: true }
      }
      limit: 10
      order_by: { created_at: desc }
    ) {
      ...Travelog
    }
    travelElement: travel_element(
      where: {
        travatar_id: { _eq: $travatarId }
        user_id: { _eq: $profileId }
        deleted_at: { _is_null: true }
      }
      limit: 10
      order_by: { created_at: desc }
    ) {
      ...TravelElement
    }
    tripPlan: trip_plan(
      where: {
        travatar_id: { _eq: $travatarId }
        user_id: { _eq: $profileId }
        deleted_at: { _is_null: true }
      }
      limit: 10
      order_by: { created_at: desc }
    ) {
      ...TripPlan
    }
  }
  ${TRAVELOG_FRAGMENT}
  ${TRAVEL_ELEMENT_FRAGMENT}
  ${TRIP_PLAN_FRAGMENT}
`

const GET_MORE_FOLLOWING_TRAVATARS = gql`
  query GetMoreLikedTravatars($userId: uuid!, $offset: Int!) {
    user_by_pk(id: $userId) {
      following_travatars(
        limit: 10
        offset: $offset
        order_by: { created_at: desc }
      ) {
        ...Travatar
      }
    }
  }
  ${TRAVATAR_FRAGMENT}
`

const GET_ALL_TRAVATARS = gql`
  query GetAllTravatars($userId: uuid!) {
    travatar(where: { user_id: { _eq: $userId } }) {
      ...TravatarInfo
    }
  }
  ${TRAVATAR_INFO_FRAGMENT}
`

export {
  GET_TRAVATAR_BY_ID,
  SEARCH_BY_TRAVATAR_ID,
  GET_COMPLETE_TRAVATAR_BY_ID,
  GET_MORE_FOLLOWING_TRAVATARS,
  GET_ALL_TRAVATARS,
}
