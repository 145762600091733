import { gql } from '@apollo/client'

const COMMENT_FRAGMENT = gql`
  fragment Comments on comment {
    id
    comment
    likesAmount: computed_likes
    createdAt: created_at
    iLiked: liked_by_user
    path
    user {
      name
      profilePhoto: profile_photo
      id
    }
    entityType: entity_type {
      name
      id
    }
  }
`

export { COMMENT_FRAGMENT }
