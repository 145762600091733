import { useSubscription } from '@apollo/client'
import { Badge, Box, Tooltip } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { IoMdNotifications } from 'react-icons/io'
import { useUser } from '../../../context/Auth'
import { NOTIFICATION_SUBSCRIPTION } from '../../../gql/subscriptions/notification'
import { useStore } from '../../../store'
import NotificationsListModal from './NotificationModal'

type NotificationProps = {
  size?: number
  iconColor?: string
  style?: any
}

const Notifications = ({
  size = 22,
  iconColor = 'white',
  style = {},
}: NotificationProps): ReactElement => {
  const [showModal, setShowModal] = useState(false)
  const {
    setAlertMessage,
    setNewNotifications,
    getUnreadNotificationsAmount,
    unreadNotificationsAmount,
  } = useStore()
  const { claims } = useUser()

  const { data, error } = useSubscription(NOTIFICATION_SUBSCRIPTION, {
    variables: { id: claims?.id },
  })

  useEffect(() => {
    const notifications = data?.notification
    if (notifications?.length) {
      setNewNotifications(notifications)
    }
  }, [data])

  useEffect(() => {
    if (
      claims?.id &&
      !unreadNotificationsAmount &&
      unreadNotificationsAmount !== 0
    ) {
      getUnreadNotificationsAmount(claims?.id)
    }
  }, [getUnreadNotificationsAmount, claims, unreadNotificationsAmount])

  useEffect(() => {
    if (error?.message === 'Observable cancelled prematurely') {
      setAlertMessage(
        'Notifications subscription stopped. You must reload for it to work',
        'info'
      )
    } else if (error) {
      console.error(error)
      setAlertMessage(error.message, 'error')
    }
  }, [error])

  return (
    <Box sx={style}>
      <Badge color="primary" badgeContent={unreadNotificationsAmount}>
        <Tooltip title="Notifications">
          <div>
            <IoMdNotifications
              size={size}
              color={iconColor}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowModal(true)}
            />
          </div>
        </Tooltip>
      </Badge>
      <NotificationsListModal
        open={showModal}
        handleClose={() => setShowModal(false)}
      />
    </Box>
  )
}

export default Notifications
