import { connectSearchBox } from 'react-instantsearch-dom'
import TextField from '@mui/material/TextField'
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { useRef, useState } from 'react'
import { useSearchPlace } from '../../../context/SearchPlace'
import styled from '@emotion/styled'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import { Popper } from '@mui/material'
import { useSearch } from '../../../context/Search'
const StyledSearchIcon = styled(SearchIcon)`
  display: flex;
  color: #a79b9b;
`

const StyledPopper = styled(Popper)(() => ({
  '& .MuiAutocomplete-groupLabel': {
    backgroundColor: ' #0d0c19',
    color: '#fff',
  },
}))
const StyledAutocomplete = styled(Autocomplete)`
  position: relative;
  border-radius: 4px;
  background-color: #2b2841;
  margin-left: 24px;
  margin-right: 24px;

  & ::placeholder {
    font-size: 14px;
  }

  & #multiple-limit-tags-label {
    color: #a79b9b;
    transform: translate(15px, 11px) scale(1);
  }
  & #multiple-limit-tags-label[data-shrink='true'] {
    transform: translate(14px, -9px) scale(0.75);
  }
  & #multiple-limit-tags {
    color: #a79b9b;
    padding: 9px 4px 9px 6px;
    border: none;
  }
  & .MuiOutlinedInput-root {
    padding: 3px;
    padding-right: 10px !important;
    border-radius: 4px;
  }

  & .MuiAutocomplete-groupLabel {
    color: #a79b9b !important;
    background-color: #2b2841 !important;
  }

  & .MuiAutocomplete-tag {
    color: #a79b9b;
  }
`
interface IProps {
  refine: (...args: any[]) => any
  hits: string[]
}

function SearchBox({ refine, hits }: IProps) {
  const [added, setAdded] = useState(false)

  mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_KEY
  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    types: 'country,region,place,postcode,locality,neighborhood',
    map: null,
  })

  const mapControl = useRef(geocoder)

  const searchPlace = useSearchPlace()

  const { setSearchPlaces, setSearchPlace, setSearchResultsMap } = searchPlace

  const searchcontext = useSearch()

  const { setHitsPlaces } = searchcontext

  const handleOnChange = (e) => {
    if (!added && mapControl?.current) {
      mapControl?.current?.addTo('#geocoder')
      setAdded(true)
    }
    if (e?.target?.value) {
      mapControl.current.query(e.target.value)
      refine(e?.currentTarget?.value)
    } else if (e?.target?.value == '') {
      setSearchPlaces([])
      setSearchPlace([])
      setHitsPlaces([])
      setSearchResultsMap([])
    }
  }

  mapControl.current.on('results', (e) => {
    setSearchResultsMap(e.features)
  })

  return (
    <>
      <StyledAutocomplete
        disablePortal
        popupIcon={<StyledSearchIcon />}
        options={hits}
        filterOptions={(x) => x}
        id="geocoder"
        PopperComponent={StyledPopper}
        groupBy={(element: any) => element?.group}
        onChange={(e, target: any) => {
          if (target?.showAll) {
            if (target?.isTravelElements) {
              setHitsPlaces(target) //Travel Element multiple places
            } else {
              setSearchPlaces(target) //Mapbox multiple places
            }
          } else if (target?.place?.bbox) {
            setSearchPlace([
              {
                bbox: target?.place?.bbox,
                countryName: target?.place?.properties?.short_code,
              },
            ]) //One place selected mapbox
          } else {
            setHitsPlaces(target) //One place selected Element
          }
        }}
        renderInput={(params) => (
          <TextField
            onChange={(e) => handleOnChange(e)}
            {...params}
            placeholder="Search for a place, interest or activity"
            sx={{ m: 0 }}
          />
        )}
      />
    </>
  )
}

export default connectSearchBox(SearchBox)
