import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from './comment'
import { TRIP_CARD_CONTENT_FRAGMENT } from './tripCardContent'

const TRAVELOG_INFO_FRAGMENT = gql`
  fragment TravelogInfo on travelog {
    id
    title
    description
    likesAmount: computed_likes
    iLiked: liked_by_user
    statusId: status_id
    userId: user_id
    coverPhoto: cover_photo
    user {
      name
      id
      profilePhoto: profile_photo
    }
    entityType: entity_type {
      name
    }
  }
`

const TRAVELOG_FRAGMENT = gql`
  fragment Travelog on travelog {
    ...TravelogInfo
    comments {
      ...Comments
    }
    tripCards: trip_cards {
      id
      metadata
      tripPlanId: trip_plan_id
      location
      travelElement: travel_element {
        id
        location
        name
        coverPhoto: cover_photo
        travatar {
          id
          name
          profilePhoto: profile_photo
        }
      }
      travelElementId: travel_element_id
      tripCardContents: trip_card_contents(
        where: { is_private: { _eq: false } }
      ) {
        ...TripCardContent
      }
    }
  }
  ${TRAVELOG_INFO_FRAGMENT}
  ${COMMENT_FRAGMENT}
  ${TRIP_CARD_CONTENT_FRAGMENT}
`

export { TRAVELOG_FRAGMENT, TRAVELOG_INFO_FRAGMENT }
