import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import client from '../gql/clients/apollo-client'
import {
  INSERT_TRAVEL_ELEMENT_CONTENT_DESCRIPTION,
  REMOVE_TRAVEL_ELEMENT_CONTENT,
  UPDATE_TRAVEL_ELEMENT_CONTENT_POSITION,
} from '../gql/mutations/travelElementContent'
import { GET_TRAVEL_ELEMENTS_BY_ID } from '../gql/querys/travelElement'
import { ITravelElement, ITravelElementContent } from '../types'
import { getProfilePhotoUrl } from '../utils/profile'
import removeDashes from '../utils/removeDashes'
import { getTripCardContentUrl } from '../utils/travelContent'
import commentService from './commentService'

export default {
  getLikedTravelElementContents: async (travelElementIds: string[]) => {
    const result = await client.query({
      query: GET_TRAVEL_ELEMENTS_BY_ID,
      variables: {
        travelElementIds,
      },
    })
    let travelContents = result?.data?.travel_element
    travelContents = Promise.all(
      travelContents.map(async (te) => {
        const travelContentUrl = await getTripCardContentUrl(te.id, te.path)
        const profileName = te.travelog?.travatar?.user?.name
        const profileId = te.travelog?.travatar?.user?.id
        const profileCover = te.travelog?.travatar?.user?.profile_photo
        const profileCoverUrl = await getProfilePhotoUrl(
          profileCover,
          profileId
        )

        return {
          ...te,
          travelContentUrl,
          profileInfo: {
            name: profileName,
            photoUrl: profileCoverUrl,
          },
        }
      })
    )

    return travelContents
  },
  /*insertTripCardContentsToTripCard: async (
    filesWithLocation: IFileWithLocation[],
    tripCardId: string
  ): Promise<ITripCardContent[]> => {
    const travelContentInsertInput = filesWithLocation.map(
      (fileWithLocalization) => {
        const file = fileWithLocalization.file
        const localization = fileWithLocalization.localization
        return {
          trip_card_id: tripCardId,
          location: localization
            ? `(${localization?.latitude}, ${localization?.longitude})`
            : null,
          path: file.name,
          entity_type_id: file.type.includes('image')
            ? IdsEnum.ENTITY_TYPE_IMAGE
            : IdsEnum.ENTITY_TYPE_VIDEO,
        }
      }
    )
    const result = await client.mutate({
      mutation: INSERT_TRAVEL_CONTENTS,
      variables: { travelContents: travelContentInsertInput },
    })
    const travelContents = result.data.insert_travel_content.returning

    const travelContentsWithUrl = await Promise.all(
      travelContents.map(async (tc, index) => {
        const file = filesWithLocation[index].file
        const key = await uploadFile({
          rawKey: `${tc.id}/${file.name}`,
          contentType: file.type,
          file: file,
        })
        const url = await getImageUrl(key)
        return {
          ...tc,
          travelContentUrl: url,
        }
      })
    )

    return travelContentsWithUrl
  },
  editTripCardContent: async (
    travelContent: ITripCardContent
  ): Promise<ITripCardContent> => {
    travelContent.metadata = {
      ...travelContent.metadata,
      tags: getTags(travelContent.metadata.description),
      mentions: getMentions(travelContent.metadata.description),
    }
    const result = await client.mutate({
      mutation: UPDATE_TRAVEL_CONTENT,
      variables: {
        id: travelContent.id,
        metadata: travelContent.metadata,
        location: travelContent.location,
        isPrivate: travelContent.isPrivate,
      },
    })
    const travelContentUpdated = result.data.update_travel_content_by_pk
    return travelContentUpdated
  },

  insertUrlToTripCardContents: async (tripCardContents: ITripCardContent[]) => {
    return await Promise.all(
      tripCardContents.map(async (tc) => {
        const tripCardContentUrl = await getTripCardContentUrl(tc.id, tc.path)
        return {
          ...tc,
          tripCardContentUrl,
        }
      })
    )
  },*/
  removeTravelElementContents: async (travelElementContentId: string) => {
    try {
      const result = await client.mutate({
        mutation: REMOVE_TRAVEL_ELEMENT_CONTENT,
        variables: { travelElementContentId },
      })

      const deletedTravelElementContent =
        result?.data?.delete_travel_element_content_by_pk

      if (deletedTravelElementContent) {
        return deletedTravelElementContent
      } else {
        throw new Error(
          'There was an error deleting the Travel Element content'
        )
      }
    } catch (err) {
      console.log(err)
      throw new Error('Trip Content could not be deleted')
    }
  },
  changeTravelElementContentOrder: async (travelElementContents) => {
    try {
      const result = await client.mutate({
        mutation: UPDATE_TRAVEL_ELEMENT_CONTENT_POSITION,
        variables: { travelElementContents },
      })

      const newTravelElementContent =
        result.data.insert_travel_element_content.returning

      return newTravelElementContent
    } catch (error) {
      console.log(error)
      throw new Error('Cannot Change Content Position')
    }
  },
  addDescription: async (
    description: string,
    travelElementContentId: string,
    travelElementFromStore: ITravelElement,
    travelElementsFromStore: ITravelElement[],
    profileTravelElementsFromStore: ITravelElement[]
  ) => {
    const result = await client.mutate({
      mutation: INSERT_TRAVEL_ELEMENT_CONTENT_DESCRIPTION,
      variables: {
        travelElementContentId,
        description,
      },
    })
    const travelElementNoUrl = result.data.update_travel_element_content_by_pk

    let travelElementContentUrl

    if (travelElementNoUrl?.entityType?.id !== IdsEnum.ENTITY_TYPE_YOUTUBE) {
      travelElementContentUrl = await getTripCardContentUrl(
        travelElementNoUrl.id,
        travelElementNoUrl.path
      )
    } else {
      travelElementContentUrl = travelElementNoUrl?.travelElementContentUrl
    }

    const travelElementContent: ITravelElementContent = {
      ...travelElementNoUrl,
      travelElementContentUrl,
    }

    const travelElement = {
      ...travelElementFromStore,
      travelElementContents: travelElementFromStore.travelElementContents.map(
        (te) => {
          if (te.id === travelElementContent.id) {
            return travelElementContent
          }
          return te
        }
      ),
    }

    const travelElementsIndex = travelElementsFromStore.findIndex(
      (te) => te.id === travelElementContent.travelElementId
    )

    if (travelElementsIndex !== -1) {
      travelElementsFromStore.splice(travelElementsIndex, 1, travelElement)
    }

    const travelElements = travelElementsFromStore

    const profileTravelElementsIndex = profileTravelElementsFromStore.findIndex(
      (te) => te.id === travelElementContent.travelElementId
    )

    if (profileTravelElementsIndex !== -1) {
      profileTravelElementsFromStore.splice(
        profileTravelElementsIndex,
        1,
        travelElement
      )
    }

    const profileTravelElements = profileTravelElementsFromStore

    return { travelElement, travelElements, profileTravelElements }
  },
  getMoreComments: async (oldTravelElement: ITravelElement, contentId) => {
    const oldTravelElementContent = oldTravelElement.travelElementContents.find(
      (tec) => tec.id === contentId
    )
    const path = `${EntityTypeEnum.TRAVEL_ELEMENT_CONTENT}.${removeDashes(
      oldTravelElement.id
    )}`
    const offset = oldTravelElementContent?.comments?.length
    const comments = await commentService.getMoreComments(path, offset)

    const travelElementContent = {
      ...oldTravelElementContent,
      comments: [...oldTravelElementContent.comments, ...comments],
    }
    const travelElement: ITravelElement = {
      ...oldTravelElement,
      travelElementContents: oldTravelElement.travelElementContents.map(
        (tec) => {
          if (tec.id === oldTravelElementContent.id) {
            return travelElementContent
          }
          return tec
        }
      ),
    }
    return travelElement
  },
}
