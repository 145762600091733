import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from '../fragments/comment'

const GET_MORE_COMMENTS = gql`
  query GetMoreComments($path: ltree!, $offset: Int) {
    comment(
      where: { path: { _eq: $path } }
      offset: $offset
      order_by: { created_at: desc }
    ) {
      ...Comments
    }
  }
  ${COMMENT_FRAGMENT}
`
export { GET_MORE_COMMENTS }
