import { gql } from '@apollo/client'
import { TRIP_CARD_FRAGMENT } from '../fragments/tripCard'
import {
  TRIP_PLAN_COLLABORATOR_FRAGMENT,
  TRIP_PLAN_FRAGMENT,
  TRIP_PLAN_LOG_FRAGMENT,
} from '../fragments/tripPlan'

const INSERT_OR_EDIT_TRIP_PLAN = gql`
  mutation NewTripPlan(
    $travatarId: uuid
    $title: String
    $metadata: jsonb
    $statusId: uuid
    $description: String
    $id: uuid
    $userId: uuid
  ) {
    insert_trip_plan_one(
      object: {
        id: $id
        title: $title
        status_id: $statusId
        description: $description
        metadata: $metadata
        travatar_id: $travatarId
        user_id: $userId
      }
      on_conflict: {
        constraint: trip_plan_pkey
        update_columns: [
          description
          metadata
          travatar_id
          title
          user_id
          status_id
        ]
      }
    ) {
      ...TripPlan
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`
const UPDATE_TRIP_PLAN = gql`
  mutation UpdateTripPlan(
    $id: uuid!
    $title: String
    $travatarId: uuid
    $metadata: jsonb
    $description: String
  ) {
    update_trip_plan_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        description: $description
        metadata: $metadata
        travatar_id: $travatarId
      }
    ) {
      ...TripPlan
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`

const INSERT_TRIP_PLAN_COLLABORATOR = gql`
  mutation InsertTripPlanCollaborator(
    $tripPlanId: uuid!
    $userId: uuid
    $email: String
    $isAdvisor: Boolean
  ) {
    insert_trip_plan_collaborators_one(
      object: {
        trip_plan_id: $tripPlanId
        user_id: $userId
        email_invited: $email
        is_advisor: $isAdvisor
      }
    ) {
      ...TripPlanCollaborator
    }
  }
  ${TRIP_PLAN_COLLABORATOR_FRAGMENT}
`

const ACCCEPT_TRIP_PLAN_COLLABORATATION = gql`
  mutation AcceptTripPlanCollaboration($id: uuid!, $userId: uuid!) {
    update_trip_plan_collaborators(
      where: { id: { _eq: $id } }
      _set: { invitation_accepted: true, user_id: $userId }
    ) {
      returning {
        tripPlan: trip_plan {
          ...TripPlan
        }
      }
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`

const REMOVE_COLLABORATOR = gql`
  mutation RemoveCollaborator($tripPlanId: uuid!, $userId: uuid!) {
    delete_trip_plan_collaborators(
      where: { trip_plan_id: { _eq: $tripPlanId }, user_id: { _eq: $userId } }
    ) {
      affected_rows
    }
  }
`

const INSERT_TRIP_PLAN_LOGS = gql`
  mutation InsertTripPlanLogs($tripPlanLogs: [trip_plan_log_insert_input!]!) {
    insert_trip_plan_log(objects: $tripPlanLogs) {
      ...TripPlanLog
    }
  }
  ${TRIP_PLAN_LOG_FRAGMENT}
`

const COPY_TRIP_PLAN = gql`
  mutation CopyTripPlan(
    $tripPlan: trip_plan_insert_input!
    $tripCards: [trip_card_insert_input!]!
  ) {
    insert_trip_plan_one(object: $tripPlan) {
      ...TripPlan
    }
    insert_trip_card(objects: $tripCards) {
      returning {
        ...TripCard
      }
    }
  }
  ${TRIP_PLAN_FRAGMENT}
  ${TRIP_CARD_FRAGMENT}
`

export {
  INSERT_OR_EDIT_TRIP_PLAN,
  UPDATE_TRIP_PLAN,
  INSERT_TRIP_PLAN_COLLABORATOR,
  ACCCEPT_TRIP_PLAN_COLLABORATATION,
  REMOVE_COLLABORATOR,
  INSERT_TRIP_PLAN_LOGS,
  COPY_TRIP_PLAN,
}
