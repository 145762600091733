import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from './comment'

const TRAVEL_ELEMENT_CONTENT_FRAGMENT = gql`
  fragment TravelElementContent on travel_element_content {
    id
    entityType: entity_type {
      id
      name
    }
    location
    metadata
    likesAmount: computed_likes
    iLiked: liked_by_user
    description
    comments {
      ...Comments
    }
    isPrivate: is_private
    path
    travelElementContentUrl: travel_element_content_url
    travelElementId: travel_element_id
    travelElement: travel_element {
      id
      name
      userId: user_id
    }
    blog {
      id
      content
      title
      travatarInfo: travatar {
        id
        name
      }
    }
  }
  ${COMMENT_FRAGMENT}
`

export { TRAVEL_ELEMENT_CONTENT_FRAGMENT }
