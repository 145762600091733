import { gql } from '@apollo/client'
import { CHAT_FRAGMENT } from '../fragments/chat'

const INSERT_CHAT = gql`
  mutation InsertChat(
    $id: uuid!
    $message: String!
    $path: ltree!
    $userId: uuid!
  ) {
    insert_chat_one(
      object: { id: $id, message: $message, path: $path, user_id: $userId }
    ) {
      ...Chat
    }
  }
  ${CHAT_FRAGMENT}
`
export { INSERT_CHAT }
