import { StoreSlice } from '.'
import { IComment, ITravelog, ITripPlan } from '../types'
import travelogService from '../services/travelogService'
import likeService from '../services/likeService'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import commentService from '../services/commentService'
import { getImageUrl } from '../utils/useS3'
import { v4 as uuidv4 } from 'uuid'
import removeDashes from '../utils/removeDashes'
import { formatItinerary } from '../utils/tripPlan'
import tripCardService from '../services/tripCardService'

type TravelogSliceState = {
  travelog: ITravelog | null
}

type TravelogSliceActions = {
  getOrAssignTravelog(id: string): Promise<void>
  getTravelog(id: string): Promise<void>
  setTravelog(travelog: ITravelog): void
  addTravelogLike(travelogId: string, profileId: string): Promise<void>
  deleteTravelogLike(travelogId: string, profileId: string): Promise<void>
  editTravelog(newTravelogData: ITravelog)
  getMoreTravelogs(): Promise<void>
  getMoreLikedTravelogs(): Promise<void>
  addTravelogComment(
    comment: string,
    travelogId: string,
    user: any
  ): Promise<void>
  deleteTravelogComment(commentId: string, travelogId: string): Promise<void>
  getMoreTravelogComments(): Promise<void>
  cleanTravelog(): void
  updateTravelogsInStore(travelog: ITravelog): void
  publishTripPlanAsTravelog(tripPlan: ITripPlan): Promise<ITravelog>
}

export type TravelogSlice = TravelogSliceState & TravelogSliceActions

const defaultTravelogSliceState: TravelogSliceState = {
  travelog: null,
}

export const createTravelogSlice: StoreSlice<TravelogSlice> = (set, get) => ({
  ...defaultTravelogSliceState,
  getOrAssignTravelog: async (travelogId) => {
    try {
      const profileTravelogs = get().profileTravelogs
      let travelog = profileTravelogs?.find(
        (travelog: ITravelog) => travelog.id === travelogId
      )
      if (!travelog) {
        travelog = await travelogService.getOrAssignTravelog(travelogId)
      }
      get().setTravelog(travelog)
      set({
        travelog,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  getTravelog: async (travelogId) => {
    get().setStartLoading()
    try {
      let travelog = get().profileTravelogs.find(
        (travelog: ITravelog) => travelog.id === travelogId
      )
      if (!travelog) {
        travelog = await travelogService.getTravelog(travelogId)
      }
      if (!travelog) get().setNotFound(true)
      get().setTravelog(travelog)
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    } finally {
      get().setEndLoading()
    }
  },
  setTravelog: (travelog) => {
    const travelogItinerary = formatItinerary(travelog?.tripCards)
    get().setItinerary(travelogItinerary)
    set({
      travelog,
    })
  },

  getMoreTravelogs: async () => {
    try {
      const userId = get().profile.id
      const searchInput = get().searchInput
      const searchResultStore = get().searchResult
      const searchResultTravelogs = searchResultStore?.travelogs
      const searchTravatarId = get().searchTravatarId
      const currentProfileTravelogs = get().profileTravelogs

      const offsetSearch = searchResultTravelogs?.length
      const offsetNotSearch = currentProfileTravelogs?.length
      const offset: number = searchInput ? offsetSearch : offsetNotSearch

      const newSearchTravelogs = await travelogService.getMoreTravelogs(
        userId,
        offset,
        searchTravatarId,
        searchInput
      )

      if (searchInput && newSearchTravelogs?.length) {
        const searchResult = {
          ...searchResultStore,
          travelogs: [...searchResultTravelogs, ...newSearchTravelogs],
        }
        set({ searchResult })
      } else if (newSearchTravelogs?.length) {
        const profileTravelogs = [
          ...currentProfileTravelogs,
          ...newSearchTravelogs,
        ]
        const cacheContents = {
          ...get().cacheContents,
          travelogs: profileTravelogs,
        }

        set({
          profileTravelogs,
          cacheContents,
        })
      }
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
        console.log(error)
      }
    }
  },
  getMoreLikedTravelogs: async () => {
    try {
      const userId = get().profile.id
      const likedContentInStore = get().likedContent
      const likedTravelogs = likedContentInStore.travelogs
      const offset = likedTravelogs.length

      const travelogs = await travelogService.getMoreLikedTravelogs(
        userId,
        offset
      )

      const likedContent = {
        ...likedContentInStore,
        travelogs: [...likedTravelogs, ...travelogs],
      }
      set({ likedContent })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  updateTripCardLocation: async () => {
    try {
      const mapCoordinate = get().mapCoordinate
      const tripCardId = get().relocateContentId

      const { entity: travelog, profileEntity: profileTravelogs } =
        await tripCardService.updateTripCardLocation(
          mapCoordinate,
          tripCardId,
          get().profileTravelogs,
          get().travelog
        )

      const cacheContents = {
        ...get().cacheContents,
        travelogs: profileTravelogs,
      }

      get().setTravelog(travelog)
      set({
        cacheContents,
        profileTravelogs,
      })
      get().setAlertMessage('Location saved successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  editTravelog: async (newTravelogData) => {
    try {
      const travelog = await travelogService.editTravelog(newTravelogData)

      let profileTravelogs = get().profileTravelogs

      if (!profileTravelogs.find((tg: ITravelog) => tg.id === travelog.id)) {
        profileTravelogs = [travelog, ...profileTravelogs]
      } else {
        profileTravelogs = profileTravelogs.map((tg) => {
          if (travelog.id === tg.id) {
            return travelog
          }
          return tg
        })
      }
      const cacheContents = {
        ...get().cacheContents,
        travelogs: profileTravelogs,
      }

      set({ travelog, profileTravelogs, cacheContents })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTravelogLike: async (travelogId: string, profileId: string) => {
    try {
      likeService.addLike(
        travelogId,
        profileId,
        EntityTypeEnum.TRAVELOG,
        IdsEnum.ENTITY_TYPE_TRAVELOG
      )

      const profileTravelogs = get().profileTravelogs.map(
        (travelog: ITravelog) => {
          if (travelogId === travelog.id) {
            return {
              ...travelog,
              likesAmount: travelog.likesAmount + 1,
              iLiked: true,
            }
          }
          return travelog
        }
      )

      const travelog = get().travelog
      travelog.likesAmount = travelog?.likesAmount + 1
      travelog.iLiked = true

      const likedContentInStore = get().likedContent

      let likedContent = null
      if (likedContentInStore) {
        likedContent = {
          ...likedContentInStore,
          travelogs: [travelog, ...likedContentInStore.travelogs],
        }
      }

      const cacheContents = {
        ...get().cacheContents,
        travelogs: profileTravelogs,
      }

      set({
        travelog,
        profileTravelogs,
        likedContent,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  deleteTravelogLike: async (travelogId, profileId) => {
    try {
      likeService.deleteLike(travelogId, profileId, EntityTypeEnum.TRAVELOG)

      const profileTravelogs = get().profileTravelogs.map((travelog) => {
        if (travelogId === travelog.id) {
          return {
            ...travelog,
            likesAmount: travelog.likesAmount - 1,
            iLiked: false,
          }
        }
        return travelog
      })

      const travelog = get().travelog
      travelog.likesAmount = travelog?.likesAmount - 1
      travelog.iLiked = false

      const likedContentInStore = get().likedContent

      const likedContent = {
        ...likedContentInStore,
        travelogs: likedContentInStore.travelogs.filter(
          (tg) => tg.id !== travelogId
        ),
      }

      const cacheContents = {
        ...get().cacheContents,
        travelogs: profileTravelogs,
      }

      set({
        travelog,
        profileTravelogs,
        likedContent,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTravelogComment: async (
    comment: string,
    travelogId: string,
    user: any
  ) => {
    try {
      const commentId = uuidv4()

      const newComment: IComment = {
        id: commentId,
        comment,
        createdAt: Date.now(),
        likesAmount: 0,
        iLiked: false,
        path: `${EntityTypeEnum.TRAVELOG}.${removeDashes(travelogId)}`,
        user: {
          id: user.id,
          name: user.name,
          profilePhoto: user.profilePhoto,
          profilePhotoUrl: await getImageUrl(`${user.id}/${user.profilePhoto}`),
        },
      }

      commentService.addComment(
        commentId,
        travelogId,
        user.id,
        EntityTypeEnum.TRAVELOG,
        IdsEnum.ENTITY_TYPE_TRAVELOG,
        comment
      )

      const travelogInStore = get().travelog
      const comments = [newComment, ...travelogInStore.comments]
      const travelog = { ...travelogInStore, comments }

      const profileTravelogs = get().profileTravelogs.map((tg: ITravelog) => {
        if (tg.id === travelogId) {
          return travelog
        }
        return tg
      })
      const cacheContents = {
        ...get().cacheContents,
        travelogs: profileTravelogs,
      }

      set({
        travelog,
        profileTravelogs,
        cacheContents,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  deleteTravelogComment: async (commentId: string, travelogId: string) => {
    try {
      await commentService.deleteComment(commentId)

      const travelog = get().travelog
      if (travelog) {
        const comments = travelog.comments.filter(
          (comment: IComment) => comment.id !== commentId
        )
        travelog.comments = comments
      }

      const profileTravelogs = get().profileTravelogs.map(
        (travelog: ITravelog) => {
          if (travelog.id === travelogId) {
            const comments = travelog.comments.filter(
              (comment: IComment) => comment.id !== commentId
            )
            return {
              ...travelog,
              comments,
            }
          }
          return travelog
        }
      )
      const cacheContents = {
        ...get().cacheContents,
        travelogs: profileTravelogs,
      }

      set({ travelog, profileTravelogs, cacheContents })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  getMoreTravelogComments: async () => {
    const travelog = await travelogService.getMoreComments(get().travelog)
    set({ travelog })
  },
  cleanTravelog: () => {
    set({ travelog: null, tripPlan: null })
  },
  updateTravelogsInStore: (travelog: ITravelog) => {
    const profileTravelogsInStore = get().profileTravelogs

    const profileTravelogs = profileTravelogsInStore.map((tg) => {
      if (tg.id === travelog.id) {
        return travelog
      }
      return tg
    })
    const cacheContents = {
      ...get().cacheContents,
      travelogs: profileTravelogs,
    }
    set({ travelog, profileTravelogs, cacheContents })
  },
  publishTripPlanAsTravelog: async (tripPlan: ITripPlan) => {
    try {
      const { travelog, profileTravelogs, cacheContents } =
        await travelogService.publishTripPlanAsTravelog(
          tripPlan,
          get().profileTravelogs,
          get().cacheContents
        )
      get().setTravelog(travelog)
      set({ profileTravelogs, cacheContents })
      get().setAlertMessage('Travelog published successfully', 'success')
      return travelog
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
})
