import { StoreSlice } from '.'
import { CoordinateType } from '../components/common/SkipdMap/skipMapTypes'

const defaultMapSliceState: MapSliceState = {
  selectLocationModalMapOpen: false,
  mapCoordinate: null,
  onCloseSelectLocationMap: null,
  relocateContentId: '',
}

type MapSliceState = {
  selectLocationModalMapOpen: boolean
  mapCoordinate: CoordinateType
  onCloseSelectLocationMap: () => void
  relocateContentId: string
}

type MapSliceActions = {
  setSelectLocationModalMapOpen: (isOpen: boolean) => void
  setMapCoordinate: (coordinate: CoordinateType) => void
  setOnCloseSelectLocationMap: (onCloseFunction: () => void) => void
  setRelocateContentId: (relocateContentId: string) => void
}

export type MapSlice = MapSliceState & MapSliceActions

export const createMapSlice: StoreSlice<MapSlice> = (set /* get */) => ({
  ...defaultMapSliceState,

  setSelectLocationModalMapOpen: (isOpen: boolean) => {
    set({ selectLocationModalMapOpen: isOpen })
  },
  setMapCoordinate: (mapCoordinate: CoordinateType) => {
    set({ mapCoordinate })
  },
  setOnCloseSelectLocationMap: (onCloseFunction) => {
    set({ onCloseSelectLocationMap: onCloseFunction })
  },
  setRelocateContentId: (relocateContentId) => {
    set({ relocateContentId })
  },
})
