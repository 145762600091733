const inviteToCollaborateTemplate = (invitationUrl, imageUrl, name): string => {
  return `<html xmlns="http://www.w3.org/1999/xhtml" lang="en">
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
      <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta name="color-scheme" content="light dark"/>
      <meta name="supported-color-schemes" content="light dark"/>
      <title>Skipd</title>
      <style type="text/css">
        @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;600&display=swap");
        table {
          border-spacing: 0;
        }
        td {
          padding: 0;
        }
        p {
          font-size: 15px;
        }
        img {
          border: 0;
          border-width: 0;
        }
        a {
          color: #277fd2;
          text-decoration: none;
        }
        @media screen and (max-width: 599.98px) {
          
         
          
          .d-600-none {
            display: none !important;
          }
          
          .center-text-mobile {
            text-align: center !important;
          }
        }
        @media screen and (max-width: 549.98px) {
          .pt-550-0 {
            padding-top: 0 !important;
          }
         
        }
        @media screen and (max-width: 499.98px) {
          .pt-500-0 {
            padding-top: 0 !important;
          }
          .fontlarge{
            font-size: 18px !important;
          }
         
        }
        @media screen and (max-width: 399.98px) {
          .px-sm-0 {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
          .pt-400-0 {
            padding-top: 0 !important;
          }
          .fontlarge{
            font-size: 20px !important;
            line-height: 20px !important;
          }
  
          .fontmed{
            font-size: 12px !important;
          }
          .fontsmall{
            font-size: 10px !important;
          }
          .imgsmall{
            max-width: 70px !important;
            
          }
          
        }
  
        /* Custom Dark Mode Colors */
        :root {
          color-scheme: light dark;
          supported-color-schemes: light dark;
        }
        @media (prefers-color-scheme: dark) {
          body,
          center,
          table,
          .darkmode-bg {
            background: #252334 !important;
            color: #ffffff !important;
          }
          .darkmode-transparent {
            background-color: transparent !important;
          }
        }
      </style>
  
      <!--[if (gte mso 9)|(IE)]>
        <style type="text/css">
          table {
            border-collapse: collapse !important;
          }
        </style>
      <![endif]-->
    </head>
    <body style="
        margin: 0;
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
        min-width: 100%;
        background-color: #252334 !important;
      ">
      <!--[if (gte mso 9)|(IE)]>
        <style type="text/css">
          body {
            background-color: #252334 !important;
          }
          body,
          table,
          td,
          p,
          a {
            font-family: sans-serif, Arial, Helvitica !important;
          }
        </style>
      <![endif]-->
  
      <center style="
          width: 100%;
          table-layout: fixed;
          background-color: #252334 !important;
          padding-bottom: 20px;
          padding-top: 20px;
        ">
        <div style="
            max-width: 600px;
            background-color: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          ">
          <!-- Preheader (remove comment) -->
          <div style="
              font-size: 0px;
              color: #fafdfe;
              line-height: 1px;
              mso-line-height-rule: exactly;
              display: none;
              max-width: 0px;
              max-height: 0px;
              opacity: 0;
              overflow: hidden;
              mso-hide: all;
            ">
            <!-- Add Preheader Text Here (85-100 characters in length) -->
          </div>
          <!-- End Preheader (remove comment) -->
  
          <!--[if (gte mso 9)|(IE)]>
          <table width="600" align="center" style="border-spacing:0;">
          <tr>
          <td style="padding-top:0;padding-right:0;padding-bottom:0;padding-left:0;">
        <![endif]-->
  
          <table align="center" style="
              border-spacing: 0;
              color: #4f5557;
              font-family: 'Lato', sans-serif, Arial, Helvitica;
              background-color: #252334 !important;
              margin: 0;
              padding-top: 0;
              padding-right: 0;
              padding-bottom: 0;
              padding-left: 0;
              width: 100%;
              max-width: 600px;
            " role="presentation">
            <!-- START LOGO & TEXT -->
            <tr>
              <td style="
                  padding-top: 0;
                  padding-right: 0;
                  padding-bottom: 8px;
                  padding-left: 0;
                  text-align: center;
                  font-size: 0;
                ">
                <!--[if (gte mso 9)|(IE)]>
                <table width="100%" style="border-spacing:0;">
                <tr>
                <td width="250" valign="top" style="padding-top:0;padding-right:0;padding-bottom:0;padding-left:0;">
              <![endif]-->
  
                <table bgcolor="#252334" class="column" style="
                    border-spacing: 0;
                    color: #4d5358;
                    width: 100%;
                    max-width: 50%;
                    display: inline-block;
                    background-color: #252334;
                  " role="presentation">
                  <tr>
                    <td width="100%" align="left" class="padded px-600-0" style="
                        padding-top: 20px;
                        padding-right: 10px;
                        padding-bottom: 5px;
                        padding-left: 20px;
                        background-color: #252334;
                      ">
                      <table class="content" style="border-spacing: 0; width: 100%; text-align: center" role="presentation">
                        <tr>
                          <td class="imgsmall" style="
                              padding-top: 0;
                              padding-right: 0;
                              padding-bottom: 0;
                              padding-left: 0;
                              width: 100%;
                              text-align: left;
                              
                              background-color: #252334;
                            ">
                            <a class="imgsmall" href="#"><img class="imgsmall" src="https://i.ibb.co/MRrLvNf/wglobe.png" alt="logo" width="95" style="border-width: 0; max-width: 95px"/></a>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
  
                
  
                <!--[if (gte mso 9)|(IE)]>
                </td><td width="250" valign="top" style="padding-top:0;padding-right:0;padding-bottom:0;padding-left:0;">
              <![endif]-->
  
                <table class="column" style="
                    border-spacing: 0;
                    width: 100%;
                    max-width: 50%;
                    display: inline-block;
                    vertical-align: top;
                  " role="presentation">
                  <tr>
                    <td class="padded px-600-0 pt-500-0" style="
                        padding-top: 10px;
                        padding-right: 20px;
                        padding-bottom: 5px;
                        padding-left: 24px;
                        
                      ">
                      <table class="content" style="border-spacing: 0; text-align: center" role="presentation">
                        <tr>
                          <td class="padded content fontmed" style="
                              padding-top: 10px;
                              padding-bottom: 5px;
                              padding-right: 0;
                              padding-left: 0;
                             
                              text-align: right;
                              
                            ">
                            <p class="fontsmall" style="
                                color: #b16b3e;
                                font-weight: 500;
                                font-size: 13px;
                                font-family: 'Roboto Condensed', sans-serif;
                              ">
                              Explore, Discover and Plane your trips with leading
                              Travel Advisors to assist you!
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
  
                <!--[if (gte mso 9)|(IE)]>
                </td>
                </tr>
                </table>
              <![endif]-->
              </td>
            </tr>
            <!-- END LOGO & TEXT -->
  
            <!-- LINE START -->
  
            <tr>
              <td align="center">
                <table width="560" border="0" cellspacing="0" cellpadding="0" role="presentation">
                  <tr>
                    <!--[if (gte mso 9)|(IE)]>
                  <td style="padding-top:15px;padding-bottom:15px;">
                <![endif]-->
  
                    <td width="560" align="center" style="border-top: 2px solid #44434d; padding: 10px 0"></td>
  
                    <!--[if (gte mso 9)|(IE)]>
                                </td>
                <![endif]-->
                  </tr>
                </table>
              </td>
            </tr>
  
            <!-- LINE END -->
  
            <!-- START CENTERED CONTENT -->
            <tr>
              <td align="center" style="
                  padding-top: 20px;
                  padding-right: 0;
                  padding-bottom: 40px;
                  padding-left: 0;
                  border-radius: 20px !important;
                ">
                <table width="580" align="center" style="width: 580px; text-align: center; border-spacing: 0" role="presentation">
                  <tr>
                    <td class="padding content fontlarge" style="
                        padding-top: 15px;
                        padding-right: 20px;
                        padding-bottom: 10px;
                        padding-left: 20px;
                        width: 100%;
                        text-align: center;
                        font-size: 20px;
                        background-color: #1d1c2a;
                       border-top-left-radius: 20px ;
                       border-top-right-radius: 20px;
                      ">
                      <p class="fontlarge" style="
                          font-size: 32px;
                          line-height: 30px;
                          padding-bottom: 5px;
                          font-family: 'Roboto Condensed', sans-serif;
                          color: #ea8942;
                          font-weight: 600;
                        ">
                        You have been invited to collaborate!
                      </p>
                    </td>
                  </tr>
                  <tr>
  
                    <td style="background-color: #1d1c2a;">
                      <table>
                        <td width="60" style="background-color:#1d1c2a ;">
                          <p style="background-color: #1d1c2a; font-size: 0;"></p>
                        </td>
                     
                    <td class="padding content" style="
                        padding-right: 20px;
                        padding-bottom: 30px;
                        padding-left: 0px;
  
                        text-align: center;
                        font-size: 20px;
                        background-color: #1d1c2a;
                      ">
                      <table bgcolor="#1d1c2a" border="0" cellpadding="0" cellspacing="0" width="100%" style="
                          background-color: #1d1c2a;
                          border-collapse: collapse;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          -ms-text-size-adjust: 100%;
                          -webkit-text-size-adjust: 100%;
                        ">
                        <tbody class="mcnImageGroupBlockOuter">
                          <tr>
                            <td valign="top" style="
                                background-color: #1d1c2a;
                                mso-line-height-rule: exactly;
                                -ms-text-size-adjust: 100%;
                                -webkit-text-size-adjust: 100%;
                              ">
                              <!--[if mso]>
                                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                    <tr>
                                    <![endif]-->
  
                              <!--[if mso]>
                                    <td valign="top" width="560" style="width:560px;">
                                    <![endif]-->
                              <table align="left" width="32" border="0" cellpadding="0" cellspacing="0" style="
                                  background-color: #1d1c2a;
                                  border-collapse: collapse;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  -ms-text-size-adjust: 100%;
                                  -webkit-text-size-adjust: 100%;
                                ">
                                <tbody>
                                  <tr>
                                    <td bgcolor="#1d1c2a" valign="top" style="
                                        align-items: right;
                                        background-color: #1d1c2a;
                                        mso-line-height-rule: exactly;
                                        -ms-text-size-adjust: 100%;
                                        -webkit-text-size-adjust: 100%;
                                        padding-right: 10px;
                                      ">
                                      <img alt="" src=${imageUrl} width="32" style="
                                        border-radius: 20px;
                                          text-align: right;
                                          background-color: #1d1c2a;
                                          padding: 0 0 0 0px;
                                          border: 0;
                                          height: auto;
                                          outline: none;
                                          text-decoration: none;
                                          -ms-interpolation-mode: bicubic;
                                          vertical-align: bottom;
                                        "/>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
  
                              <table bgcolor="#1d1c2a" align="start" width="440" border="0" cellpadding="0" cellspacing="0" style="
                                  border-collapse: collapse;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  -ms-text-size-adjust: 100%;
                                  -webkit-text-size-adjust: 100%;
                                ">
                                <tbody>
                                  <tr>
                                    <td class="mcnImageGroupContent fontmed" valign="top" style="
                                        
                                        background-color: #1d1c2a;
                                        color: #dbdbdd;
                                        font-family: 'Roboto Condensed',
                                          sans-serif;
                                        font-size: 16px;
                                        text-align: start;
                                        mso-line-height-rule: exactly;
                                        -ms-text-size-adjust: 100%;
                                        -webkit-text-size-adjust: 100%;
                                      ">
                                      Your friend <br/>
                                      ${name}, wants you to
                                      collaborate on a Trip plan!
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]>
                                    </td>
                                    <![endif]-->
  
                              <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
  
                      <table align="start" border="0" cellspacing="0" cellpadding="0" role="presentation" style="background-color: #1d1c2a">
                        <tr>
                          <td align="start" style="
                              background-color: #1d1c2a;
                              padding: 20px 0 0 0px;
                            ">
                            <table border="0" cellspacing="0" cellpadding="0" role="presentation">
                              <tr>
                                <td class="fontmed" align="start" style="border-radius: 4px" bgcolor="#ea8942">
                                  <a class="fontmed" href=${invitationUrl} target="_blank" style="
                                      font-size: 16px;
                                      font-weight: 500;
                                      font-family: 'Roboto Condensed', sans-serif;
                                      text-decoration: none;
                                      color: #ffffff;
                                      background-color: #ea8942;
                                      border: 1px solid #ea8942;
                                      border-radius: 4px;
                                      padding: 9px 20px;
                                      display: inline-block;
                                    ">Start Collaborating
                                  </a>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
  
                      <table align="start" border="0" cellspacing="0" cellpadding="0" role="presentation" style="background-color: #1d1c2a">
                        <tr>
                          <td class="fontsmall" align="start" style="
                              background-color: #1d1c2a;
                              padding: 20px 0 0 0px;
                            ">
                            <p class="fontsmall" style="
                                color: #dbdbdd;
                                font-size: 12px;
                                font-family: 'Roboto Condensed', sans-serif;
                                margin-bottom: 2px !important;
                              ">
                              If you have problem with the link above try using
                              this link to join
                            </p>
                            <a class="fontsmall" style="
                                font-size: 12px;
                                font-family: 'Roboto Condensed', sans-serif;
                                color: #b2e441;
                              " href=${invitationUrl}>${invitationUrl}
                          </td>
                        </tr>
                      </table>
                    </td>
  
                  </table>
                </td>
                
                  </tr>
                  <tr>
                    <td align="center" style="background-color: #1d1c2a;">
                      <table width="520" border="0" cellspacing="0" cellpadding="0" role="presentation">
                        <tr>
                          <!--[if (gte mso 9)|(IE)]>
                        <td style="padding-top:15px;padding-bottom:15px;">
                      <![endif]-->
  
                          <td width="520" align="center" style="
                              background-color: #1d1c2a;
                              border-top: 2px solid #44434d;
                              padding: 10px 0;
                            "></td>
  
                          <!--[if (gte mso 9)|(IE)]>
                                        </td>
                        <![endif]-->
                        </tr>
                      </table>
                    </td>
                  </tr>
  
                  <tr>
                    <td style="padding-bottom: 20px; background-color:#1d1c2a ; 
                        border-bottom-left-radius: 20px ;
                        border-bottom-right-radius: 20px; ">
                      <table>
  
                        
                        <td width="60" style="background-color:#1d1c2a ;">
                          <p style="background-color: #1d1c2a; font-size: 0;"></p>
                        </td>
                        <td class="fontsmall" style="background-color:#1d1c2a ;" align="start">
                          <a class="fontsmall" style="color: #b3b3b3; font-size: 12px;" href="">hello@skipd.com</a>
                          <p class="fontsmall" style="color: #b3b3b3; font-size: 12px; margin-top: 2px !important; ">Copyright (C) 2022 Skipd Corporation</p>
                        </td>
                      </table>
                    </td>
                  </tr>
  
  
                </table>
              </td>
            </tr>
            <!-- END CENTERED CONTENT -->
  
            
  
            
          </table>
  
          <!--[if (gte mso 9)|(IE)]>
          </td>
          </tr>
          </table>
        <![endif]-->
        </div>
      </center>
    </body>
  </html>`
}

export default inviteToCollaborateTemplate
