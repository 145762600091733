import { ReactElement, useCallback, useRef } from 'react'
import { Box, Modal, IconButton, Typography } from '@mui/material'
import { IoMdClose } from 'react-icons/io'
import styled from '@emotion/styled'
import { INotification, ITheme } from '../../../types'
import NotificationsItem from './NotificationsItem'
import { colors } from '../../../styles/theme'
import { useStore } from '../../../store'
import { useUser } from '../../../context/Auth'

const MainContainer = styled(Box)(
  ({ theme }: ITheme) => `
  position: relative;
  border-radius: 12px;
  padding: 33px 26px 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 450px;
  max-height: 430px;
  width: 100%;
  height: 488px;
  background-color: ${theme.palette.componentBackground.main};
  color: ${theme.palette.text.primary};
`
)

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 33px;
`
const NotificationsContainer = styled(Box)`
  display: flex;
  height: 80%;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
  padding-right: 5px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.componentBackground.main};
  }
  ::-webkit-scrollbar-thumb {
    margin-top: 10px;
    background-color: ${colors.primary.main};
    border-radius: 20px;
  }
`

type NotificationsListModalProps = {
  open: boolean
  handleClose: () => void
}

const NotificationsListModal = ({
  open,
  handleClose,
}: NotificationsListModalProps): ReactElement => {
  const { notifications, getMoreNotifications, clearNotifications } = useStore()
  const listInnerRef = useRef()
  const { claims } = useUser()

  const handleClearNotifications = useCallback(() => {
    clearNotifications(claims?.id)
  }, [clearNotifications, claims])

  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listInnerRef.current
      if (scrollLeft + clientWidth === scrollWidth) {
        getMoreNotifications(claims?.id)
      }
    }
  }, [claims])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MainContainer>
        <StyledIconButton
          onClick={handleClearNotifications}
          sx={{ marginRight: '40px', marginTop: '7px' }}
        >
          <Typography variant="body1" color="white">
            Clear all
          </Typography>{' '}
        </StyledIconButton>
        <StyledIconButton onClick={handleClose}>
          {' '}
          <IoMdClose style={{ color: colors?.primary?.main }} size={30} />
        </StyledIconButton>

        <Typography variant="h3">Notifications</Typography>

        <NotificationsContainer ref={listInnerRef} onScroll={onScroll}>
          {notifications?.length ? (
            notifications.map((notification: INotification) => (
              <NotificationsItem
                key={notification?.id}
                notification={notification}
              />
            ))
          ) : (
            <Typography variant="h4">...No notifications yet</Typography>
          )}
        </NotificationsContainer>
      </MainContainer>
    </Modal>
  )
}

export default NotificationsListModal
