import { gql } from '@apollo/client'
import { TRAVELOG_FRAGMENT } from '../fragments/travelog'
import { TRIP_CARD_FRAGMENT } from '../fragments/tripCard'

const INSERT_TRAVELOG = gql`
  mutation InsertTravelog(
    $travelogInsertInput: travelog_insert_input!
    $tripCardsInsertInput: [trip_card_insert_input!]!
  ) {
    insert_travelog_one(object: $travelogInsertInput) {
      ...Travelog
    }
    insert_trip_card(objects: $tripCardsInsertInput) {
      returning {
        ...TripCard
      }
    }
  }
  ${TRAVELOG_FRAGMENT}
  ${TRIP_CARD_FRAGMENT}
`

const UPDATE_TRAVELOG = gql`
  mutation UpdateTravelog(
    $id: uuid!
    $title: String
    $description: String
    $coverPhoto: String
    $metadata: jsonb
    $statusId: uuid
  ) {
    update_travelog_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        description: $description
        cover_photo: $coverPhoto
        metadata: $metadata
        status_id: $statusId
      }
    ) {
      ...Travelog
    }
  }
  ${TRAVELOG_FRAGMENT}
`

export { UPDATE_TRAVELOG, INSERT_TRAVELOG }
