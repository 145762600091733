import { gql } from '@apollo/client'
import { TRAVATAR_FRAGMENT } from '../fragments/travatar'
import { TRAVEL_ELEMENT_FRAGMENT } from '../fragments/travelElement'
import { PROFILE_INFO_FRAGMENT } from '../fragments/profile'

const GET_MORE_FOLLOWERS = gql`
  query GetMoreFollowers($path: ltree!, $offset: Int!) {
    follow(
      offset: $offset
      order_by: { created_at: desc }
      limit: 10
      where: { path: { _eq: $path } }
    ) {
      user {
        ...ProfileInfo
      }
    }
  }
  ${PROFILE_INFO_FRAGMENT}
`

const GET_PROFILE_FOLLOWED_CONTENT = gql`
  query GetProfileFollowedContent($id: uuid!) {
    user_by_pk(id: $id) {
      following_travatars(limit: 10, order_by: { created_at: desc }) {
        ...Travatar
      }
      following_travel_elements(limit: 10, order_by: { created_at: desc }) {
        ...TravelElement
      }
    }
  }
  ${TRAVATAR_FRAGMENT}
  ${TRAVEL_ELEMENT_FRAGMENT}
`
const GET_MORE_FOLLOWINGS = gql`
  query GetMoreFollowings($userId: uuid!, $offset: Int!) {
    user_by_pk(id: $userId) {
      following_users(
        offset: $offset
        order_by: { created_at: desc }
        limit: 10
      ) {
        ...ProfileInfo
      }
    }
  }
  ${PROFILE_INFO_FRAGMENT}
`
export { GET_MORE_FOLLOWERS, GET_MORE_FOLLOWINGS, GET_PROFILE_FOLLOWED_CONTENT }
