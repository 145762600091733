import { gql } from '@apollo/client'
import { TRIP_CARD_CONTENT_FRAGMENT } from '../fragments/tripCardContent'

const INSERT_TRAVEL_CONTENT = gql`
  mutation InsertTravelContent(
    $entityTypeId: uuid!
    $location: point
    $path: String!
    $tripCardId: uuid!
    $metadata: jsonb
  ) {
    insert_trip_card_content_one(
      object: {
        entity_type_id: $entityTypeId
        location: $location
        path: $path
        trip_card_id: $tripCardId
        metadata: $metadata
      }
    ) {
      id
    }
  }
`

const INSERT_TRIP_CARD_CONTENTS = gql`
  mutation InsertTripCardContents(
    $tripCardContents: [trip_card_content_insert_input!]!
  ) {
    insert_trip_card_content(objects: $tripCardContents) {
      returning {
        ...TripCardContent
      }
    }
  }
  ${TRIP_CARD_CONTENT_FRAGMENT}
`

const REMOVE_TRAVEL_CONTENTS = gql`
  mutation RemoveTravelContents($travelContentsIds: [uuid!]) {
    delete_travel_content(where: { id: { _in: $travelContentsIds } }) {
      affected_rows
    }
  }
`
const UPDATE_TRAVEL_CONTENT = gql`
  mutation UpdateTravelContent(
    $id: uuid!
    $metadata: jsonb
    $location: point
    $isPrivate: Boolean
  ) {
    update_travel_content_by_pk(
      pk_columns: { id: $id }
      _set: { metadata: $metadata, location: $location, is_private: $isPrivate }
    ) {
      ...TripCardContent
    }
  }
  ${TRIP_CARD_CONTENT_FRAGMENT}
`
const INSERT_TRIP_CARD_CONTENT_DESCRIPTION = gql`
  mutation InsertTripCardContentDescription(
    $tripCardContentId: uuid!
    $description: String!
  ) {
    update_trip_card_content_by_pk(
      pk_columns: { id: $tripCardContentId }
      _set: { description: $description }
    ) {
      ...TripCardContent
    }
  }
  ${TRIP_CARD_CONTENT_FRAGMENT}
`

const UPDATE_TRIP_CARD_CONTENT = gql`
  mutation UpdateTripCardContent($id: uuid!, $location: point) {
    update_trip_card_content_by_pk(
      pk_columns: { id: $id }
      _set: { location: $location }
    ) {
      ...TripCardContent
    }
  }
  ${TRIP_CARD_CONTENT_FRAGMENT}
`

export {
  INSERT_TRAVEL_CONTENT,
  REMOVE_TRAVEL_CONTENTS,
  UPDATE_TRAVEL_CONTENT,
  INSERT_TRIP_CARD_CONTENTS,
  UPDATE_TRIP_CARD_CONTENT,
  INSERT_TRIP_CARD_CONTENT_DESCRIPTION,
}
