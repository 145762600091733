import { gql } from '@apollo/client'
import { TRAVEL_ELEMENT_CONTENT_FRAGMENT } from '../fragments/travelElementContent'

const REMOVE_TRAVEL_ELEMENT_CONTENT = gql`
  mutation RemoveTravelElementContent($travelElementContentId: uuid!) {
    delete_travel_element_content_by_pk(id: $travelElementContentId) {
      ...TravelElementContent
    }
  }
  ${TRAVEL_ELEMENT_CONTENT_FRAGMENT}
`

const INSERT_TRAVEL_ELEMENT_CONTENTS = gql`
  mutation InsertTravelElementContents(
    $travelElementContents: [travel_element_content_insert_input!]!
  ) {
    insert_travel_element_content(objects: $travelElementContents) {
      returning {
        ...TravelElementContent
      }
    }
  }
  ${TRAVEL_ELEMENT_CONTENT_FRAGMENT}
`

const INSERT_TRAVEL_ELEMENT_CONTENT_DESCRIPTION = gql`
  mutation InsertTravelElementContentDescription(
    $travelElementContentId: uuid!
    $description: String!
  ) {
    update_travel_element_content_by_pk(
      pk_columns: { id: $travelElementContentId }
      _set: { description: $description }
    ) {
      ...TravelElementContent
    }
  }
  ${TRAVEL_ELEMENT_CONTENT_FRAGMENT}
`

const UPDATE_TRAVEL_ELEMENT_CONTENT_POSITION = gql`
  mutation UpdateTravelElementContentPosition(
    $travelElementContents: [travel_element_content_insert_input!]!
  ) {
    insert_travel_element_content(
      objects: $travelElementContents
      on_conflict: {
        constraint: travel_element_content_pkey
        update_columns: [metadata, id]
      }
    ) {
      returning {
        ...TravelElementContent
      }
    }
  }
  ${TRAVEL_ELEMENT_CONTENT_FRAGMENT}
`

export {
  REMOVE_TRAVEL_ELEMENT_CONTENT,
  INSERT_TRAVEL_ELEMENT_CONTENTS,
  INSERT_TRAVEL_ELEMENT_CONTENT_DESCRIPTION,
  UPDATE_TRAVEL_ELEMENT_CONTENT_POSITION,
}
