import { StoreSlice } from '.'
import userService from '../services/userService'
import { IProfileInfo } from '../types'

const defaultUserSliceState: UserSliceState = {
  storeUser: null,
}

type UserSliceState = {
  storeUser: IProfileInfo | null
}

type UserSliceActions = {
  getStoreUser(userId: string): Promise<void>
  setStoreUser(user: IProfileInfo | null): void
  changeUserPhoto(progilePhoto: string, profilePhotoUrl: string): void
}

export type UserSlice = UserSliceState & UserSliceActions

export const createUserSlice: StoreSlice<UserSlice> = (set, get) => ({
  ...defaultUserSliceState,
  getStoreUser: async (userId: string) => {
    const storeUser = await userService.getUser(userId)
    set({ storeUser })
  },
  changeUserPhoto: (profilePhoto: string, profilePhotoUrl) => {
    const storeUser = { ...get().storeUser, profilePhoto, profilePhotoUrl }
    set({ storeUser })
  },
  setStoreUser: (storeUser) => {
    set({ storeUser })
  },
})
