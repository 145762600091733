import client from '../gql/clients/apollo-client'
import {
  INSERT_TRIP_CARD_CONTENTS,
  INSERT_TRIP_CARD_CONTENT_DESCRIPTION,
  REMOVE_TRAVEL_CONTENTS,
  UPDATE_TRAVEL_CONTENT,
  UPDATE_TRIP_CARD_CONTENT,
} from '../gql/mutations/tripCardContent'
import {
  GET_ALL_TRIP_CARD_CONTENT_BY_TRIP_PLAN_ID,
  GET_PROFILE_LIKED_TRIP_CARD_CONTENTS,
} from '../gql/querys/tripCardContent'
import { getProfilePhotoUrl } from '../utils/profile'
import {
  formatTripCardContentByDays,
  getTripCardContentUrl,
} from '../utils/travelContent'
import {
  IFileWithLocation,
  ITripCard,
  ITripCardContent,
  ITripPlan,
} from '../types'
import { getMentions, getTags } from '../utils'
import IdsEnum from '../constants/ids.constants'
import { getImageUrl, uploadFile } from '../utils/useS3'
import { CoordinateType } from '../components/common/SkipdMap/skipMapTypes'

export default {
  getLikedTripCardContents: async (tripCardContentIds: string[]) => {
    const result = await client.query({
      query: GET_PROFILE_LIKED_TRIP_CARD_CONTENTS,
      variables: {
        tripCardContentIds,
      },
    })
    let travelContents = result?.data?.travel_content
    travelContents = Promise.all(
      travelContents.map(async (te) => {
        const travelContentUrl = await getTripCardContentUrl(te.id, te.path)
        const profileName = te.travelog?.travatar?.user?.name
        const profileId = te.travelog?.travatar?.user?.id
        const profileCover = te.travelog?.travatar?.user?.profile_photo
        const profileCoverUrl = await getProfilePhotoUrl(
          profileCover,
          profileId
        )

        return {
          ...te,
          travelContentUrl,
          profileInfo: {
            name: profileName,
            photoUrl: profileCoverUrl,
          },
        }
      })
    )

    return travelContents
  },

  removeTripCardContents: async (travelContentsIds: string) => {
    try {
      await client.mutate({
        mutation: REMOVE_TRAVEL_CONTENTS,
        variables: { travelContentsIds },
      })
    } catch {
      throw new Error('Trip Content could not be deleted')
    }
  },
  insertToTripCard: async (
    filesWithLocation: IFileWithLocation[],
    tripCardId: string
  ): Promise<ITripCardContent[]> => {
    const travelContentInsertInput = filesWithLocation.map(
      (fileWithLocalization) => {
        const file = fileWithLocalization.file
        const localization = fileWithLocalization.localization
        return {
          trip_card_id: tripCardId,
          location: localization
            ? `(${localization?.latitude}, ${localization?.longitude})`
            : null,
          path: file.name,
          entity_type_id: file.type.includes('image')
            ? IdsEnum.ENTITY_TYPE_IMAGE
            : IdsEnum.ENTITY_TYPE_VIDEO,
        }
      }
    )
    const result = await client.mutate({
      mutation: INSERT_TRIP_CARD_CONTENTS,
      variables: { tripCardContents: travelContentInsertInput },
    })

    const tripCardContents = result.data.insert_trip_card_content.returning

    const tripCardContentsWithUrl = await Promise.all(
      tripCardContents.map(async (tc, index) => {
        const file = filesWithLocation[index].file
        const key = await uploadFile({
          rawKey: `${tc.id}/${file.name}`,
          contentType: file.type,
          file: file,
        })
        const url = await getImageUrl(key)

        return {
          ...tc,
          tripCardContentUrl: url,
        }
      })
    )

    return tripCardContentsWithUrl
  },
  editTripCardContent: async (
    travelContent: ITripCardContent
  ): Promise<ITripCardContent> => {
    travelContent.metadata = {
      ...travelContent.metadata,
      tags: travelContent?.metadata?.description
        ? getTags(travelContent?.metadata?.description)
        : null,
      mentions: travelContent?.metadata?.description
        ? getMentions(travelContent?.metadata?.description)
        : null,
    }
    const result = await client.mutate({
      mutation: UPDATE_TRAVEL_CONTENT,
      variables: {
        id: travelContent.id,
        metadata: travelContent.metadata,
        location: travelContent.location,
        isPrivate: travelContent.isPrivate,
      },
    })
    const travelContentUpdated = result.data.update_travel_content_by_pk
    return travelContentUpdated
  },

  completeTripCardContents: async (tripCardContents: ITripCardContent[]) => {
    return await Promise.all(
      tripCardContents.map(async (tcc) => {
        const tripCardContentUrl = await getTripCardContentUrl(tcc.id, tcc.path)
        return {
          ...tcc,
          tripCardContentUrl,
        }
      })
    )
  },
  getPublicAndPrivates: async (tripPlanId) => {
    const result = await client.query({
      query: GET_ALL_TRIP_CARD_CONTENT_BY_TRIP_PLAN_ID,
      variables: { tripPlanId },
    })
    const tripCardContent = await Promise.all(
      result.data.travel_content.map(async (tc) => {
        const tripCardContentUrl = await getTripCardContentUrl(tc.id, tc.path)
        return {
          ...tc,
          tripCardContentUrl,
        }
      })
    )

    const formatedTripCardContent = formatTripCardContentByDays(tripCardContent)
    return formatedTripCardContent
  },
  addDescription: async (
    tripCardContentId: string,
    description: string,
    tripPlanFromStore: ITripPlan,
    profileTripPlansFromStore: ITripPlan[]
  ) => {
    const result = await client.mutate({
      mutation: INSERT_TRIP_CARD_CONTENT_DESCRIPTION,
      variables: {
        tripCardContentId,
        description,
      },
    })
    const tripCardContentNoUrl: ITripCardContent =
      result.data.update_trip_card_content_by_pk

    const tripCardContent = {
      ...tripCardContentNoUrl,
      tripCardContentUrl: await getImageUrl(
        `${tripCardContentNoUrl.id}/${tripCardContentNoUrl.path}`
      ),
    }

    const tripCards = tripPlanFromStore.tripCards.map((tc: ITripCard) => {
      const tripCardContentIndex = tc.tripCardContents.findIndex(
        (tcc) => tcc.id === tripCardContent.id
      )
      const tripCardContents = tc.tripCardContents

      if (tripCardContentIndex !== -1) {
        tripCardContents.splice(tripCardContentIndex, 1, tripCardContent)
      }
      return {
        ...tc,
        tripCardContents,
      }
    })

    const tripPlan = { ...tripPlanFromStore, tripCards }

    const profileTripPlansIndex = profileTripPlansFromStore.findIndex(
      (tp) => tp.id == tripPlan.id
    )
    if (profileTripPlansIndex !== -1) {
      profileTripPlansFromStore.splice(profileTripPlansIndex, 1, tripPlan)
    }

    const profileTripPlans = profileTripPlansFromStore

    return { tripPlan, profileTripPlans }
  },
  updateTripCardContentLocation: async (
    mapCoordinate: CoordinateType,
    tripCardContentId,
    profileTripPlansInStore,
    cacheContentsInStore,
    tripPlanInStore
  ) => {
    const location = `(${mapCoordinate.latitude}, ${mapCoordinate.longitude})`
    const result = await client.mutate({
      mutation: UPDATE_TRIP_CARD_CONTENT,
      variables: {
        id: tripCardContentId,
        location,
      },
    })

    const tripCardContentResult: ITripCardContent =
      result.data.update_trip_card_content_by_pk

    const tripCardContent: ITripCardContent = {
      ...tripCardContentResult,
      tripCardContentUrl: await getTripCardContentUrl(
        tripCardContentId,
        tripCardContentResult.path
      ),
    }

    const tripPlan = {
      ...tripPlanInStore,
      tripCards: tripPlanInStore.tripCards.map((tc) => {
        const tripCardContents = tc?.tripCardContents?.map((tc) => {
          if (tc.id === tripCardContentId) {
            return tripCardContent
          }
          return tc
        })
        return { ...tc, tripCardContents }
      }),
    }

    const profileTripPlans = profileTripPlansInStore.map((tp: ITripPlan) => {
      if (tp.id === tripPlan.id) {
        return tripPlan
      }
      return tp
    })

    const cacheContents = {
      ...cacheContentsInStore,
      tripPlans: profileTripPlans,
    }
    return { profileTripPlans, cacheContents, tripPlan }
  },
}
