import { gql } from '@apollo/client'
import { TRIP_CARD_FRAGMENT } from '../fragments/tripCard'

const REMOVE_TRIP_CARDS = gql`
  mutation RemoveTripCards($tripCardIds: [uuid!]) {
    delete_trip_card_content(where: { trip_card_id: { _in: $tripCardIds } }) {
      affected_rows
    }
    delete_trip_card(where: { id: { _in: $tripCardIds } }) {
      affected_rows
    }
  }
`

const INSERT_OR_UPDATE_TRIP_CARDS = gql`
  mutation InsertOrUpdateTripCards($tripCards: [trip_card_insert_input!]!) {
    insert_trip_card(
      objects: $tripCards
      on_conflict: {
        constraint: trip_card_pkey
        update_columns: [metadata, travel_element_id, trip_plan_id, id]
      }
    ) {
      returning {
        ...TripCard
      }
    }
  }
  ${TRIP_CARD_FRAGMENT}
`

const UPDATE_TRIP_CARD_LOCATION = gql`
  mutation UpdateTripCardLocation($tripCardId: uuid!, $location: point!) {
    update_trip_card_by_pk(
      pk_columns: { id: $tripCardId }
      _set: { location: $location }
    ) {
      ...TripCard
    }
  }
  ${TRIP_CARD_FRAGMENT}
`

export {
  REMOVE_TRIP_CARDS,
  INSERT_OR_UPDATE_TRIP_CARDS,
  UPDATE_TRIP_CARD_LOCATION,
}
