import { gql } from '@apollo/client'
import {
  PROFILE_INFO_FRAGMENT,
  USER_FRAGMENT,
  USER_LIKED_CONTENT_FRAGMENT,
} from '../fragments/profile'
import { TRAVATAR_FRAGMENT } from '../fragments/travatar'
import { TRAVEL_ELEMENT_FRAGMENT } from '../fragments/travelElement'
import { TRAVEL_ELEMENT_CONTENT_FRAGMENT } from '../fragments/travelElementContent'
import { TRAVELOG_FRAGMENT } from '../fragments/travelog'
import { TRIP_CARD_CONTENT_FRAGMENT } from '../fragments/tripCardContent'
import { TRIP_PLAN_FRAGMENT } from '../fragments/tripPlan'

const GET_PROFILE = gql`
  query GetProfile($id: uuid!) {
    user_by_pk(id: $id) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`

const GET_USER = gql`
  query GetUser($userId: uuid!) {
    user_by_pk(id: $userId) {
      ...ProfileInfo
    }
  }
  ${PROFILE_INFO_FRAGMENT}
`

const SEARCH_BY_NAME = gql`
  query SearchByName($name: String!, $profileId: uuid!) {
    travatar(
      where: {
        name: { _ilike: $name }
        user_id: { _eq: $profileId }
        deleted_at: { _is_null: true }
      }
      limit: 10
      order_by: { created_at: desc }
    ) {
      ...Travatar
    }
    travelog(
      where: {
        title: { _ilike: $name }
        user_id: { _eq: $profileId }
        deleted_at: { _is_null: true }
      }
      limit: 10
      order_by: { created_at: desc }
    ) {
      ...Travelog
    }
    travelElement: travel_element(
      where: {
        name: { _ilike: $name }
        user_id: { _eq: $profileId }
        deleted_at: { _is_null: true }
      }
      limit: 10
      order_by: { created_at: desc }
    ) {
      ...TravelElement
    }
    tripPlan: trip_plan(
      where: {
        title: { _ilike: $name }
        user_id: { _eq: $profileId }
        deleted_at: { _is_null: true }
      }
      limit: 10
      order_by: { created_at: desc }
    ) {
      ...TripPlan
    }
  }
  ${TRAVATAR_FRAGMENT}
  ${TRAVELOG_FRAGMENT}
  ${TRAVEL_ELEMENT_FRAGMENT}
  ${TRIP_PLAN_FRAGMENT}
`

const GET_PROFILE_LIKED_CONTENT = gql`
  query GetUser($id: uuid!) {
    user_by_pk(id: $id) {
      ...UserLikedContent
    }
  }
  ${USER_LIKED_CONTENT_FRAGMENT}
`

const GET_MORE_LIKED_CONTENT = gql`
  query GetMoreLikedContent(
    $userId: uuid!
    $offsetTec: Int!
    $offsetTcc: Int!
  ) {
    user_by_pk(id: $userId) {
      likedTravelElementContent: liked_travel_element_contents(
        limit: 10
        offset: $offsetTec
      ) {
        ...TravelElementContent
      }
      likedTripCardContent: liked_trip_card_contents(
        limit: 10
        offset: $offsetTcc
      ) {
        ...TripCardContent
      }
    }
  }
  ${TRAVEL_ELEMENT_CONTENT_FRAGMENT}
  ${TRIP_CARD_CONTENT_FRAGMENT}
`
const SEARCH_PROFILE_BY_NAME = gql`
  query SearchProfileByName($name: String!, $offset: Int!) {
    user(where: { name: { _ilike: $name } }, limit: 10, offset: $offset) {
      ...ProfileInfo
    }
  }
  ${PROFILE_INFO_FRAGMENT}
`

const GET_PROFILE_BY_EMAIL = gql`
  query GetProfileByEmail($email: String!) {
    user(where: { email: { _ilike: $email } }) {
      ...ProfileInfo
    }
  }
  ${PROFILE_INFO_FRAGMENT}
`

export {
  GET_PROFILE,
  GET_USER,
  SEARCH_BY_NAME,
  GET_PROFILE_LIKED_CONTENT,
  GET_MORE_LIKED_CONTENT,
  SEARCH_PROFILE_BY_NAME,
  GET_PROFILE_BY_EMAIL,
}
