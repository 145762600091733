import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from './comment'
import { LIKES_FRAGMENT } from './like'
import { TRAVEL_ELEMENT_CONTENT_FRAGMENT } from './travelElementContent'
import { TRAVELOG_FRAGMENT } from './travelog'

const TRAVEL_ELEMENT_INFO_FRAGMENT = gql`
  fragment TravelElementInfo on travel_element {
    id
    name
    description
    coverPhoto: cover_photo
    isVerified: is_verified
    location
    metadata
    statusId: status_id
    likesAmount: computed_likes
    iLiked: liked_by_user
    iFollow: followed_by_user
    userId: user_id
    travatarId: travatar_id
    travatarInfo: travatar {
      name
    }
    profileInfo: user {
      name
      id
      profilePhoto: profile_photo
    }
  }
`

const TRAVEL_ELEMENT_FRAGMENT = gql`
  fragment TravelElement on travel_element {
    ...TravelElementInfo
    comments {
      ...Comments
    }
    followersAmount: computed_followers
    travatar {
      id
      name
      profilePhoto: profile_photo
      profileInfo: user {
        id
        name
        profilePhoto: profile_photo
      }
    }
    travelElementContents: travel_element_contents {
      ...TravelElementContent
    }
    relatedTravelogs: related_travelogs {
      ...Travelog
    }
    followerUsers: follower_users {
      id
      name
      profilePhoto: profile_photo
    }
  }
  ${TRAVEL_ELEMENT_CONTENT_FRAGMENT}
  ${LIKES_FRAGMENT}
  ${COMMENT_FRAGMENT}
  ${TRAVELOG_FRAGMENT}
  ${TRAVEL_ELEMENT_INFO_FRAGMENT}
`

export { TRAVEL_ELEMENT_FRAGMENT, TRAVEL_ELEMENT_INFO_FRAGMENT }
