enum TripPlanLogsEnum {
  CREATED = 'has created the trip plan',
  TITLED = 'has titled the trip plan',
  DELETED_CARD = 'has deleted a card',
  MODIFIED_CARD = 'has modified a card',
  MOVED_CARD = 'has change position of a card',
  NEW_CARD = 'has inserted a new card',
  NEW_DAY = 'has added a new day',
  DELETED_DAY = 'has deleted a day',
}

export default TripPlanLogsEnum
