import { gql } from '@apollo/client'
import { NOTIFICATION_FRAGMENT } from '../fragments/notification'

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription Notifications($id: uuid!) {
    notification(
      where: { to: { _eq: $id } }
      order_by: { read: asc, created_at: desc }
      limit: 8
    ) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`

export { NOTIFICATION_SUBSCRIPTION }
