import { StoreSlice } from '.'
import blogService from '../services/blogService'
import travelElementService from '../services/travelElementService'
import { IBlog, ITravelElement } from '../types'

type BlogSliceState = {
  blog: IBlog
  searchInputBlogs: string
  searchBlogsResult: IBlog[] | []
  searchBlogInput: string
}

type BlogSliceActions = {
  getOrAssignNewBlog(blogId: string): void
  InsertOrUpdateNewBlog(blog: any): void
  blogSearch(search: string): void
  insertContentForBlog(blog: IBlog): void
  cleanBlog(): void
}

const defaultBlogSliceState: BlogSliceState = {
  blog: null,
  searchInputBlogs: '',
  searchBlogsResult: [],
  searchBlogInput: '',
}

export type BlogSlice = BlogSliceState & BlogSliceActions

export const createBlogSlice: StoreSlice<BlogSlice> = (set, get) => ({
  ...defaultBlogSliceState,

  cleanBlog: () => {
    set({ blog: null })
  },

  getOrAssignNewBlog: async (blogId: string) => {
    try {
      const profileBlogs = get().profileBlogs

      let blog = profileBlogs.find((blog: IBlog) => blog.id === blogId)

      if (!blog) {
        blog = await blogService.getOrAssingNewBlog(blogId)
      }

      set({
        blog,
      })
    } catch (error) {
      console.log(error)
    }
  },
  blogSearch: async (searchInput: string) => {
    try {
      if (searchInput) {
        const searchedBlogs = await blogService.blogSearch(searchInput)

        set({
          searchBlogInput: searchInput,
          searchBlogsResult: searchedBlogs,
        })
      } else {
        const searchBlogsResult = []
        set({
          searchBlogInput: searchInput,
          searchBlogsResult,
        })
      }
    } catch (error) {
      console.log(error)
    }
  },
  insertContentForBlog: async (blog: IBlog) => {
    const currentProfileTravelElements = get().profileTravelElements.filter(
      (te: ITravelElement) => te.id != blog.travelElementId
    )
    const currentTravelElement = get().travelElements.filter(
      (te: ITravelElement) => te?.id != blog?.travelElementId
    )

    if (blog.travelElementId) {
      const travelElementToModify = get().profileTravelElements.find(
        (te: ITravelElement) => te.id == blog.travelElementId
      )

      const isContentExist = travelElementToModify.travelElementContents.find(
        (tec) => tec.path == blog.id
      )

      if (!isContentExist) {
        const contentLength = travelElementToModify.travelElementContents.length

        const newTravelElementContent =
          await travelElementService.insertTravelElementBlogContent(
            blog,
            contentLength
          )

        travelElementToModify.travelElementContents = [
          ...travelElementToModify.travelElementContents,
          { ...newTravelElementContent, blog: blog },
        ]

        const profileTravelElements = [
          ...currentProfileTravelElements,
          travelElementToModify,
        ]

        const travelElements = [...currentTravelElement, travelElementToModify]

        get().setAlertMessage('Blog Added', 'success')

        set({
          profileTravelElements,
          travelElement: travelElementToModify,
          travelElements,
        })
      } else {
        get().setAlertMessage(
          'This Blog already exist on your Travel Element',
          'error'
        )
      }
    }
  },
  InsertOrUpdateNewBlog: async (blog) => {
    try {
      const currentBlogs = get().profileBlogs.filter(
        (currentBlog: IBlog) => currentBlog?.id != blog?.id
      )

      const currentProfileTravelElements = get().profileTravelElements.filter(
        (te: ITravelElement) => te.id != blog.travelElementId
      )

      const currentTravelElement = get().travelElements.filter(
        (te: ITravelElement) => te?.id != blog?.travelElementId
      )

      const newBlog = await blogService.InsertOrUpdateNewBlog(blog)

      if (blog.travelElementId) {
        const travelElementToModify = get().profileTravelElements.find(
          (te: ITravelElement) => te.id == blog.travelElementId
        )

        const isContentExist = travelElementToModify.travelElementContents.find(
          (tec) => tec.path == blog.id
        )

        if (!isContentExist) {
          const contentLength =
            travelElementToModify.travelElementContents.length

          const newTravelElementContent =
            await travelElementService.insertTravelElementBlogContent(
              blog,
              contentLength
            )

          travelElementToModify.travelElementContents = [
            ...travelElementToModify.travelElementContents,
            { ...newTravelElementContent, blog: newBlog },
          ]

          const profileTravelElements = [
            ...currentProfileTravelElements,
            travelElementToModify,
          ]

          const travelElements = [
            ...currentTravelElement,
            travelElementToModify,
          ]

          get().setAlertMessage('Blog Updated', 'success')

          set({
            profileTravelElements,
            travelElement: travelElementToModify,
            travelElements,
          })
        } else {
          const newContent = { ...isContentExist, blog: newBlog }

          const currentTravelElementContents =
            travelElementToModify.travelElementContents.filter(
              (tec) => tec.path != blog.id
            )
          travelElementToModify.travelElementContents = [
            ...currentTravelElementContents,
            newContent,
          ]

          get().setAlertMessage('Blog Updated', 'success')

          set({ travelElement: travelElementToModify })
        }
      }
      const profileBlogs = [...currentBlogs, newBlog]

      set({
        profileBlogs,
      })
    } catch (error) {
      console.log(error)
    }
  },
})
