import IdsEnum from '../constants/ids.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import client from '../gql/clients/apollo-client'
import { INSERT_OR_UPDATE_BLOG } from '../gql/mutations/blog'
import { GET_BLOG, GET_BLOG_BY_NAME } from '../gql/querys/blog'
import { IBlog } from '../types'
import { isValidUuid } from '../utils'

export default {
  getOrAssingNewBlog: async (blogId: string) => {
    if (!isValidUuid(blogId)) {
      throw new InvalidUuidError('Invalid Trip Plan id')
    }
    let blog: IBlog

    const result = await client.query({
      query: GET_BLOG,
      variables: {
        id: blogId,
      },
    })

    if (result?.data.blog_by_pk) {
      blog = result?.data.blog_by_pk
    } else {
      blog = { id: blogId }
    }

    return blog
  },
  InsertOrUpdateNewBlog: async (blog: IBlog) => {
    const result = await client.mutate({
      mutation: INSERT_OR_UPDATE_BLOG,
      variables: {
        id: blog?.id,
        title: blog?.title,
        entityTypeId: IdsEnum.ENTITY_TYPE_BLOG_POST,
        travatarId: blog.travatarId,
        userId: blog?.userId,
        content: blog?.content,
      },
    })

    const newBlog = result.data.insert_blog_one

    return newBlog
  },
  blogSearch: async (search: string) => {
    const result = await client.query({
      query: GET_BLOG_BY_NAME,
      variables: {
        search: `%${search}%`,
      },
    })
    const blogsResult = result.data.blog
    return blogsResult
  },
}
