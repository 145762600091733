import client from '../gql/clients/apollo-client'
import {
  DELETE_TRAVATAR_BY_ID,
  INSERT_TRAVATAR,
  UPDATE_TRAVATAR,
} from '../gql/mutations/travatar'
import {
  GET_ALL_TRAVATARS,
  GET_COMPLETE_TRAVATAR_BY_ID,
  GET_MORE_FOLLOWING_TRAVATARS,
  GET_TRAVATAR_BY_ID,
  SEARCH_BY_TRAVATAR_ID,
} from '../gql/querys/travatar'
import { ITravatar } from '../types'
import { getImageUrl, uploadFile } from '../utils/useS3'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import { isValidUuid } from '../utils'
import tripPlanService from './tripPlanService'
import travelElementService from './travelElementService'
import IdsEnum from '../constants/ids.constants'
import travelogService from './travelogService'

export const getTravatarCoverPhotoUrl = async (
  id: string,
  profilePhotoName: string
): Promise<string> => {
  if (profilePhotoName)
    return await getImageUrl(
      `${
        profilePhotoName?.includes('default') ? 'travatar_default_picture' : id
      }/${profilePhotoName}`
    )
}

const getTravatarStatus = (travelElement): string => {
  if (
    travelElement?.coverPhoto &&
    travelElement?.description &&
    travelElement?.name &&
    travelElement?.status !== IdsEnum.STATUS_PUBLISHED
  ) {
    return IdsEnum.STATUS_COMPLETE
  } else if (travelElement.status !== IdsEnum.STATUS_PUBLISHED) {
    return IdsEnum.STATUS_DRAFT
  } else {
    return IdsEnum.STATUS_PUBLISHED
  }
}

export default {
  getCompleteTravatar: async (travatarId) => {
    const result = await client.query({
      query: GET_COMPLETE_TRAVATAR_BY_ID,
      variables: {
        id: travatarId,
      },
    })

    const travatarResult = result.data.travatar_by_pk

    const travatar = {
      ...travatarResult,
      coverPhotoUrl: await getTravatarCoverPhotoUrl(
        travatarResult.id,
        travatarResult.profilePhoto
      ),
      followerUsers: await Promise.all(
        travatarResult.followerUsers.map(async (profile) => ({
          ...profile,
          profilePhotoUrl: profile.profilePhoto
            ? await getImageUrl(`${profile.id}/${profile.profilePhoto}`)
            : '',
        }))
      ),
    }

    const travatarTripPlans = await tripPlanService.completeTripPlansInfo(
      travatar.tripPlans
    )
    const travelogs = result.data.travelog

    const travatarTravelogs = await travelogService.completeTravelogsInfo({
      travelogs,
    })

    const travatarTravelElements =
      await travelElementService.completeTravelElementsInfo(
        travatar.travelElements
      )

    return {
      travatar,
      travatarTravelogs,
      travatarTripPlans,
      travatarTravelElements,
    }
  },
  getOrAssignNewTravatar: async (travatarId: string) => {
    if (!isValidUuid(travatarId)) {
      throw new InvalidUuidError('Invalid Travatar id')
    }
    let travatar: ITravatar

    const result = await client.query({
      query: GET_TRAVATAR_BY_ID,
      variables: {
        id: travatarId,
      },
    })

    if (result.data.travatar_by_pk) {
      travatar = result.data.travatar_by_pk
      const coverPhotoUrl = await getTravatarCoverPhotoUrl(
        travatar?.id,
        travatar?.profilePhoto
      )
      travatar = { ...travatar, coverPhotoUrl }
    } else {
      travatar = { id: travatarId, statusId: IdsEnum.STATUS_DRAFT }
    }

    return travatar
  },
  createOrEditTravatar: async (newPhoto, newTravatarData) => {
    const status = getTravatarStatus(newTravatarData)

    const newPhotoName = newTravatarData.coverPhoto

    const result = await client.mutate({
      mutation: INSERT_TRAVATAR,
      variables: {
        description: newTravatarData?.description,
        name: newTravatarData?.name,
        id: newTravatarData?.id,
        userId: newTravatarData?.userId,
        statusId: status,
        profilePhoto: newPhotoName,
      },
    })

    const newTravatarInfo = result.data.insert_travatar_one as ITravatar

    if (newPhoto !== null) {
      await uploadFile({
        rawKey: `${newTravatarData.id}/${newPhotoName}`,
        contentType: newPhoto.contentType,
        file: newPhoto,
      })
    }

    const photoName = newPhotoName

    const coverPhotoUrl = await getTravatarCoverPhotoUrl(
      newTravatarInfo.id,
      photoName
    )

    const travatar = {
      ...newTravatarInfo,
      profilePhoto: photoName,
      coverPhotoUrl,
    } as ITravatar

    return travatar
  },
  editTravatar: async (newPhoto, newTravatarData) => {
    if (!isValidUuid(newTravatarData.id)) {
      throw new InvalidUuidError('Invalid Travatar id')
    }

    const result = await client.mutate({
      mutation: UPDATE_TRAVATAR,
      variables: {
        id: newTravatarData.id,
        description: newTravatarData.description,
        name: newTravatarData.name,
        profilePhoto: newTravatarData.coverPhoto,
      },
    })

    if (newPhoto) {
      await uploadFile({
        rawKey: `${newTravatarData.id}/travatar_photo.${newPhoto.name
          .split('.')
          .pop()}`,
        contentType: newPhoto.contentType,
        file: newPhoto,
      })
    }

    const photoName = newTravatarData.coverPhoto
    const coverPhotoUrl = await getTravatarCoverPhotoUrl(
      newTravatarData.id,
      photoName
    )

    const travatar = {
      ...result.data?.update_travatar_by_pk,
      coverPhotoUrl,
    } as ITravatar

    return travatar
  },
  fetchCoverImages: async (travatars: ITravatar[]): Promise<ITravatar[]> => {
    return await Promise.all(
      travatars.map(async (travatar: ITravatar) => {
        const coverPhotoUrl = await getTravatarCoverPhotoUrl(
          travatar.id,
          travatar.profilePhoto
        )

        return {
          ...travatar,
          coverPhotoUrl,
        }
      })
    )
  },
  deleteTravatar: async (travatarId) => {
    await client.mutate({
      mutation: DELETE_TRAVATAR_BY_ID,
      variables: { id: travatarId },
    })
  },
  searchByTravatar: async (travatarId, profileId) => {
    const result = await client.query({
      query: SEARCH_BY_TRAVATAR_ID,
      variables: {
        travatarId,
        profileId,
      },
    })
    const { travelog, tripPlan, travelElement } = result.data

    const profileTravelogs = await travelogService.completeTravelogsInfo({
      travelogs: travelog,
    })

    const profileTripPlans = await tripPlanService.completeTripPlansInfo(
      tripPlan
    )

    const profileTravelElements =
      await travelElementService.completeTravelElementsInfo(travelElement)

    return { profileTravelogs, profileTripPlans, profileTravelElements }
  },
  getMoreFollowingTravatars: async (userId, offset) => {
    const result = await client.query({
      query: GET_MORE_FOLLOWING_TRAVATARS,
      variables: {
        userId,
        offset,
      },
    })
    const travatars = await Promise.all(
      result.data.user_by_pk.following_travatars.map(async (travatar) => {
        return {
          ...travatar,
          coverPhotoUrl: await getTravatarCoverPhotoUrl(
            travatar.id,
            travatar.profilePhoto
          ),
        }
      })
    )
    return travatars
  },
  getAllTravatars: async (userId): Promise<ITravatar[]> => {
    const result = await client.query({
      query: GET_ALL_TRAVATARS,
      variables: { userId },
    })
    return result.data.travatar
  },
}
