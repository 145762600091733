import { gql } from '@apollo/client'
import { NOTIFICATION_FRAGMENT } from '../fragments/notification'

const GET_MORE_NOTIFICATIONS = gql`
  query Notifications($id: uuid!, $offset: Int!) {
    notification(
      where: { to: { _eq: $id } }
      order_by: { read: asc, created_at: desc }
      limit: 8
      offset: $offset
    ) {
      ...Notification
    }
  }

  ${NOTIFICATION_FRAGMENT}
`

const GET_UNREAD_NOTIFICATIONS_AMOUNT = gql`
  query UnreadNotificationsAmount($id: uuid!) {
    notification_aggregate(where: { to: { _eq: $id }, read: { _eq: false } }) {
      aggregate {
        count
      }
    }
  }
`

export { GET_MORE_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS_AMOUNT }
