import styled from '@emotion/styled'
import { Typography, Box, Avatar } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import Link from 'next/link'
import { ReactElement, useEffect, useState } from 'react'
import { useStore } from '../../../store'
import { colors } from '../../../styles/theme'
import { INotification } from '../../../types'
import { getImageUrl } from '../../../utils/useS3'

type NotificationContainerProps = {
  isRead: boolean
}

const NotificationContainer = styled(Box)<NotificationContainerProps>`
  width: 100%;
  height: 60px;
  display: flex;
  gap: 6px;
  padding: 9px 13px;
  background-color: ${({ isRead }) => (!isRead ? ' #786DCC33' : 'none')};
  cursor: pointer;
  border-radius: 4px;
  :hover {
    background-color: ${colors.componentBackground.secondary};
  }
`
const BodyContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

type NotificationItemProps = {
  notification: INotification
}

const NotificationItem = ({
  notification,
}: NotificationItemProps): ReactElement => {
  const [avatar, setAvatar] = useState('')
  const { readNotification } = useStore()

  const handleReadNotification = (): void => {
    if (!notification.isRead) {
      readNotification(notification?.id)
    }
  }

  useEffect(() => {
    if (notification?.body?.path) {
      getImageUrl(notification?.body?.path).then((url) => {
        setAvatar(url)
      })
    }
  }, [notification])

  return (
    <Link href={notification?.body?.link}>
      <NotificationContainer
        onClick={handleReadNotification}
        isRead={notification?.isRead}
      >
        <Avatar
          src={avatar || '/images/travelog5.jpg'}
          style={{ height: '45px', width: '45px' }}
        />
        <BodyContainer>
          <Box>
            <Box sx={{ display: 'flex', alignContent: 'center' }}>
              <Typography variant="subtitle2" sx={{ lineHeight: '16px' }}>
                {notification.body.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginLeft: 1, fontSize: '10px' }}
              >
                {formatDistanceToNow(new Date(notification.createdAt), {
                  addSuffix: false,
                })}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{ fontSize: '14px', fontWeight: '400' }}
            >
              {notification.body.description}
            </Typography>
          </Box>
        </BodyContainer>
      </NotificationContainer>
    </Link>
  )
}
export default NotificationItem
