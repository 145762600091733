import { StoreSlice } from '.'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import chatService from '../services/chatService'
import followService from '../services/followService'
import likeService from '../services/likeService'
import notificationService from '../services/notificationService'
import {
  IComment,
  ITravelElement,
  ITravelog,
  ITripCard,
  INotification,
  IChat,
} from '../types'

type SocialSliceState = {
  notifications: INotification[]
  unreadNotificationsAmount: number
  emailInvitation: string
}
const defaultSocialSliceState: SocialSliceState = {
  notifications: [],
  unreadNotificationsAmount: null,
  emailInvitation: '',
}

type SocialSliceActions = {
  fetchMoreFollowers(entityId: string, entityType: string): void
  addCommentLike(commentId: string, path: string, userId: string): void
  deleteCommentLike(commentId: string, path: string, userId: string): void
  readNotification(notificationId: string): void
  getUnreadNotificationsAmount(userId: string): void
  setNewNotifications(notifications: INotification[]): void
  getMoreNotifications(userId): void
  setEmailInvitation(email: string): void
  insertChat(chat: IChat): void
  clearNotifications(userId: string): void
  clearSocialSlice(): void
}

export type SocialSlice = SocialSliceActions & SocialSliceState

export const createSocialSlice: StoreSlice<SocialSlice> = (set, get) => ({
  ...defaultSocialSliceState,

  fetchMoreFollowers: async (entityId: string, entityType: string) => {
    try {
      if (entityType === EntityTypeEnum.TRAVEL_ELEMENT) {
        const travelElement = get().travelElement
        const offset = travelElement.followerUsers.length
        const moreFollowers = await followService.getMoreFollowers(
          entityType,
          entityId,
          offset
        )
        travelElement.followerUsers = [
          ...travelElement.followerUsers,
          ...moreFollowers,
        ]
        set({ travelElement })
      }
      if (entityType === EntityTypeEnum.TRAVATAR) {
        const travatar = get().travatar
        const offset = travatar.followerUsers.length
        const moreFollowers = await followService.getMoreFollowers(
          entityType,
          entityId,
          offset
        )
        travatar.followerUsers = [...travatar.followerUsers, ...moreFollowers]
        set({ travatar })
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addCommentLike: async (commentId: string, path: string, userId: string) => {
    try {
      likeService.addLike(
        commentId,
        userId,
        EntityTypeEnum.COMMENT,
        IdsEnum.ENTITY_TYPE_COMMENT
      )
      const entityOnComment = path.split('.')[0]

      if (entityOnComment == EntityTypeEnum.TRAVEL_ELEMENT) {
        const travelElement: ITravelElement = get().travelElement
        const indexOfComment = travelElement.comments.findIndex(
          (comment) => comment.id === commentId
        )
        const comment = travelElement.comments[indexOfComment]

        travelElement.comments[indexOfComment] = {
          ...comment,
          likesAmount: ++comment.likesAmount,
          iLiked: true,
        }

        get().updateTravelElementsInStore(travelElement)
      } else if (entityOnComment === EntityTypeEnum.TRAVEL_ELEMENT_CONTENT) {
        const travelElement: ITravelElement = get().travelElement
        const travelElementContents = travelElement.travelElementContents.map(
          (tec) => {
            const comments = tec.comments.map((comment) => {
              if (comment.id === commentId) {
                return {
                  ...comment,
                  likesAmount: ++comment.likesAmount,
                  iLiked: true,
                }
              }
              return comment
            })
            return { ...tec, comments }
          }
        )
        travelElement.travelElementContents = travelElementContents

        get().updateTravelElementsInStore(travelElement)
      } else if (entityOnComment == EntityTypeEnum.TRAVELOG) {
        const travelog: ITravelog = get().travelog
        const indexOfComment = travelog.comments.findIndex(
          (comment) => comment.id === commentId
        )
        const comment = travelog.comments[indexOfComment]

        travelog.comments[indexOfComment] = {
          ...comment,
          likesAmount: ++comment.likesAmount,
          iLiked: true,
        }

        get().updateTravelogsInStore(travelog)
      } else if (entityOnComment === EntityTypeEnum.TRIP_CARD_CONTENT) {
        const tripPlan = get().tripPlan

        const tripCards: ITripCard[] = tripPlan.tripCards?.map(
          (tc: ITripCard) => {
            const tripCardContents = tc?.tripCardContents.map((tcc) => {
              const comments = tcc.comments.map((comment: IComment) => {
                if (comment.id === commentId) {
                  return {
                    ...comment,
                    likesAmount: ++comment.likesAmount,
                    iLiked: true,
                  }
                }
                return comment
              })
              return { ...tcc, comments }
            })
            return {
              ...tc,
              tripCardContents,
            }
          }
        )
        tripPlan.tripCards = tripCards

        get().updateTripPlanInStore(tripPlan)
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  deleteCommentLike: (commentId: string, path: string, userId: string) => {
    try {
      const entityOnComment = path.split('.')[0]
      likeService.deleteLike(commentId, userId, EntityTypeEnum.COMMENT)

      if (entityOnComment == EntityTypeEnum.TRAVEL_ELEMENT) {
        const travelElement: ITravelElement = get().travelElement
        const indexOfComment = travelElement.comments.findIndex(
          (comment) => comment.id === commentId
        )
        const comment = travelElement.comments[indexOfComment]

        travelElement.comments[indexOfComment] = {
          ...comment,
          likesAmount: --comment.likesAmount,
          iLiked: false,
        }

        get().updateTravelElementsInStore(travelElement)
      } else if (entityOnComment === EntityTypeEnum.TRAVEL_ELEMENT_CONTENT) {
        const travelElement: ITravelElement = get().travelElement
        const travelElementContents = travelElement.travelElementContents.map(
          (tec) => {
            const comments = tec.comments.map((comment) => {
              if (comment.id === commentId) {
                return {
                  ...comment,
                  likesAmount: --comment.likesAmount,
                  iLiked: false,
                }
              }
              return comment
            })
            return { ...tec, comments }
          }
        )
        travelElement.travelElementContents = travelElementContents

        get().updateTravelElementsInStore(travelElement)
      } else if (entityOnComment == EntityTypeEnum.TRAVELOG) {
        const travelog: ITravelog = get().travelog
        const indexOfComment = travelog.comments.findIndex(
          (comment) => comment.id === commentId
        )
        const comment = travelog.comments[indexOfComment]

        travelog.comments[indexOfComment] = {
          ...comment,
          likesAmount: --comment.likesAmount,
          iLiked: false,
        }

        get().updateTravelogsInStore(travelog)
      } else if (entityOnComment === EntityTypeEnum.TRIP_CARD_CONTENT) {
        const tripPlan = get().tripPlan

        const tripCards: ITripCard[] = tripPlan.tripCards?.map(
          (tc: ITripCard) => {
            const tripCardContents = tc?.tripCardContents.map((tcc) => {
              const comments = tcc.comments.map((comment: IComment) => {
                if (comment.id === commentId) {
                  return {
                    ...comment,
                    likesAmount: --comment.likesAmount,
                    iLiked: false,
                  }
                }
                return comment
              })
              return { ...tcc, comments }
            })
            return {
              ...tc,
              tripCardContents,
            }
          }
        )
        tripPlan.tripCards = tripCards

        get().updateTripPlanInStore(tripPlan)
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  getUnreadNotificationsAmount: async (userId: string) => {
    try {
      const unreadNotificationsAmount =
        await notificationService.getUnreadNotificationsAmount(userId)
      set({ unreadNotificationsAmount })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  setNewNotifications: (notifications: INotification[]) => {
    try {
      const notificationsInStore = get().notifications
      const unreadNotificationsAmountInStore = get().unreadNotificationsAmount

      if (notificationsInStore.length) {
        const notificationNotInStore = notifications.filter(
          (notification) =>
            !notificationsInStore.find((n) => n.id === notification.id)
        )
        const unreadNotificationsAmount =
          unreadNotificationsAmountInStore + notificationNotInStore.length
        set({
          notifications: [...notificationNotInStore, ...notificationsInStore],
          unreadNotificationsAmount,
        })
      } else {
        set({ notifications })
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  readNotification: async (notificationId) => {
    try {
      const notifications = get().notifications.map((n) => {
        if (n.id === notificationId) {
          return {
            ...n,
            isRead: true,
          }
        }
        return n
      })

      notificationService.readNotification(notificationId)
      const unreadNotificationsAmount = get().unreadNotificationsAmount - 1
      set({ notifications, unreadNotificationsAmount })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  getMoreNotifications: async (userId: string) => {
    try {
      const notifications = get().notifications
      const offset = notifications.length
      const newNotifications = await notificationService.getMoreNotifications(
        userId,
        offset
      )

      if (newNotifications.length) {
        set({
          notifications: [...notifications, ...newNotifications],
        })
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  setEmailInvitation: (email: string) => {
    set({ emailInvitation: email })
  },
  insertChat: async (chat) => {
    try {
      get().setTripPlanChat([chat])
      await chatService.insertChat(chat)
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  clearNotifications: async (userId: string) => {
    try {
      const unreadNotifications = get().unreadNotificationsAmount
      if (unreadNotifications) {
        const notifications = await notificationService.readAllNotifications(
          userId,
          get().notifications
        )

        set({
          notifications,
          unreadNotificationsAmount: 0,
        })
      }
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  clearSocialSlice: () => {
    set({ ...defaultSocialSliceState })
  },
})
