import client from '../gql/clients/apollo-client'
import { INSERT_LIKE, UNLIKE } from '../gql/mutations/likes'
import { ILike } from '../types'
import removeDashes from '../utils/removeDashes'

export default {
  addLike: async (
    entityId: string,
    userId: string,
    entityType: string,
    entityTypeId: string
  ): Promise<ILike> => {
    const path = `${entityType}.${removeDashes(entityId)}`
    const result = await client.mutate({
      mutation: INSERT_LIKE,
      variables: { path, userId, entityTypeId },
    })
    const like: ILike = result.data.insert_like_one
    return like
  },
  deleteLike: async (entityId: string, userId: string, entityType: string) => {
    const path = `${entityType}.${removeDashes(entityId)}`

    await client.mutate({
      mutation: UNLIKE,
      variables: { path, userId },
    })
  },
}
