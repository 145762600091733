import { CoordinateType } from '../components/common/SkipdMap/skipMapTypes'
import client from '../gql/clients/apollo-client'
import {
  INSERT_OR_UPDATE_TRIP_CARDS,
  REMOVE_TRIP_CARDS,
  UPDATE_TRIP_CARD_LOCATION,
} from '../gql/mutations/tripCard'
import { ITripCard, ITripPlan } from '../types'
import { formatTripCardItineraryMetadata } from '../utils/tripPlan'
import tripCardContentService from './tripCardContentService'

export default {
  removeTripCards: async (tripCardIds): Promise<void> => {
    await client.mutate({
      mutation: REMOVE_TRIP_CARDS,
      variables: { tripCardIds },
    })
  },

  insertOrEditTripCards: async (
    itinerary,
    tripPlanId
  ): Promise<ITripCard[]> => {
    const newCards = Object.assign({}, itinerary)

    const cardsMetadata = formatTripCardItineraryMetadata(newCards)

    const completeTripCards = cardsMetadata.map((card) => {
      return {
        id: card.id,
        metadata: card,
        trip_plan_id: tripPlanId,
        travel_element_id: card?.travelElementId,
      }
    })

    const result = await client.mutate({
      mutation: INSERT_OR_UPDATE_TRIP_CARDS,
      variables: { tripCards: completeTripCards },
    })

    const cards = await Promise.all(
      result.data.insert_trip_card.returning.map(async (card) => {
        return {
          ...card,
          tripCardContents:
            await tripCardContentService.completeTripCardContents(
              card?.tripCardContents
            ),
        }
      })
    )

    return cards
  },
  updateTripCardLocation: async (
    mapCoordinate: CoordinateType,
    tripCardId,
    profileEntityInStore,
    entityInStore
  ) => {
    const location = `(${mapCoordinate.latitude}, ${mapCoordinate.longitude})`
    const result = await client.mutate({
      mutation: UPDATE_TRIP_CARD_LOCATION,
      variables: {
        tripCardId,
        location,
      },
    })

    const tripCardResult: ITripCard = result.data.update_trip_card_by_pk

    const tripCardComplete: ITripCard = {
      ...tripCardResult,
      tripCardContents: await tripCardContentService.completeTripCardContents(
        tripCardResult?.tripCardContents
      ),
    }

    const entity = {
      ...entityInStore,
      tripCards: entityInStore.tripCards.map((tc: ITripCard) => {
        if (tc.id === tripCardComplete.id) {
          return tripCardComplete
        }
        return tc
      }),
    }

    const profileEntity = profileEntityInStore.map((ent) => {
      if (ent.id === entity.id) {
        return entity
      }
      return ent
    })

    return { profileEntity, entity }
  },
}
