import { gql } from '@apollo/client'
import { TRAVATAR_INFO_FRAGMENT } from '../fragments/travatar'

const BLOG_FRAGMENT = gql`
  fragment BlogInfo on blog {
    id
    title
    content
    travatarId: travatar_id
    userId: user_id
    entityTypeId: entity_type_id
    entityType: entity_type {
      id
      name
    }
    travatarInfo: travatar {
      ...TravatarInfo
    }
    user {
      name
      id
      profilePhoto: profile_photo
    }
  }
  ${TRAVATAR_INFO_FRAGMENT}
`

export { BLOG_FRAGMENT }
