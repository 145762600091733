import { useState, useEffect, MouseEvent, ReactElement } from 'react'
import { useStore } from '../../../store'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Image from 'next/image'
import Searcher from '../../common/Searcher'
import SignUpModal from '../../common/SignUpModal'
import LoginModal from '../../common/LoginModal'
import Alert from '@mui/material/Alert'
import SkipdBreadcrumb from '../../common/SkipdBreadcrumb'

import { Auth } from 'aws-amplify'
import { useRouter } from 'next/router'
import { useUser } from '../../../context/Auth'
import styled from '@emotion/styled'
import ForgotPassowrd from '../../common/ForgotPassword'
import { Button, Divider } from '@mui/material'
import Notifications from '../../common/Notifications'
import AboutSkipd from '../../common/AboutSkipd'

/*const SoundIcon = styled.div`
  background-color: #2b2841;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 48px;
  border-radius: 4px;
  & svg {
    color: #fff;
    font-size: 16px;
  }
`*/

const AlertContainer = styled(Box)`
  position: fixed;
  z-index: 2000;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 70px;
`

const NavBarTop = (): ReactElement => {
  //TODO: REVIEW WHY SOMETIMES WHEN UPDATE THE USER PICTURE IT LOOPS

  const { claims, user, setClaims } = useUser()
  const router = useRouter()

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const {
    breadcrumb,
    openSignUp,
    openLogin,
    openForgotPassword,
    openAboutSkipd,
    alertMessage,
    notFound,
    setOpenSignUp,
    setOpenLogin,
    setOpenAboutSkipd,
    setCloseAlert,
    cleanProfile,
    setGlobeZoomOut,
    getStoreUser,
    setStoreUser,
    storeUser,
    clearSocialSlice,
  } = useStore()

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
    setAnchorElNav(null)
  }

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null)
  }

  const handleOpenLogin = (): void => {
    setOpenLogin()
    setAnchorElNav(null)
    setAnchorElUser(null)
  }

  const handleOpenSignUp = (): void => {
    setOpenSignUp()
    setAnchorElNav(null)
    setAnchorElUser(null)
  }
  const handleOpenAboutSkipd = (): void => {
    setOpenAboutSkipd()
    setAnchorElNav(null)
    setAnchorElUser(null)
  }

  const handleMyProfile = (): void => {
    router.push(`${process.env.NEXT_PUBLIC_ROOT}/profile/${claims?.id}/edit`)
  }

  const handleCreationHub = (): void => {
    router.push(
      `${process.env.NEXT_PUBLIC_ROOT}/profile/${claims?.id}/creation-hub`
    )
  }

  const handleLogout = async (): Promise<void> => {
    await Auth.signOut()
    setClaims(null)
    cleanProfile()
    setStoreUser(null)
    clearSocialSlice()
  }

  const handleReloadHome = (): void => {
    if (router.pathname === `${process.env.NEXT_PUBLIC_ROOT}`) {
      setGlobeZoomOut()
    } else {
      router.push(`${process.env.NEXT_PUBLIC_ROOT}`)
    }
  }

  useEffect(() => {
    if (claims?.id && !storeUser) {
      getStoreUser(claims?.id)
    }
  }, [claims, getStoreUser])

  useEffect(() => {
    if (notFound) {
      router.push('/404')
    }
  }, [notFound])

  useEffect(() => {
    let closeAlert
    if (alertMessage.message)
      closeAlert = setTimeout(() => {
        setCloseAlert()
      }, 5000)
    return () => clearTimeout(closeAlert)
  }, [alertMessage, setCloseAlert])

  return (
    <>
      {alertMessage.message ? (
        <AlertContainer>
          <Alert
            severity={alertMessage.severity}
            icon={false}
            onClose={setCloseAlert}
          >
            {alertMessage.message}
          </Alert>
        </AlertContainer>
      ) : null}
      <AppBar
        position="absolute"
        sx={{ backgroundColor: 'rgba(0,0,0,0.4)', boxShadow: 0, flexGrow: 1 }}
      >
        <Container maxWidth={false} sx={{ zIndex: 1000 }}>
          <Toolbar disableGutters>
            <Box sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
              <Button onClick={handleReloadHome}>
                <span style={{ cursor: 'pointer' }}>
                  <Image
                    src="/images/logoglobe.svg"
                    height={70}
                    width={70}
                    alt="explorer"
                    layout="fixed"
                    objectFit="contain"
                    quality={100}
                    priority
                  />
                </span>
              </Button>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                alignItems: 'center',
                display: { xs: 'flex', md: 'none' },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <Button onClick={handleReloadHome}>
                  <span style={{ cursor: 'pointer' }}>
                    <Image
                      src="/images/logoglobe.svg"
                      height={70}
                      width={70}
                      alt="explorer"
                      layout="fixed"
                      objectFit="contain"
                      quality={100}
                      priority
                    />
                  </span>
                </Button>
              </Box>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {/* Alonzo: I documented this option in the menu because it is not functional right now
                <MenuItem
                  onClick={() => router.push(`${process.env.NEXT_PUBLIC_ROOT}`)}
                >
                  <Typography textAlign="center">Explore</Typography>
              </MenuItem>
                <MenuItem>
                  <Typography textAlign="center">My Profile</Typography>
                </MenuItem>*/}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
              }}
            >
              {breadcrumb.length ? (
                <SkipdBreadcrumb />
              ) : (
                <>
                  {/* Alonzo: I documented this option in the menu because it is not functional right now
                   <Button
                    onClick={() =>
                      router.push(`${process.env.NEXT_PUBLIC_ROOT}`)
                    }
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Explore
                  </Button> 
                  {user && claims?.id ? (
                    <Button
                      onClick={() =>
                        router.push(
                          `${process.env.NEXT_PUBLIC_ROOT}/profile/${claims.id}`
                        )
                      }
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      My Profile
                    </Button>
                  ) : null}*/}
                  <Searcher />
                  {/*<SoundIcon>
                    <IconButton>
                      <FaVolumeUp />
                    </IconButton>
                </SoundIcon>*/}
                </>
              )}
            </Box>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              {user ? (
                <>
                  <Notifications
                    size={26}
                    iconColor="white"
                    style={{ margin: '0 10px' }}
                  />
                  <Tooltip title="Open settings">
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{ p: 0, ml: 2 }}
                    >
                      <Avatar
                        alt={user.getUsername()}
                        src={storeUser?.profilePhotoUrl}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={handleMyProfile}>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        textAlign="center"
                      >
                        My Profile
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCreationHub}>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        textAlign="center"
                      >
                        My Travel Content
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleOpenAboutSkipd}>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        textAlign="center"
                      >
                        About Skip`d
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        textAlign="center"
                      >
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <MenuItem onClick={handleOpenAboutSkipd}>
                    <Typography variant="subtitle1" textAlign="center">
                      About Skip`d
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleOpenSignUp}>
                    <Typography variant="subtitle1" textAlign="center">
                      Sign up
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleOpenLogin}>
                    <Typography variant="subtitle1" textAlign="center">
                      Login
                    </Typography>
                  </MenuItem>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {openAboutSkipd && <AboutSkipd />}
      {openSignUp && <SignUpModal />}
      {openLogin && <LoginModal />}
      {openForgotPassword && <ForgotPassowrd />}
    </>
  )
}

export default NavBarTop
