import { gql } from '@apollo/client'
import IdsEnum from '../../constants/ids.constants'
import { TRAVEL_ELEMENT_FRAGMENT } from '../fragments/travelElement'

const GET_TRAVEL_ELEMENT = gql`
  query GetTravelElement($id: uuid!) {
    travel_element_by_pk(id: $id) {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`

const GET_TRAVEL_ELEMENTS = gql`
  query GetTravelElements {
    travel_element(
      where: {
        location: { _is_null: false }
        deleted_at: { _is_null: true }
        status_id: { _eq: "${IdsEnum.STATUS_PUBLISHED}" }
      }
    ) {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`
const GET_TRAVEL_ELEMENTS_BY_ID = gql`
  query GetTravelElementsById($travelElementIds: [uuid!]) {
    travel_element(where: { id: { _in: $travelElementIds } }) {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`
const GET_MORE_TRAVEL_ELEMENTS = gql`
  query GetMoreTravelElements(
    $offset: Int!
    $userId: uuid!
    $nameComparisonExp: String_comparison_exp
    $travatarIdComparisonExp: uuid_comparison_exp
  ) {
    travel_element(
      limit: 10
      offset: $offset
      where: {
        user_id: { _eq: $userId }
        name: $nameComparisonExp
        travatar_id: $travatarIdComparisonExp
        deleted_at: { _is_null: true }
      }
      order_by: { created_at: desc }
    ) {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`

const GET_MORE_LIKED_TRAVEL_ELEMENTS = gql`
  query GetMoreLikedTravelElements($userId: uuid!, $offset: Int!) {
    user_by_pk(id: $userId) {
      likedTravelElements: liked_travel_elements(limit: 10, offset: $offset) {
        ...TravelElement
      }
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`
const GET_MORE_FOLLOWING_TRAVEL_ELEMENTS = gql`
  query GetMoreLikedTravelElements($userId: uuid!, $offset: Int!) {
    user_by_pk(id: $userId) {
      following_travel_elements(
        limit: 10
        offset: $offset
        order_by: { created_at: desc }
      ) {
        ...TravelElement
      }
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`

export {
  GET_TRAVEL_ELEMENT,
  GET_TRAVEL_ELEMENTS,
  GET_TRAVEL_ELEMENTS_BY_ID,
  GET_MORE_TRAVEL_ELEMENTS,
  GET_MORE_LIKED_TRAVEL_ELEMENTS,
  GET_MORE_FOLLOWING_TRAVEL_ELEMENTS,
}
