import { gql } from '@apollo/client'
import { BLOG_FRAGMENT } from '../fragments/blog'

const INSERT_OR_UPDATE_BLOG = gql`
  mutation InsertOrUpdateBlog(
    $id: uuid!
    $title: String
    $travatarId: uuid
    $userId: uuid!
    $content: String
    $entityTypeId: uuid
  ) {
    insert_blog_one(
      object: {
        id: $id
        title: $title
        entity_type_id: $entityTypeId
        travatar_id: $travatarId
        user_id: $userId
        content: $content
      }
      on_conflict: {
        constraint: blog_pkey
        update_columns: [title, content, travatar_id, entity_type_id, user_id]
      }
    ) {
      ...BlogInfo
    }
  }
  ${BLOG_FRAGMENT}
`

export { INSERT_OR_UPDATE_BLOG }
