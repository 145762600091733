import { gql } from '@apollo/client'
import { TRIP_CARD_FRAGMENT } from './tripCard'

const TRIP_PLAN_INFO_FRAGMENT = gql`
  fragment TripPlanInfo on trip_plan {
    id
    metadata
    title
    description
    statusId: status_id
    travatarId: travatar_id
    likesAmount: computed_likes
    userId: user_id
    user {
      id
      name
      profilePhoto: profile_photo
    }
  }
`

const TRIP_PLAN_FRAGMENT = gql`
  fragment TripPlan on trip_plan {
    ...TripPlanInfo
    travatar {
      name
      id
      user {
        id
        name
      }
    }
    tripPlanCollaborators: trip_plan_collaborators {
      isAdvisor: is_advisor
      user {
        id
        name
        profilePhoto: profile_photo
      }
    }
    tripCards: trip_cards {
      ...TripCard
    }
    originalTripPlan: original_trip_plan {
      id
      title
      user {
        id
        name
        profilePhoto: profile_photo
      }
    }
  }
  ${TRIP_CARD_FRAGMENT}
  ${TRIP_PLAN_INFO_FRAGMENT}
`

const TRIP_PLAN_COLLABORATOR_FRAGMENT = gql`
  fragment TripPlanCollaborator on trip_plan_collaborators {
    id
    metadata
    invitationAccepted: invitation_accepted
    userId: user_id
    emailInvited: email_invited
    tripPlanId: trip_plan_id
    isAdvisor: is_advisor
    user {
      id
      name
      profilePhoto: profile_photo
      description
      email
      isAdvisor: is_advisor
    }
    tripPlan: trip_plan {
      ...TripPlan
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`

const TRIP_PLAN_LOG_FRAGMENT = gql`
  fragment TripPlanLog on trip_plan_log {
    id
    log
    createdAt: created_at
    userId: user_id
    tripCardId: trip_card_id
    tripPlanId: trip_plan_id
    tripPlan: trip_plan {
      ...TripPlanInfo
    }
    profileInfo: user {
      id
      name
      profilePhoto: profile_photo
      description
      email
    }
  }
  ${TRIP_PLAN_INFO_FRAGMENT}
`

export {
  TRIP_PLAN_FRAGMENT,
  TRIP_PLAN_INFO_FRAGMENT,
  TRIP_PLAN_COLLABORATOR_FRAGMENT,
  TRIP_PLAN_LOG_FRAGMENT,
}
