import client from '../gql/clients/apollo-client'
import { INSERT_FOLLOW, UNFOLLOW } from '../gql/mutations/follows'
import { GET_MORE_FOLLOWERS, GET_MORE_FOLLOWINGS } from '../gql/querys/follow'
import { IProfileInfo } from '../types'
import { getProfilePhotoUrl } from '../utils/profile'
import removeDashes from '../utils/removeDashes'

export default {
  addFollow: async (
    entityId: string,
    profileId: string,
    entityType: string,
    entityTypeId: string
  ) => {
    const path = `${entityType}.${removeDashes(entityId)}`
    const result = await client.mutate({
      mutation: INSERT_FOLLOW,
      variables: {
        path,
        userId: profileId,
        entityTypeId,
      },
    })
    return result.data.insert_follow_one
  },
  deleteFollow: async (
    entityId: string,
    profileId: string,
    entityType: string
  ) => {
    const path = `${entityType}.${removeDashes(entityId)}`
    const result = client.mutate({
      mutation: UNFOLLOW,
      variables: {
        path,
        userId: profileId,
      },
    })
    return result
  },
  getMoreFollowers: async (
    entityType: string,
    entityId: string,
    offset: number
  ) => {
    const path = `${entityType}.${removeDashes(entityId)}`

    const result = await client.query({
      query: GET_MORE_FOLLOWERS,
      variables: {
        path,
        offset,
      },
    })

    const profileWithNoImage = result.data.follow

    const completeProfiles: IProfileInfo[] = await Promise.all(
      profileWithNoImage.map(async (profile) => {
        const url = await getProfilePhotoUrl(
          profile.user.profilePhoto,
          profile.user.id
        )
        return { ...profile.user, profilePhotoUrl: url }
      })
    )

    return completeProfiles
  },
  getMoreFollowings: async (
    userId: string,
    offset: number
  ): Promise<IProfileInfo[]> => {
    const result = await client.query({
      query: GET_MORE_FOLLOWINGS,
      variables: {
        userId,
        offset,
      },
    })

    const profileWithNoImage = result.data.user_by_pk.following_users

    const completeProfiles: IProfileInfo[] = await Promise.all(
      profileWithNoImage?.map(async (profile) => {
        const url = await getProfilePhotoUrl(
          profile.user.profilePhoto,
          profile.user.id
        )
        return { ...profile.user, profilePhotoUrl: url }
      })
    )

    return completeProfiles
  },
}
