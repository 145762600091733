import { formatPointToCoordinateType, similarityPercentageStrings } from '.'
import EntityTypeEnum from '../constants/entityType.constants'
import blogService from '../services/blogService'
import { completeCommentsInfo } from '../services/commentService'
import travelElementService from '../services/travelElementService'
import { ITravelElement, ITravelElementContent } from '../types'
import { pointInRadius } from './distanceBetweenPoints'
import { getProfilePhotoUrl } from './profile'
import { getImageUrl } from './useS3'

export function checkIfTravelElementExists(
  globePoints: ITravelElement[],
  travelElement: ITravelElement
): void {
  globePoints.forEach((te) => {
    if (te.id !== travelElement.id) {
      const isInRadius = pointInRadius(
        formatPointToCoordinateType(te.location),
        formatPointToCoordinateType(travelElement.location),
        0.05
      )

      if (isInRadius) {
        const similarity = similarityPercentageStrings(
          travelElement.name,
          te.name
        )
        if (similarity > 0.6) {
          throw new Error(
            'This Travel Element cannot be published because there is a similar one published'
          )
        }
      }
    }
  })
}

export const orderTravelElementContents = (
  travelContents: ITravelElementContent[]
) => {
  const orderTravelElementContents = [...travelContents]

  const orderedResult = orderTravelElementContents.sort((a, b) => {
    return a.metadata.order - b.metadata.order
  })

  return orderedResult
}

export const completeTravelElementInfo = async (
  travelElement: ITravelElement
): Promise<ITravelElement> => {
  const coverPhotoUrl = await getImageUrl(
    `${travelElement?.id}/${travelElement?.coverPhoto}`
  )
  const travelElementContents = await Promise.all(
    travelElement?.travelElementContents?.map(async (tec) => {
      let travelElementContentUrl
      if (tec.entityType.name !== EntityTypeEnum.YOUTUBE_VIDEO) {
        travelElementContentUrl = await getImageUrl(`${tec?.id}/${tec?.path}`)
      } else {
        travelElementContentUrl = tec.travelElementContentUrl
      }

      const comments = await completeCommentsInfo(tec?.comments)
      const blog = tec?.blog[0]

      return {
        ...tec,
        location: tec.location ? tec?.location : travelElement?.location,
        travelElementContentUrl,
        comments,
        blog,
      }
    }) || []
  )

  const comments = await completeCommentsInfo(travelElement?.comments)

  const followerUsers = await Promise.all(
    travelElement?.followerUsers?.map(async (profile) => {
      const url = await getProfilePhotoUrl(profile.profilePhoto, profile.id)
      return { ...profile, profilePhotoUrl: url }
    }) || []
  )

  const user = travelElement?.profileInfo
  const profileInfo = {
    id: user?.id,
    name: user?.name,
  }
  return {
    ...travelElement,
    coverPhotoUrl,
    travelElementContents,
    profileInfo,
    comments,
    followerUsers,
  }
}
export const getTravelElement = async (travelElementId: string) => {
  const newTravelElement =
    await travelElementService.getOrAssignNewTravelElement(travelElementId)

  return newTravelElement
}

export const completeCardTravelElementInfo = async (travelElement) => {
  const travelElementContents = await Promise.all(
    travelElement?.travelElementContents?.map(async (tec) => {
      let travelElementContentUrl
      let blog = null
      if (tec.entityType.name == EntityTypeEnum.BLOG_POST) {
        blog = await blogService.getOrAssingNewBlog(tec.path)
      }
      if (tec.entityType.name !== EntityTypeEnum.YOUTUBE_VIDEO) {
        travelElementContentUrl = await getImageUrl(`${tec?.id}/${tec?.path}`)
      } else {
        travelElementContentUrl = tec.travelElementContentUrl
      }
      return {
        ...tec,
        blog,
        travelElementContentUrl,
      }
    }) || []
  )
  return { ...travelElement, travelElementContents }
}

export const getTravelElementsFromItinerary = (itinerary) => {
  const itineraryDays = Object.keys(itinerary)

  const itineraryCards = itineraryDays
    .map((day) => {
      const days = itinerary[day]?.filter((card) => {
        if (card?.travelElementId) {
          return card.travelElement
        }
      })
      return days
    })
    .flat()

  const travelElements = itineraryCards.map((card) => {
    return card?.travelElement
  })

  return travelElements
}
