import { gql } from '@apollo/client'
import { TRAVEL_ELEMENT_FRAGMENT } from './travelElement'
import { TRIP_CARD_CONTENT_FRAGMENT } from './tripCardContent'

const TRIP_CARD_INFO_FRAGMENT = gql`
  fragment TripCardInfo on trip_card {
    id
    metadata
    tripPlanId: trip_plan_id
    location
    travelElementId: travel_element_id
    travelElement: travel_element {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`

const TRIP_CARD_FRAGMENT = gql`
  fragment TripCard on trip_card {
    ...TripCardInfo
    tripCardContents: trip_card_contents(
      where: { is_private: { _eq: false } }
    ) {
      ...TripCardContent
    }
  }
  ${TRIP_CARD_CONTENT_FRAGMENT}
  ${TRIP_CARD_INFO_FRAGMENT}
`

export { TRIP_CARD_FRAGMENT, TRIP_CARD_INFO_FRAGMENT }
