import { gql } from '@apollo/client'
import { FOLLOWS_FRAGMENT } from '../fragments/follows'

const INSERT_FOLLOW = gql`
  mutation insertFollow($path: ltree!, $userId: uuid!, $entityTypeId: uuid!) {
    insert_follow_one(
      object: { path: $path, user_id: $userId, entity_type_id: $entityTypeId }
    ) {
      ...Follows
    }
  }
  ${FOLLOWS_FRAGMENT}
`
const UNFOLLOW = gql`
  mutation deleteFollow($path: ltree!, $userId: uuid!) {
    delete_follow(where: { path: { _eq: $path }, user_id: { _eq: $userId } }) {
      affected_rows
    }
  }
`
export { INSERT_FOLLOW, UNFOLLOW }
