import { StoreSlice } from '.'
import settingsService from '../services/settingsService'
import { IBreadcrumbElement } from '../types'
type AppSliceState = {
  openSignUp: boolean
  openLogin: boolean
  openForgotPassword: boolean
  openAboutSkipd: boolean
  isLoading: any
  alertMessage: any
  breadcrumb: IBreadcrumbElement[]
  notFound: boolean
  mutationSuccess: boolean
  appVersion: string
  globeZoomOut: boolean
  showLeanBackMode: boolean
}

type AppSliceActions = {
  setOpenSignUp(): void
  setOpenLogin(): void
  setOpenAboutSkipd(): void
  setOpenForgotPassword(): void
  setCloseForgotPassword(): void
  setCloseAboutSkipd(): void
  setCloseSignUp(): void
  setCloseLogin(): void
  setAlertMessage(alertMessage: string, severity: string): void
  setCloseAlert(): void
  setStartLoading(isLoading?: any): void
  setEndLoading(): void
  setBreadcrumb(breadcrumb: IBreadcrumbElement[]): void
  setNotFound(notFound: boolean): void
  setMutationSuccess(mutationSuccess: boolean): void
  getAppVersion(): void
  setGlobeZoomOut(): void
  setShowLeanBackMode(showLeanBackMode: boolean): void
}

export type AppSlice = AppSliceState & AppSliceActions

const defaultAppSliceState: AppSliceState = {
  openSignUp: false,
  openLogin: false,
  openAboutSkipd: false,
  openForgotPassword: false,
  notFound: false,
  isLoading: {
    main: false,
    profile: {
      likedVideos: false,
      likedTravelogs: false,
      likedPhotos: false,
      followers: false,
      edit: false,
    },
    tripPlan: { main: false, mutation: false },
    travelog: { main: false, mutation: false },
    travatar: { main: false, mutation: false },
    travelElement: { main: false, mutation: false },
    search: { main: false },
  },
  alertMessage: {
    message: '',
    severity: '',
  },
  breadcrumb: [],
  mutationSuccess: false,
  appVersion: '',
  globeZoomOut: false,
  showLeanBackMode: false,
}

export const createAppSlice: StoreSlice<AppSlice> = (set, get) => ({
  ...defaultAppSliceState,

  setNotFound: (notFound) => {
    set({ notFound })
  },
  setOpenSignUp: () => {
    const openSignUp = true
    const openLogin = false
    const openForgotPassword = false
    set({ openSignUp, openLogin, openForgotPassword })
  },
  setOpenAboutSkipd: () => {
    const openAboutSkipd = true
    set({ openAboutSkipd })
  },
  setCloseAboutSkipd: () => {
    const openAboutSkipd = false
    set({ openAboutSkipd })
  },
  setOpenForgotPassword: () => {
    const openLogin = false
    const openSignUp = false
    const openForgotPassword = true
    set({ openLogin, openSignUp, openForgotPassword })
  },
  setOpenLogin: () => {
    const openSignUp = false
    const openLogin = true
    const openForgotPassword = false
    set({ openSignUp, openLogin, openForgotPassword })
  },
  setCloseSignUp: () => {
    const openSignUp = false
    set({ openSignUp })
  },
  setCloseLogin: () => {
    const openLogin = false
    set({ openLogin })
  },
  setCloseForgotPassword: () => {
    const openForgotPassword = false
    set({ openForgotPassword })
  },
  setAlertMessage: (message: string, severity: string) => {
    const alertMessage = { message, severity }
    set({ alertMessage })
  },
  setCloseAlert: () => {
    set({ alertMessage: defaultAppSliceState.alertMessage })
  },
  setStartLoading: (isLoading = { main: true }) => {
    const startLoading = { ...get().isLoading, isLoading }
    set({ isLoading: isLoading ? isLoading : startLoading })
  },
  setEndLoading: () => {
    set({ isLoading: defaultAppSliceState.isLoading })
  },
  setBreadcrumb: (breadcrumb: IBreadcrumbElement[]) => {
    set({ breadcrumb })
  },
  setMutationSuccess: (mutationSuccess) => set({ mutationSuccess }),
  getAppVersion: async () => {
    const appVersion = await settingsService.getVersion()
    set({ appVersion })
  },
  setGlobeZoomOut() {
    const currentZoomStatus = get().globeZoomOut
    set({ globeZoomOut: !currentZoomStatus })
  },
  setShowLeanBackMode: (showLeanBackMode: boolean) => {
    set({ showLeanBackMode })
  },
})
