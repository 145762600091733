import { gql } from '@apollo/client'
import { PROFILE_INFO_FRAGMENT } from './profile'

const FOLLOWS_FRAGMENT = gql`
  fragment Follows on follow {
    id
    path
    userId: user_id
    user {
      ...ProfileInfo
    }
    entityType: entity_type {
      id
      name
    }
  }
  ${PROFILE_INFO_FRAGMENT}
`
export { FOLLOWS_FRAGMENT }
