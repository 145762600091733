import { gql } from '@apollo/client'
import {
  TRAVELOG_FRAGMENT,
  TRAVELOG_INFO_FRAGMENT,
} from '../fragments/travelog'

const GET_TRAVELOG_BY_ID = gql`
  query getTravelogById($id: uuid!) {
    travelog_by_pk(id: $id) {
      ...Travelog
    }
  }
  ${TRAVELOG_FRAGMENT}
`

const GET_TRAVELOGS = gql`
  query GetTravelogs($travelogIds: [uuid!]) {
    travelog(
      where: { id: { _in: $travelogIds }, deleted_at: { _is_null: true } }
    ) {
      ...Travelog
    }
  }

  ${TRAVELOG_FRAGMENT}
`

const GET_MORE_TRAVELOGS = gql`
  query GetMoreTravelogs(
    $userId: uuid!
    $offset: Int!
    $titleComparisonExp: String_comparison_exp
    $travatarIdComparisonExp: uuid_comparison_exp
  ) {
    travelog(
      offset: $offset
      limit: 10
      order_by: { created_at: desc }
      where: {
        user_id: { _eq: $userId }
        title: $titleComparisonExp
        travatar_id: $travatarIdComparisonExp
        deleted_at: { _is_null: true }
      }
    ) {
      ...Travelog
    }
  }
  ${TRAVELOG_FRAGMENT}
`

const GET_MORE_LIKED_TRAVELOGS = gql`
  query GetMoreLikedTravelogs($userId: uuid!, $offset: Int!) {
    user_by_pk(id: $userId) {
      likedTravelogs: liked_travelogs(limit: 10, offset: $offset) {
        ...TravelogInfo
      }
    }
  }
  ${TRAVELOG_INFO_FRAGMENT}
`

export {
  GET_TRAVELOG_BY_ID,
  GET_TRAVELOGS,
  GET_MORE_TRAVELOGS,
  GET_MORE_LIKED_TRAVELOGS,
}
